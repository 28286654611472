import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../components/common/Logo";
import { useHistory } from "react-router";
import ApiCalling from "../../network/ApiCalling";
import ToastHOC from "../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import Invalid from "../../assets/Icon/invalid.svg";

function ForgotPassword(props) {
  const { t } = useTranslation();
  const pendingToast = props.history.location.pendingToast;
  const { setIsToast } = props;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const sentMail = async () => {
    ApiCalling.apiCallBodyDataGet(`user/forget-password/${email}`)
      .then((res) => {
        if (res.data.status === true) {
          history.push({
            pathname: "/resetPassword",
            pendingToast: t("Email with OTP Sent"),
          });
          Util.saveForgotPasswordUser(email);
        } else {
          setEmailError(t(res.data.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSave = (e) => {
    e.preventDefault();
    setEmailError("");
    let error = false;
    if (email === "") {
      setEmailError(t("Enter Your Email"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (!error) {
      sentMail();
    }
  };
  useEffect(() => {
    if (pendingToast) {
      setIsToast(pendingToast);
    }
  }, []);
  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          <div className="auth-box">
            <Logo />
            <div className="card">
              <div className="header">
                <p className="lead">{t("Recover password")}</p>
              </div>
              <div className="body">
                <p>
                  {t(
                    "Please enter your email address below to receive instructions for resetting password."
                  )}
                </p>
                <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                  <div className="input-filed">
                    <input
                      className={`form-control custom-input${
                        emailError.length > 0 ? " input-error" : ""
                      }`}
                      placeholder={t("Email Address")}
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {emailError}
                      </span>
                    )}
                  </div>
                  <button
                    className="btn custom-primary btn-lg btn-block"
                    type="submit"
                    onClick={handleSave}
                  >
                    {t("SEND ME CODE")}
                  </button>
                  <div className="bottom">
                    <span className="text-sm-secondary">
                      {t("Know your password?")}{" "}
                      <a href="login">{t("Login")}</a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToastHOC(ForgotPassword);
