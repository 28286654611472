import React, { useEffect, useRef, useState } from "react";
import gearIcon from "../../assets/Icon/setting.svg";

const CustomSelect = ({
  options,
  onSelect,
  onSettingsClick,
  selectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setIsOpen(false);

    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div className="custom-select2" ref={dropdownRef}>
      <div className="select-container" onClick={handleToggleDropdown}>
        <div className="selected-value">
          {selectedOption ? selectedOption.label : "Select an option"}
        </div>
        <img
          onClick={onSettingsClick}
          src={gearIcon}
          alt="Settings"
          className="settings-icon"
        />
      </div>

      {isOpen && options && options.length > 0 && (
        <div className="options">
          {options.map((option) => (
            <div
              key={option.value}
              className="option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
