import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Avatar } from "@mui/material";
import { NoteAltOutlined } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import { imageStudentList } from "../../../CommonImage";
import ToastHOC from "../../../HOC/ToastHOC";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { orangeTagStyle } from "../../TagButtonStyle";
import TagButton from "../../TagButton";
import ModalComponent from "../../ModalComponent";
import Loader from "../../../../components/common/Loader";

function LeavePending({ setIsToast, setRefreshApprove }) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [requestList, setRequestList] = useState([]);
  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [approveByUserId, setApproveByUserId] = useState(0);
  const [approve, setApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const leaveRequestList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `leave-request/get-pending-request/${school.id}`
    )
      .then((res) => {
        setIsLoading(false);
        setRequestList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    leaveRequestList();
  }, []);
  const handleApprove = () => {
    setIsLoading(true);
    const data = {
      user_id: approveByUserId,
      approved: approve,
    };
    ApiCalling.apiCallBodyDataPost(`leave-request/approve/${requestId}`, data)
      .then((res) => {
        setApprovalConfirmation(false);
        if (res.data.status == true) {
          setIsLoading(false);
          setIsToast(res.data.message);
          leaveRequestList();
          setRefreshApprove(true);
        } else {
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        {!isLoading ? (
          <>
            <div className="row p-3">
              {requestList?.length > 0 ? (
                <>
                  {requestList.map((req) => {
                    const formattedDate = moment(req.start_date).format(
                      "MMMM DD ddd"
                    );
                    const formattedEndDate = moment(req.end_date).format(
                      "MMMM DD ddd"
                    );

                    return (
                      <div className="col-6" key={req.id}>
                        <div className="activity-card mb-4">
                          <div className="body">
                            <div className="post-header d-flex justify-content-start">
                              {Util.isValidData(req.Student.photo) ? (
                                <img
                                  src={imageStudentList + req.Student.photo}
                                  className="rounded-circle user-photo"
                                  style={{ width: "40px", height: "40px" }}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  aria-label="recipe"
                                  className="avatar-font"
                                >
                                  {req.Student.first_name?.toUpperCase()[0] +
                                    req.Student.last_name?.toUpperCase()[0]}
                                </Avatar>
                              )}
                              <div className="post-details ">
                                <h4 className="large-text mx-2">
                                  {req.Student.first_name +
                                    " " +
                                    req.Student.last_name}
                                </h4>
                                <p className="small-text mx-2">
                                  {t("Requested On")}-
                                  {moment(req.requested_on).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="dots-button">
                                <TagButton
                                  size={"100px"}
                                  title={"Pending"}
                                  buttonStyle={orangeTagStyle}
                                  icon="fa fa-circle"
                                  iconSize="6px"
                                />
                              </div>
                            </div>
                            <div className="alert alert-secondary d-flex justify-content-between align-items-center">
                              <div
                                style={{ display: "grid", textAlign: "center" }}
                              >
                                <h6>{formattedDate.split(" ")[0]}</h6>
                                <h3>{formattedDate.split(" ")[1]}</h3>
                                <span style={{ color: "gray" }}>
                                  {formattedDate.split(" ")[2]}
                                </span>
                              </div>
                              <i
                                className="fa fa-long-arrow-right "
                                aria-hidden="true"
                              ></i>

                              <div
                                style={{ display: "grid", textAlign: "center" }}
                              >
                                <h6>{formattedEndDate.split(" ")[0]}</h6>
                                <h3>{formattedEndDate.split(" ")[1]}</h3>
                                <span style={{ color: "gray" }}>
                                  {formattedEndDate.split(" ")[2]}
                                </span>
                              </div>
                            </div>
                            <div className="card-content-details">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="large-text">
                                    {t("Leave Type")}
                                  </div>
                                  <p>
                                    {req.Request_type !== null
                                      ? req.Request_type?.type
                                      : "-"}
                                  </p>
                                </div>

                                <div>
                                  <div className="large-text">
                                    {t("Request By")}
                                  </div>
                                  <p>
                                    {req.Requested_By.firstName +
                                      " " +
                                      req.Requested_By.lastName}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="large-text">{t("Note")}</div>
                                <p
                                  className="wrapTwoLine"
                                  // style={{
                                  //   overflow: "hidden",
                                  //   display: "-webkit-box",
                                  //   WebkitLineClamp: 2,
                                  //   WebkitBoxOrient: "vertical",
                                  //   textOverflow: "ellipsis",
                                  //   whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                                  //   maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                                  // }}
                                >
                                  {Util.isValidData(req.note)
                                    ? req.note
                                    : t("no note added..")}
                                </p>
                              </div>
                              {/* <div className="d-flex align-item-center gap-1">
                              <CommentIcon style={{ color: "gray" }} />
                              <span>Comments</span>
                            </div>
                          </div> */}
                            </div>
                            <div className="row">
                              <div className="col-6"> </div>
                              <div className="col-6">
                                <button
                                  type="button"
                                  className={`${
                                    Util.hasPermission("ATTENDANCE_LEAVE")
                                      ? "btn custom-primary btn-block"
                                      : "no-permission"
                                  }`}
                                  onClick={() => {
                                    setApprovalConfirmation(true);
                                    setApproveByUserId(req.requested_by);
                                    setRequestId(req.id);
                                    setApprove(true);
                                  }}
                                >
                                  {t("Approve")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="row p-5">
                    <div className="col-lg-4 offset-lg-4">
                      <p className="text-center text-muted">
                        {t("No Request found")}:-(
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>

      <ModalComponent
        show={approvalConfirmation}
        onHide={() => {
          setApprovalConfirmation(false);
        }}
        title={t("Approval")}
        subTitle={t("Are you sure to approve the leave(s)")}
        size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setApprovalConfirmation(false);
        }}
        onConfirmClick={handleApprove}
      />
    </div>
  );
}
export default ToastHOC(LeavePending);
