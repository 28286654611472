import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Modal, Button } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import ApiCalling from "../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import ModalContent from "../../../components/common/ModalContent";

function Subject(props) {
  const { t } = useTranslation("");
  const { setIsToast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [subjectData, setSubjectData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [subjectNameError, setSubjectNameError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSubjects, setTotalSubjects] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("Subject");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedSubjectList = [...subjectData].sort((a, b) => {
    if (sortColumn === "Subject") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return 0;
  });

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const handleShow = () => {
    setShowModal(true);
    setSubjectName("");
  };
  const handleClose = () => {
    setShowModal(false);
    setSubjectName("");
    setSubjectNameError("");
    setEditValue(null);
  };
  const handleSave = (e) => {
    e.preventDefault();
    setSubjectNameError("");
    let error = false;
    if (subjectName === "") {
      setSubjectNameError(t("Subject is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (editValue) {
        const data = {
          id: editValue.id,
          name: subjectName,
        };
        ApiCalling.apiCallBodyDataPost("subject/update", data)
          .then((res) => {
            if (res.data.status == true) {
              setIsLoading(false);
              handleClose();
              subjectList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const data = {
          name: subjectName,
        };
        ApiCalling.apiCallBodyDataPost("subject/add", data)
          .then((res) => {
            if (res.data.status == true) {
              setIsLoading(false);
              handleClose();
              subjectList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`subject/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setIsToast(t("Subject Deleted Successfully"));
        setCurrentPage(1);
        subjectList();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const subjectList = async () => {
    setIsLoading(true);
    let data = {
      page_size: recordPerPage,
      page_number: currentPage,
    };
    ApiCalling.apiCallBodyDataPost("subject/search", data)
      .then((res) => {
        setSubjectData(res.data.data);
        setTotalPages(res.data.totalPages);
        setTotalSubjects(res.data.total);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    subjectList();
  }, [currentPage, recordPerPage]);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {/* <PageHeader
            HeaderText="Dashboard"
            Breadcrumb={[{ name: "Subject" }]}
          /> */}
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={handleShow}
              >
                {t("Register new Subject")}
              </button>
              <Modal
                show={showModal}
                onHide={handleClose}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={() => {
                  setIsClickedOnce(false);
                  if (editValue !== null) {
                    setSubjectName(editValue.name);
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue !== null
                      ? t("Edit Subject")
                      : t("Register Subject")}
                  </h6>

                  <button className="hidden-btn" onClick={handleClose}>
                    <img
                      src={Close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <ModalContent isLoading={isLoading}>
                  <Modal.Body>
                    <div className="input-filed text-start">
                      <label className="basic-text required">
                        {t("Subject Title")}
                      </label>
                      <input
                        className={`form-control custom-input ${
                          subjectNameError.length > 0 && "input-error"
                        }`}
                        type="text"
                        value={subjectName}
                        onChange={(event) => {
                          setSubjectName(event.target.value);
                          setSubjectNameError("");
                        }}
                      />
                      {subjectNameError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {subjectNameError}
                        </span>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn custom-primary" onClick={handleSave}>
                      {t("Save")}
                    </Button>
                  </Modal.Footer>
                </ModalContent>
              </Modal>
            </div>
          </div>
          <div className="row clearfix">
            <div className="table-div p-0 overflow-hidden">
              <div className="col-lg-12 col-md-12 m-0 p-0">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th scope="col">
                              {t("Subject Name")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("Subject");
                                }}
                              />
                            </th>

                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {subjectData.length > 0 ? (
                          <tbody>
                            {sortedSubjectList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>

                                <td className="text-right">
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowModal(true);
                                      setEditValue(item);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(item.id);
                                    }}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no subject. Click the 'Register new Subject' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {subjectData.length > 0 && (
                        <div className="small text-muted mb-3 pl-3 border-top py-3">
                          <i>
                            {totalSubjects} {t("Subject found")}.
                          </i>
                        </div>
                      )}
                      {totalPages > 0 && (
                        <div
                          className="row m-0 border-top"
                          style={{ height: "72px" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2">
                              <div
                                className="basic-text"
                                style={{ fontWeight: "400" }}
                              >
                                {t("Students Per Page")}
                              </div>
                              <div>
                                <select
                                  name=""
                                  id=""
                                  className="select-dropdown custom-input"
                                  value={recordPerPage}
                                  onChange={(e) => {
                                    setRecordPerPage(e.target.value);
                                  }}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                            </div>
                            <div>
                              <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                              >
                                <div
                                  className={`link-button ${
                                    currentPage == 1 ? "disabled" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePreviousClick();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <KeyboardArrowLeftIcon />
                                  <span
                                    className="basic-text"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {t("Previous")}
                                  </span>
                                </div>
                                <Pagination
                                  count={totalPages}
                                  page={currentPage}
                                  onChange={(event, page) => {
                                    setCurrentPage(page);
                                  }}
                                  size="small"
                                  hideNextButton
                                  hidePrevButton
                                />
                                <div
                                  className={`link-button ${
                                    currentPage == totalPages ? "disabled" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleNextClick();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="basic-text"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {t("Next")}
                                  </span>
                                  <KeyboardArrowRightIcon />
                                </div>
                              </Stack>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Delete")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t("Are you sure, to delete this Subject?")}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Subject);
