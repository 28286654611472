import React, { useEffect, useState } from "react";
import { Avatar, Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ApiCalling from "../../../network/ApiCalling";
import { useSelector } from "react-redux";
import Invalid from "../../../assets/Icon/invalid.svg";
import Calender from "../../../assets/Icon/calendar.svg";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Util from "../../../Util";

export default function AddLeaveRequest({
  isVisible,
  isClose,
  notification,
  isSave,
}) {
  const { t } = useTranslation();
  const selected = useSelector((state) => state.SelectedStudentReducer);
  const user = Util.getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [LeaveTypeList, setLeaveTypeList] = useState([]);
  const [leaveTypeId, setLeaveTypeId] = useState(0);
  const [leaveTypeError, setLeaveTypeError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState("");
  const [note, setNote] = useState("");

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          startDateError?.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));
  const CustomInput1 = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          endDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const changeAttendanceParameterId = (e) => {
    setLeaveTypeId(e.target.value);
    setLeaveTypeError("");
  };
  const changeStartDate = (date) => {
    const formattedDate = Util.getFormattedDate(date);
    setStartDate(formattedDate);
    setStartDateError("");
  };
  const changeEndDate = (date) => {
    const formattedDate = Util.getFormattedDate(date);
    setEndDate(formattedDate);
    setEndDateError("");
  };
  const changeNote = (e) => {
    setNote(e.target.value);
  };
  const reset = () => {
    setLeaveTypeId(0);
    setStartDate(null);
    setEndDate(null);
    setNote("");
    setLeaveTypeError("");
    setStartDateError("");
    setEndDateError("");
  };
  const handleSave = () => {
    let error = false;
    if (leaveTypeId == 0) {
      setLeaveTypeError(t("Please Select Reason"));
      error = true;
    }
    if (startDate == null) {
      setStartDateError(t("start date is required"));
      error = true;
    }
    if (endDate == null) {
      setEndDateError(t("end date is required"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const data = {
        requested_by: user.id,
        student_id: selected.selectedStudent.id,
        start_date: startDate,
        end_date: endDate,
        leave_request_type_id: parseInt(leaveTypeId),
        note: note,
      };
      ApiCalling.apiCallBodyDataPost("leave-request/add", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            notification(res.data.message);
            isSave();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getLeaveTypeList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `leave-request-type/list`,
      selected.selectedStudent.student.School.id
    )
      .then((res) => {
        setIsLoading(false);
        setLeaveTypeList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (selected !== null && selected.selectedStudent?.id > 0) {
      getLeaveTypeList();
    }
  }, [selected]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isVisible}
        onClose={() => {
          isClose();
          reset();
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {t("Request Leave for")}{" "}
                {Util.isValidData(selected) &&
                  selected?.selectedStudent?.student?.first_name +
                    " " +
                    selected?.selectedStudent?.student?.last_name}
              </div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  isClose();
                  reset();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="input-filed">
                <label className="basic-text required">{t("Reason")}</label>
                <select
                  className={`select-dropdown form-select custom-input ${
                    leaveTypeError.length > 0 ? "input-error" : ""
                  }`}
                  value={leaveTypeId}
                  onChange={changeAttendanceParameterId}
                >
                  <option value={0} disabled>
                    {t("select")}
                  </option>
                  {LeaveTypeList?.map((att) => {
                    return (
                      <option key={att.id} value={att.id}>
                        {att.type}
                      </option>
                    );
                  })}
                </select>
                {leaveTypeError.length > 0 && (
                  <span className="invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {leaveTypeError}
                  </span>
                )}
              </div>
              <div className="input-filed">
                <label className="basic-text required">
                  {t("Leave Start Date")}
                </label>
                <DatePicker
                  wrapperClassName=""
                  placeholderText="Select Date"
                  selected={startDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={changeStartDate}
                  // className="datePicker"
                  popperPlacement="bottom"
                  // maxDate={new Date()}
                  customInput={<CustomInput icon={Calender} />}
                />

                {startDateError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {startDateError}
                  </span>
                )}
              </div>
              <div className="input-filed">
                <label className="basic-text required">
                  {t("Leave End Date")}
                </label>
                <DatePicker
                  wrapperClassName=""
                  placeholderText="Select Date"
                  selected={endDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={changeEndDate}
                  // className="datePicker"
                  popperPlacement="bottom"
                  // maxDate={new Date()}
                  customInput={<CustomInput1 icon={Calender} />}
                  minDate={new Date(startDate)}
                />

                {endDateError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {endDateError}
                  </span>
                )}
              </div>
              <div className="input-filed ">
                <span className="basic-text ">{t("Note")}</span>
                <textarea
                  style={{
                    width: "100%",
                    minHeight: "120px",
                    height: "120px",
                  }}
                  type="text"
                  className="form-control custom-input"
                  onChange={changeNote}
                  value={note}
                />
              </div>
            </div>
            <div
              className="col-12 mt-2 border-top"
              style={{
                backgroundColor: "white",
                position: "absolute",
                bottom: "0px",
                padding: "10px",
                left: 0,
                right: 0,
              }}
            >
              <button
                className="secondary-md-btn btn ml-2"
                onClick={() => {
                  isClose();
                  reset();
                }}
              >
                {t("Cancel")}
              </button>
              <button
                style={{ float: "right" }}
                className="primary-lg-btn btn mr-1"
                onClick={handleSave}
              >
                {t("Save")}
              </button>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </>
  );
}
