import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["en"];
const selectedLanguage = localStorage.getItem("selectedLanguage");
const availableLanguages = [{ name: "ar" }, { name: "en" }, { name: "fr" }];
// const availableLanguages = [
//   { name: "ar", dir: "rtl" },
//   { name: "en", dir: "ltr" },
//   { name: "fr", dir: "ltr" },
// ];
// document.body.dir = availableLanguages.find(lang => lang.name === selectedLanguage)?.dir || 'ltr';
i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    detection: {
      order: ["navigator"], // to detect the user's preferred language
      checkWhitelist: true, // options for language detection
    },
    whitelist: availableLanguages,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for React
    },

    loadPath: "/locales/{{lng}}/translation.json",
    load: "languageOnly",
    lng: selectedLanguage !== "null" ? selectedLanguage : fallbackLng,
  });
// function handleLanguageChange(lng) {
//   const isRTL = lng === "ar";

//   document.body.dir = isRTL ? "rtl" : "ltr";
// }

// i18n.on("languageChanged", handleLanguageChange);

export default i18n;
