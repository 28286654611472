import React, { useEffect, useState } from "react";
import NotificationAlert from "../Dashbord/NotificationAlert";
import LocalErrorBoundary from "../../components/common/LocalErrorBoundary";
import RecaptchaWrapper from "./RecaptchaWrapper";

export const ToastComponent = ({
  showNotification,
  setShowNotification,
  title,
  subtitle = "",
  type = "success",
}) => {
  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showNotification, setShowNotification]);

  return (
    <>
      {showNotification && (
        <NotificationAlert
          icon={`fa ${
            type === "success" ? "fa-check" : type === "error" ? "fa-close" : ""
          }`}
          title={title}
          subTitle={subtitle.length > 0 && subtitle}
          onClose={() => {
            setShowNotification(false);
          }}
          type={type}
        />
      )}
    </>
  );
};

const ToastHOC = (WrappedComponent) => {
  function HOC(props) {
    const [showNotification, setShowNotification] = useState(false);
    const [isToast, setIsToast] = useState("");
    const [isSubToast, setIsSubToast] = useState("");
    const [toastType, setToastType] = useState("success");

    const setToastState = (isComponent) => {
      setIsToast(isComponent);
      setShowNotification(true);
    };

    const setSubToastState = (isComponent) => {
      setIsSubToast(isComponent);
    };

    const setToastTypeState = (type = "success") => {
      setToastType(type);
    };

    useEffect(() => {
      if (isToast.length > 0) {
        setShowNotification(true);
        const timer = setTimeout(() => {
          setIsToast("");
          setIsSubToast("");
          setToastType("success");
          setShowNotification(false);
        }, 5000);

        return () => clearTimeout(timer);
      }
    }, [isToast, setIsToast]);

    return (
      <>
        {showNotification && (
          <NotificationAlert
            icon={`fa ${
              toastType === "success"
                ? "fa-check"
                : toastType === "error"
                ? "fa-close"
                : ""
            }`}
            title={isToast}
            subTitle={isSubToast.length > 0 && isSubToast}
            onClose={() => {
              setIsToast("");
              setIsSubToast("");
              setShowNotification(false);
            }}
            type={toastType}
          />
        )}

        <LocalErrorBoundary>
          <RecaptchaWrapper path={props?.location?.pathname}>
            <WrappedComponent
              {...props}
              setIsToast={setToastState}
              setIsSubToast={setSubToastState}
              setToastType={setToastTypeState}
            />
          </RecaptchaWrapper>
        </LocalErrorBoundary>
      </>
    );
  }

  return HOC;
};

export default ToastHOC;
