import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import { Modal, Button } from "react-bootstrap";
import { Rings } from "react-loader-spinner";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import Util from "../../../Util";
import ModalContent from "../../../components/common/ModalContent";

function LeaveRequestType(props) {
  const { setIsToast } = props;
  const { t } = useTranslation();
  const [showLeaveRequest, setShowLeaveRequest] = useState(false);
  const [leaveRequestTypeList, setLeaveRequestTypeList] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [leaveTypeError, setLeaveTypeError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sortColumn, setSortColumn] = useState("Terms");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedLeaveRequestTypeList = [...leaveRequestTypeList].sort((a, b) => {
    if (sortColumn === "Type") {
      return sortDirection === "asc"
        ? a.type.localeCompare(b.type)
        : b.type.localeCompare(a.type);
    }
    return 0;
  });

  const handleAddLeaveRequestType = () => {
    setShowLeaveRequest(true);
    setEditValue(null);
    setLeaveType("");
    setLeaveTypeError("");
  };

  const handleClose = () => {
    setShowLeaveRequest(false);
    setLeaveType("");
    setLeaveTypeError("");
  };
  const handleSaveAndClose = () => {
    setLeaveTypeError("");
    let error = false;
    if (leaveType === "") {
      setLeaveTypeError(t("Leave Type is required"));
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          type: leaveType,
        };

        ApiCalling.apiCallBodyDataPost("leave-request-type/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowLeaveRequest(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getLeaveRequestList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          type: leaveType,
        };
        ApiCalling.apiCallBodyDataPost("leave-request-type/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowLeaveRequest(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getLeaveRequestList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const getLeaveRequestList = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("leave-request-type/list")
      .then((res) => {
        // console.log("Leave Request Type", res.data);
        setLeaveRequestTypeList(res.data || []);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`leave-request-type/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Leave Request Type Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getLeaveRequestList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLeaveRequestList();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right px-0 mb-2">
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={handleAddLeaveRequestType}
            >
              {t("Add Leave Request Type")}
            </button>
          </div>
        </div>

        <Modal
          show={showLeaveRequest}
          onHide={handleClose}
          animation={true}
          centered={true}
          dialogClassName="modal-view"
          onShow={() => {
            setIsClickedOnce(false);
            if (editValue !== null) {
              setLeaveType(editValue.type);
            }
          }}
        >
          <div className="d-flex justify-content-between align-items center p-4 border-bottom">
            <h6>
              {editValue !== null
                ? t("Edit Leave Request Type")
                : t("Add Leave Request Type")}
            </h6>

            <button className="hidden-btn" onClick={handleClose}>
              <img src={Close} className="svg-icon" height={20} width={20} />
            </button>
          </div>
          <ModalContent isLoading={isLoading}>
            <Modal.Body>
              <div className="input-filed text-start">
                <label className="basic-text required">{t("Leave Type")}</label>
                <input
                  className={`form-control custom-input ${
                    leaveTypeError.length > 0 ? "input-error" : ""
                  }`}
                  placeholder={t("Leave Type")}
                  type="text"
                  value={leaveType}
                  onChange={(event) => {
                    setLeaveType(event.target.value);
                    setLeaveTypeError("");
                  }}
                />
                {leaveTypeError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {leaveTypeError}
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn custom-primary"
                onClick={handleSaveAndClose}
              >
                {t("Save")}
              </Button>
            </Modal.Footer>
          </ModalContent>
        </Modal>

        <div className="row clearfix ">
          <div className="table-div overflow-hidden mt-2 p-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="body">
                {!isLoading ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">
                            {t("Leave Request Type")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Type");
                              }}
                            />
                          </th>

                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {leaveRequestTypeList.length > 0 ? (
                        <tbody>
                          {sortedLeaveRequestTypeList.map((t, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{t.type}</td>

                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(t);
                                      setShowLeaveRequest(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(t.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={4}>
                              {t(
                                "There are no Leave Request Type. Click the 'Add Leave Request Type ' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showDeleteConfirmation}
        onHide={() => {
          setShowDeleteConfirmation(false);
        }}
        title={t("Delete")}
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        subTitle={t("Are you sure, to delete this Leave Type?")}
        size="sm"
        onCancelClick={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirmClick={handleDelete}
      />
    </div>
  );
}
export default ToastHOC(LeaveRequestType);
