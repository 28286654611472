import React from "react";
import { Avatar } from "@mui/material";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Table from "../../../components/common/Table";
import { imageStudentList, invoiceDownload } from "../../CommonImage";
import Util from "../../../Util";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import { InvoiceStatus } from "../../../Enums";
import ApiCalling from "../../../network/ApiCalling";

const PendingInvoicesTable = ({
  invoices,
  message,
  setIsVisibleInvoice,
  setInvoiceId,
  setInvoiceData,
  setIsVisiblePayment,
  setStudentId,
  setStudentName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const school = Util.getSelectedSchool();

  const sendReminder = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/send-mail/${id}`)
      .then((res) => {
        if (res.data.status === true) {
          message(res.data.message);
        } else {
          message(res.data.message || "Email not sent", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const DownloadInvoice = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/download-mail/${id}`)
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            window.open(invoiceDownload + res.data.invoice, "_blank");
          }, 2000);
        } else {
          message(res.data.message || "Unable to download", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
        message("Unable to download", "error");
      });
  };

  const data = [
    ...invoices.map((invoice) => {
      return {
        studentName: `${invoice.Student.first_name} ${invoice.Student.last_name}`,
        invoice: invoice.id,
        feeType: Util.getListDisplay(
          invoice.types.map((f) => f.Fee_Type),
          2,
          "\n"
        ),
        amount: invoice.total,
        status: Util.getInvoiceStatus2(invoice.status),
        balance: invoice.due,
      };
    }),
  ];

  const columns = [
    {
      name: "studentName",
      content: <div className="m-l-5">{t("Students Name")}</div>,
      sortable: true,
    },
    { name: "invoice", content: <>{t("Invoice")}</>, sortable: true },
    { name: "feeType", content: <>{t("Fee Type")}</>, sortable: true },
    { name: "amount", content: <>{t("Amount")}</>, sortable: true },
    { name: "status", content: <>{t("Status")}</>, sortable: true },
    { name: "balance", content: <>{t("Balance")}</>, sortable: true },
    { name: "action" },
  ];

  const customRenderers = [
    ...invoices.map((a, index) => {
      return {
        studentName: (data) => (
          <div className="d-flex align-items-center m-l-5">
            {a.Student.photo ? (
              <img
                className="rounded-circle "
                style={{
                  width: "40px",
                  height: "40px",
                }}
                src={imageStudentList + a.Student.photo}
                alt=""
              />
            ) : (
              <Avatar aria-label="recipe" className="avatar-font">
                {a.Student.first_name.toUpperCase()[0] +
                  a.Student.last_name.toUpperCase()[0]}
              </Avatar>
            )}
            <span
              className="ml-3 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/profileTabs",
                  studentId: a.Student.id,
                });
              }}
              style={{ fontWeight: "600" }}
            >
              {data}
            </span>
          </div>
        ),
        invoice: (data) => (
          <>
            {Util.invoiceIdFormat(data)}
            <div className="small-text">
              {a.last_payment_date !== null
                ? moment(a.last_payment_date).format(
                    Util.getMomentDateTimeFormat(school.date_format)
                  )
                : ""}
            </div>
          </>
        ),
        feeType: (data) => (
          <>
            {a.types.length > 0 ? (
              <p style={{ whiteSpace: "pre-line", marginBottom: 0 }}>{data}</p>
            ) : (
              "-"
            )}
          </>
        ),
        amount: (data) => <>{Util.amountFormat(data)}</>,
        status: (data) => (
          <TagButton
            title={data}
            buttonStyle={
              a.status === 0
                ? { ...redTagStyle, paddingLeft: "12px", paddingRight: "12px" }
                : a.status === 1
                ? {
                    ...orangeTagStyle,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
                : {
                    ...greenTagStyle,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
            }
            icon="fa fa-circle"
            iconSize="6px"
          />
        ),
        balance: (data) => <>{Util.invoiceFormat(data)}</>,
        action: () => (
          <div className="dropdown">
            <button
              className="btn btn-lg custom-primary "
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                color: "#ffffff",
                padding: "5px, 10px",
              }}
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {InvoiceStatus.PARTIAL_PAID === a.status ||
                (InvoiceStatus.UNPAID === a.status && (
                  <>
                    <li>
                      <button
                        className="dropdown-item"
                        target="_blank"
                        onClick={() => sendReminder(a.id)}
                      >
                        {t("Send Reminder")}
                      </button>
                    </li>
                  </>
                ))}
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setIsVisibleInvoice(true);
                    setInvoiceId(a.id);
                  }}
                >
                  {t("View")}
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    DownloadInvoice(a.id);
                  }}
                >
                  {t("Download")}
                </button>
              </li>
              {InvoiceStatus.PARTIAL_PAID === a.status ||
                (InvoiceStatus.UNPAID === a.status && (
                  <>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          setInvoiceData(a);
                          setIsVisiblePayment(true);
                          setStudentId(a.Student.id);
                          setStudentName(
                            a.Student.first_name + " " + a.Student.last_name
                          );
                        }}
                      >
                        {t("Log a payment")}
                      </button>
                    </li>
                  </>
                ))}
            </ul>
          </div>
        ),
      };
    }),
  ];

  return (
    <Table data={data} columns={columns} customRenderers={customRenderers} />
  );
};

export default PendingInvoicesTable;
