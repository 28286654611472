import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import moment from "moment/moment";
import Table from "../../../components/common/Table";
import { imageStudentList } from "../../CommonImage";
import Util from "../../../Util";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import Sort from "../../../assets/Icon/Short.png";

const PendingCreditsTable = ({
  allCreditList,
  setIsVisiblePayment,
  setPaymentId,
  handleSort
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const school = Util.getSelectedSchool();

  const data = [
    ...allCreditList.map((item) => {
      const invoice_settled_ids = item?.invoice_settled.map(
        (c) => c.invoice_id
      );

      const invoice_settled = invoice_settled_ids.map((id) => {
        return Util.invoiceIdFormat(id);
      });

      return {
        studentName: `${item.Student.first_name} ${item.Student.last_name}`,
        payer: `${item.User.firstName} + " " + ${item.User.lastName}`,
        date: item.date,
        adjusted: Util.getListDisplay(invoice_settled, 2, "\n"),
        amount: item.amount,
        status: Util.getPaymentStatus(item.status),
        balance: item.not_used_amount,
      };
    }),
  ];

  const columns = [
    {
      name: "studentName",
      content: (
        <div className="m-l-5">
          {t("Student Name")}{" "}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("student.first_name");
            }}
          />
        </div>
      ),
    },
    {
      name: "payer",
      content: (
        <>
          {t("Payer")}{" "}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("payment.payment_method");
            }}
          />
        </>
      ),
    },
    {
      name: "date",
      content: (
        <>
          {t("Date")}{" "}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("payment.date");
            }}
          />
        </>
      ),
    },
    {
      name: "adjusted",
      content: (
        <>
          {t("adjusted against invoice")}{" "}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            // onClick={() => {
            //   handleSort("payment.date");
            // }}
          />
        </>
      ),
    },
    {
      name: "amount",
      content: (
        <>
          {t("Amount")}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("payment.amount");
            }}
          />
        </>
      ),
    },
    {
      name: "status",
      content: (
        <>
          {t("Status")}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("payment.status");
            }}
          />
        </>
      ),
    },
    {
      name: "balance",
      content: (
        <>
          {t("Balance")}
          <img
            src={Sort}
            className="ml-1 fa-sort"
            alt=""
            onClick={() => {
              handleSort("payment.not_used_amount");
            }}
          />
        </>
      ),
    },
  ];

  const customRenderers = [
    ...allCreditList.map((c, index) => {
      const invoice_settled_ids = c?.invoice_settled.map((c) => c.invoice_id);

      const invoice_settled = invoice_settled_ids.map((id) => {
        return Util.invoiceIdFormat(id);
      });

      return {
        studentName: (data) => (
          <div className="d-flex align-items-center">
            {c.Student.photo ? (
              <img
                className="rounded-circle "
                style={{
                  width: "40px",
                  height: "40px",
                }}
                src={imageStudentList + c.Student.photo}
                alt=""
              />
            ) : (
              <Avatar aria-label="recipe" className="avatar-font">
                {c.Student.first_name.toUpperCase()[0] +
                  c.Student.last_name.toUpperCase()[0]}
              </Avatar>
            )}
            <span
              className="ml-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/profileTabs",
                  studentId: c.Student.id,
                });
              }}
              style={{ fontWeight: "600" }}
            >
              {data}
            </span>
          </div>
        ),
        payer: (data) => (
          <>
            {c.payment_method}
            <div className="small-text">
              {t("By")} {data}
            </div>
          </>
        ),
        date: (data) => (
          <>
            {moment(data).format(
              Util.getMomentDateTimeFormat(school.date_format)
            )}
          </>
        ),
        adjusted: (data) => (
          <>
            {invoice_settled.length > 0 ? (
              <p style={{ whiteSpace: "pre-line" }}>{data}</p>
            ) : (
              "-"
            )}
          </>
        ),
        amount: (data) => <>{Util.amountFormat(data)}</>,
        status: (data) => (
          <TagButton
            title={data}
            buttonStyle={
              c.status === 0
                ? { ...redTagStyle, paddingLeft: "12px", paddingRight: "12px" }
                : c.status === 1
                ? {
                    ...orangeTagStyle,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
                : {
                    ...greenTagStyle,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
            }
            icon="fa fa-circle"
            iconSize="6px"
          />
        ),
        balance: (data) => <>{Util.balanceFormat(data)}</>,
        action: () => (
          <div className="dropdown">
            <button
              className="btn btn-lg custom-primary"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                color: "#ffffff",
                padding: "5px, 10px",
              }}
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setIsVisiblePayment(true);
                    setPaymentId(c.id);
                  }}
                >
                  {t("View")}
                </button>
              </li>
            </ul>
          </div>
        ),
      };
    }),
  ];

  return (
    <Table data={data} columns={columns} customRenderers={customRenderers} />
  );
};

export default PendingCreditsTable;
