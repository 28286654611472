import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../components/common/Logo";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Dropdown, Modal, Nav, Toast } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import backIcon from "../assets/Icon/chevrons-back.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ChevronDown from "../assets/Icon/chevron-down gray.svg";
import dashboard from "../assets/Icon/dashboard.svg";
import Calander from "../assets/Icon/calendar.svg";
import Activity from "../assets/Icon/activity.svg";
import Announcment from "../assets/Icon/megaphone-alt.svg";
import Biling from "../assets/Icon/bill.svg";
import Util from "../Util";
import ApiCalling from "../network/ApiCalling";
import { SocketContext } from "../context/SocketContext";
import Messaging from "../assets/Icon/chat.svg";
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux";
import { Toggle } from "../actions/ToggleAction";
import { setUnreadMessagesCount } from "../actions/SetUnreadMessagesAction";

export default function ParentSidebar() {
  const location = useLocation();
  const user = Util.getUser();
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [activeTabReference, setActiveTabReference] = useState("");
  const [isBillingOpen, setIsBillingOpen] = useState(false);

  const { unreadMessagesCount } = useSelector(
    (state) => state.messagingReducer
  );

  const getListItemClass = () => {
    switch (location.pathname) {
      case "/ParentsDashboard":
      case "/":
        return "ParentsDashboard";
      case "/appcalendar":
        return "appcalendar";
      case "/messaging":
        return "messaging";
      case "/ChildActivity":
        return "ChildActivity";
      case "/leave-request-list":
        return "leave-request-list";
      case "/invoices":
        return "invoices";
      case "/payments":
        return "payments";

      default:
        return "";
    }
  };

  const handleFocus = async () => {
    console.log("onFocus ***************");
    makeUserOnline();
  };

  const makeUserOnline = () => {
    // console.log("makeUserOnline");

    if (Util.isValidData(user)) {
      socket.emit("new-user-add", user?.id);

      socket.on("new-message", (res) => {
        // console.log("****");
        // console.log("makeUserOnline new-message:", res);
        // console.log("****");
        dispatch(setUnreadMessagesCount(res));
      });
    }
  };

  const getUnreadMessageCount = () => {
    ApiCalling.apiCallParamsGet("chatroom/unread/" + user.id)
      .then((res) => {
        dispatch(setUnreadMessagesCount(res.data.unread));
      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  };

  const onBackPress = () => {
    document.getElementById("left-sidebar").style.left = "-260px";
    document.getElementById("main-content").style.width = "100%";
    dispatch(Toggle(true));
  };
  useEffect(() => {
    const reference = getListItemClass();
    setActiveTabReference(reference);
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      window.addEventListener("focus", handleFocus);
      await makeUserOnline();
      getUnreadMessageCount();
    };

    fetchData();

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);
  return (
    <div>
      <div
        id="left-sidebar"
        className="sidebar offcanvas-body"
      >
        <div className="d-flex justify-content-between align-items-center mx-2 mt-3">
          <Logo wrapperClass={"logo-inside"} />

          <IconButton
            aria-label="close"
            size="small"
            className="float-right"
            onClick={(e) => {
              e.preventDefault();
              onBackPress();
            }}
          >
            <img src={backIcon} alt="back" />
          </IconButton>
        </div>
        <div className="sidebar-scroll">
          <div
            // className={sideMenuTab[0] ? "tab-pane active show" : "tab-pane"}
            className={"tab-pane"}
            id="menu"
          >
            <Nav id="left-sidebar-nav" className="sidebar-nav mt-4">
              <ul id="main-menu" className="metismenu">
                <li
                  className={
                    activeTabReference === "ParentsDashboard" ? "active":""
                  }
                  onClick={() => {
                    setIsBillingOpen(false);
                  }}
                >
                  <Link to="ParentsDashboard">
                    <i>
                      {/* <HomeOutlined fontSize="small" /> */}
                      <img
                        src={dashboard}
                        alt="Dashboard"
                        className="svg-icon"
                      />
                    </i>
                    {t("Dashboard")}
                  </Link>
                </li>

                {/* <li
                        className={activeKey === "myChild" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          this.activeMenutabContainer("calendarContainer");
                        }}
                      >
                        <Link to="myChild">
                          <i
                            className="fa fa-child fa-2x fa-fw"
                            aria-hidden="true"
                          ></i>
                          {t("My Children")}
                        </Link>
                        <ul className="collapse"> </ul>
                      </li> */}
                {user.Parents.length > 0 && (
                  <>
                    <li
                      className={
                        activeTabReference === "appcalendar" ? "active":""
                      }
                      onClick={() => {
                        setIsBillingOpen(false);
                      }}
                    >
                      <Link to="appcalendar">
                        <i>
                          <img
                            src={Calander}
                            alt="Calander"
                            className="svg-icon"
                          />
                        </i>
                        {t("Calendar")}
                      </Link>
                    </li>
                    <li
                      className={activeTabReference === "messaging" ? "active":""}
                      onClick={() => {
                        setIsBillingOpen(false);
                      }}
                    >
                      <Link
                        to="messaging"
                        className="d-flex align-items-center "
                      >
                        <i>
                          <img
                            src={Messaging}
                            alt="Messaging"
                            className="svg-icon"
                          />
                        </i>
                        <span className="d-flex align-items-center gap-5">
                          <span>{t("Messaging")}</span>
                          {unreadMessagesCount > 0 ? (
                            <span className="navbar-counter-badge">
                              {unreadMessagesCount}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        activeTabReference === "ChildActivity" ? "active" :""
                      }
                      onClick={() => {
                        setIsBillingOpen(false);
                      }}
                    >
                      <Link to="ChildActivity">
                        <i>
                          <img
                            src={Activity}
                            alt="Activity"
                            className="svg-icon"
                          />
                        </i>
                        {t("Activity")}
                      </Link>
                    </li>
                    <li
                      className={
                        activeTabReference === "leave-request-list" ? "active" :""
                      }
                      onClick={() => {
                        setIsBillingOpen(false);
                      }}
                    >
                      <Link to="leave-request-list">
                        <i>
                          <img
                            src={Activity}
                            alt="leave-req"
                            className="svg-icon"
                          />
                        </i>
                        {t("My Leave Requests")}
                      </Link>
                    </li>

                    {/* <li
                            className={
                              activeKey === "get-announcement" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.activeMenutabContainer("calendarContainer");
                            }}
                          >
                            <Link to="get-announcement">
                          
                              <i>
                                <img
                                  src={Announcment}
                                  alt="Announcment"
                                  className="svg-icon"
                                />
                              </i>

                              {t("Announcement")}
                            </Link>
                            <ul className="collapse"> </ul>
                          </li> */}

                    <li
                      id="BillingContainer"
                      className={`${
                        (activeTabReference === "invoices" ? "active" :"") ||
                        (activeTabReference === "payments" ? "active":"") ||
                        (isBillingOpen ? "active" :"")
                      }`}
                    >
                      <a
                        className="has-arrow"
                        onClick={(e) => {
                          setIsBillingOpen(!isBillingOpen);
                        }}
                      >
                        <i>
                          <img src={Biling} alt="Biling" className="svg-icon" />
                        </i>
                        <span>{t("Billing")}</span>
                      </a>
                      <ul className={`collapse ${isBillingOpen ? "in":""}`}>
                        <li
                          className={
                            activeTabReference === "invoices" ? "active":""
                          }
                        >
                          <Link to="invoices">{t("Invoices")}</Link>
                        </li>
                        <li
                          className={
                            activeTabReference === "payments" ? "active":""
                          }
                        >
                          <Link to="payments">{t("Payments")}</Link>
                        </li>
                      </ul>
                    </li>
                  </>
                )}

                {/* <hr style={{ backgroundColor: "#ddd" }} /> */}
              </ul>
            </Nav>
          </div>
        </div>
        {/* <ParentFloatActionButton /> */}
      </div>
    </div>
  );
}
