import React from "react";

export default function InvoiceCard({
  header,
  amount,
  amounts,
  contentColor,
  children,
}) {
  const totalAmount = amounts?.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div>
      <div className="card2" style={{ minHeight: "390px" }}>
        <div className="body">
          <div className="post-header">
            <div className="post-details">
              <h4 className="large-text">{header}</h4>
            </div>
          </div>
          <hr />
          <div className="card-content-details">
            <h2 className="text-start">{amount}</h2>

            <div className="d-flex">
              {amounts?.map((item, index) => (
                <div
                  key={index}
                  className="invoice-content"
                  style={{
                    width:
                      totalAmount === 0
                        ? `${100 / amounts.length}%`
                        : `${(item.amount / totalAmount) * 100}%`,
                    backgroundColor: item.color,
                  }}
                ></div>
              ))}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
export function CardDetail({ title, amount, circle, textColor }) {
  return (
    <>
      <div className="card-detail">
        <div className="d-flex align-items-center gap-3">
          <div
            className="invoice-card-circle"
            style={{ backgroundColor: circle }}
          ></div>
          <div className="invoice-card-text" style={{ color: textColor }}>
            {title}
          </div>
        </div>
        <strong>{amount}</strong>
      </div>
    </>
  );
}
