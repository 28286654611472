import React, { useEffect, useRef, useState } from "react";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Calander from "../../../assets/Icon/calendar.svg";
import PhoneInput from "react-phone-number-input";
import fileIcon from "../../../assets/Icon/file.svg";
import { studentIdentity } from "../../CommonImage";
import CustomFileUpload from "../CustomFileUpload";
import moment from "moment/moment";
import Loader from "../../../components/common/Loader";
import Invalid from "../../../assets/Icon/invalid.svg";

const StaffPersonalInfo = ({
  data,
  userId,
  notification,
  personalInfoEdit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const datepickerRef = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [dob, setDob] = useState(null);
  const [age, setAge] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [raceId, setRaceId] = useState(0);
  const [ethnicityId, setEthnicityId] = useState(0);
  const [nationalityId, setNationalityId] = useState(0);
  const [raceList, setRaceList] = useState([]);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [country, setCountry] = useState([]);
  const [identityUpload, setIdentityUpload] = useState("");
  const [identityURL, setIdentityURL] = useState("");
  const [identityFileName, setIdentityFileName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("");

  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [selectedRace, setSelectedRace] = useState("");

  useEffect(() => {
    if (userId > 0) {
      if (data !== null) {
        setFirstName(data?.firstName);
        setLastName(data?.lastName);
        if (Util.isValidData(data?.dob)) {
          setDob(new Date(data?.dob));
          const calculatedAge = Util.calculateAge(new Date(data?.dob));
          setAge(calculatedAge);
        } else {
          setAge(0);
          setDob(null);
        }
        setEmail(data?.email);
        setContactNumber(data?.mobile);
        setRaceId(Util.isValidData(data?.race_id) ? data?.race_id : 0);
        setEthnicityId(
          Util.isValidData(data?.ethnicity_id) ? data?.ethnicity_id : 0
        );
        setNationalityId(
          Util.isValidData(data?.nationality_id) ? data?.nationality_id : 0
        );
        setSelectedRace(data?.race);
        setSelectedEthnicity(data?.ethnicity);
        setSelectedNationality(data?.nationality);
        setIdentityFileName(
          Util.isValidData(data.identity) ? data.identity : ""
        );
      }
    }
  }, [data]);

  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };

  const handleIdentityDragOver = (e) => {
    e.preventDefault();
  };

  const handleIdentityDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setIdentityUpload(file);
        setIdentityURL(URL.createObjectURL(file));
      } else {
        notification(t("File size exceeds the limit of 25MB"));
      }
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input ref={ref} className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const handleIdentityUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };

  const changeDate = (date) => {
    setDob(date);
    const calculatedAge = Util.calculateAge(date);
    setAge(calculatedAge);
  };
  const getRaceList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("race")
      .then((res) => {
        setIsLoading(false);
        setRaceList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ethnicitylist = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("ethnicity")
      .then((res) => {
        setIsLoading(false);
        setEthnicityList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCountry = async () => {
    ApiCalling.apiCallBodyDataGet("country/list")
      .then((res) => {
        setCountry(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCountry();
    getRaceList();
    ethnicitylist();
  }, []);

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);

  const resetPersonalInfo = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setEmailError("");
    setContactNumberError("");
  };

  const handleSavePersonalDetails = (e) => {
    e.preventDefault();
    setFirstNameErr("");
    let error = false;
    if (firstName == "") {
      setFirstNameErr(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameErr(t("Last Name is required"));
      error = true;
    }
    if (email == "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (contactNumber == undefined || contactNumber == null) {
      setContactNumberError(t("Phone is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", userId);
      if (firstName) {
        formData.append("firstName", firstName);
      }
      if (lastName) {
        formData.append("lastName", lastName);
      }
      if (dob) {
        formData.append("dob", dob.toISOString());
      }
      if (email) {
        formData.append("email", email);
      }
      if (contactNumber) {
        formData.append("mobile", contactNumber);
      }
      if (raceId) {
        formData.append("race_id", raceId);
      }
      if (nationalityId) {
        formData.append("nationality_id", nationalityId);
      }
      if (ethnicityId) {
        formData.append("ethnicity_id", ethnicityId);
      }
      if (identityUpload) {
        formData.append("identity", identityUpload);
      }
      ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            resetPersonalInfo();
            notification(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="row text-start">
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text required">{t("First Name")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(firstName) ? firstName : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className={`form-control custom-input ${
                        firstNameErr.length > 0 ? "input-error" : ""
                      }`}
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      }}
                    />
                    {firstNameErr.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {firstNameErr}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text required">{t("Last Name")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(lastName) ? lastName : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className={`form-control custom-input ${
                        lastNameErr.length > 0 ? "input-error" : ""
                      }`}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      }}
                    />
                    {lastNameErr.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {lastNameErr}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text">{t("Date of Birth")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(dob)
                      ? moment(dob).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )
                      : "-"}
                  </div>
                ) : (
                  <>
                    <DatePicker
                      ref={datepickerRef}
                      placeholderText="Select Date"
                      selected={dob}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={changeDate}
                      dateFormat={school.date_format}
                      className="datePicker"
                      popperPlacement="bottom"
                      maxDate={new Date()}
                      customInput={<CustomInput />}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text ">{t("Age")}</span>

                <div className="large-bold-text mt-3">
                  {Util.isValidData(dob) ? (
                    <>
                      {age?.years} {t("years")} {age?.months} {t("months")}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text required">{t("Email")}</span>
                {!personalInfoEdit ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#07112b",
                    }}
                  >
                    {Util.isValidData(email) ? email : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className={`form-control custom-input ${
                        emailError.length > 0 ? "input-error" : ""
                      }`}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                      }}
                    />
                    {emailError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {emailError}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text required">{t("Phone Number")}</span>
                {!personalInfoEdit ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#07112b",
                    }}
                  >
                    {Util.isValidData(contactNumber) ? contactNumber : "-"}
                  </div>
                ) : (
                  <>
                    {/* <PhoneInput
                        international
                        defaultCountry={defaultCountry}
                        value={contactNumber}
                        onChange={(value) => {
                          setContactNumber(value);
                          setContactNumberError("");
                        }}
                        CustomPhoneInput={<CustomPhoneInput/>}
                      /> */}

                    <div
                      tabIndex={0}
                      className={`form-control custom-input ${
                        contactNumberError.length > 0 ? "input-error" : ""
                      }`}
                    >
                      <PhoneInput
                        tabIndex="-1"
                        international
                        defaultCountry={defaultCountry}
                        value={contactNumber}
                        onChange={(value) => {
                          setContactNumber(value);
                          setContactNumberError("");
                        }}
                      />
                    </div>
                    {contactNumberError.length > 0 && (
                      <span className="text-danger mt-2 invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {contactNumberError}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text">{t("Race")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedRace) ? selectedRace : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      name=""
                      id=""
                      className="select-dropdown form-select custom-input"
                      value={raceId}
                      onChange={(e) => {
                        setRaceId(e.target.value);
                      }}
                    >
                      <option disabled value={0}>
                        {t("Select Race")}
                      </option>
                      {raceList.map((item) => (
                        <option
                          placeholder="race"
                          key={item.id}
                          value={item.id}
                        >
                          {item.race}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text">{t("Nationality")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedNationality)
                      ? selectedNationality
                      : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      className="select-dropdown form-select custom-input"
                      value={nationalityId}
                      onChange={(e) => {
                        setNationalityId(e.target.value);
                      }}
                    >
                      <option disabled value={0}>
                        {t("Select Nationality")}
                      </option>
                      {country?.map(
                        (item) =>
                          item.nationality && (
                            <option
                              placeholder="grade"
                              key={item.id}
                              value={item.id}
                            >
                              {item.nationality}
                            </option>
                          )
                      )}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text ">{t("Ethnicity")}</span>
                {!personalInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedEthnicity)
                      ? selectedEthnicity
                      : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      name=""
                      id=""
                      className="select-dropdown form-select custom-input"
                      value={ethnicityId}
                      onChange={(e) => {
                        setEthnicityId(e.target.value);
                      }}
                    >
                      <option disabled value={0}>
                        {t("Select Ethnicity")}
                      </option>
                      {ethnicityList?.map((item) => (
                        <option
                          placeholder="grade"
                          key={item.id}
                          value={item.id}
                        >
                          {item.ethnicity}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </div>

            <div className={!personalInfoEdit ? "col-lg-6" : "col-lg-12"}>
              <div className="input-filed ">
                <span className="basic-text ">{t("ID Upload")}</span>
                {!personalInfoEdit ? (
                  <>
                    {Util.isValidData(identityFileName) ? (
                      <div className="d-flex align-items-center">
                        <img
                          src={fileIcon}
                          className="img-responsive"
                          style={{
                            background: "#F3F4F6",
                            padding: "5px",
                            margin: "3px",
                            borderRadius: "8px",
                            marginLeft: 0,
                          }}
                        ></img>
                        <a
                          target="_blank"
                          className="large-bold-text mx-1"
                          href={studentIdentity + identityFileName}
                        >
                          {identityFileName}
                        </a>
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </>
                ) : (
                  <>
                    <CustomFileUpload
                      onChange={handleIdentityUpload}
                      fileName={
                        identityUpload ? identityUpload.name : identityFileName
                      }
                      showFile={
                        identityURL.length > 0
                          ? identityURL
                          : studentIdentity + identityFileName
                      }
                      onDragOver={handleIdentityDragOver}
                      onDrop={handleIdentityDrop}
                      alertMessage={
                        identityFileName.length > 0 &&
                        t("(incase you select new file it will replace old)")
                      }
                      acceptedDocument={t("PDF,Doc (max file 25mb)")}
                      accept={"application/pdf,.docs,.docx"}
                      className={"custom-file"}
                    />
                  </>
                )}
              </div>
            </div>

            {personalInfoEdit ? (
              <div className="text-end mt-3">
                {userId > 0 && (
                  <button
                    className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                    onClick={() => {
                      onCancel();
                      resetPersonalInfo();
                    }}
                  >
                    {t("Cancel")}
                  </button>
                )}
                <button
                  className="primary-lg-btn rounded-btn btn"
                  onClick={handleSavePersonalDetails}
                >
                  {t("Save")}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StaffPersonalInfo;
