import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import FatherIcon from "../../../assets/Icon/Father.svg";
import MotherIcon from "../../../assets/Icon/Mother.svg";
import Call from "../../../assets/Icon/call.svg";
import { imageMyProfile, imageStudentList } from "../../CommonImage";
import unknownImg from "../../../assets/images/image 4.png";
import Util from "../../../Util";
import AddContactComponent from "./AddContactComponent";
import Sort from "../../../assets/Icon/Short.svg";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle } from "../TagButtonStyle";
import { Modal } from "react-bootstrap";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";

const ContactDetailsCard = ({ data, studentId, notification, onSave }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [parentList, setParentList] = useState([]);
  const [addContact, setAddContact] = useState(false);
  const parentId = data?.parents?.User?.id;
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [invitationPopup, setInvitationPopup] = useState(false);
  const [invitationMailNote, setInvitationMailNote] = useState("");
  const [invitationMailNoteError, setInvitationMailNoteError] = useState("");

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const handleAllSelectedParent = (e) => {
    if (e.target.checked) {
      setSelectedUserId(parentList.map((item) => item.User.id));
    } else {
      setSelectedUserId([]);
    }
  };

  const ParentChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedUserId([...selectedUserId, id]);
    } else {
      const data = selectedUserId.filter((s) => s !== id);
      setSelectedUserId(data);
    }
  };

  const openInvitationNotePopup = () => {
    setInvitationPopup(true);
  };

  const invitationSent = () => {
    setInvitationPopup(false);
    setSelectedUserId([]);
    setInvitationMailNote("");
    setInvitationMailNoteError("");
  };

  const inviteAllSelected = () => {
    if (invitationMailNote === "") {
      setInvitationMailNoteError(t("Please enter Note"));
      return;
    }
    const selectedParents = parentList.filter((parent) =>
      selectedUserId.includes(parent.User.id)
    );
    const selectedParentIds = selectedParents.map((parent) => parent.id);
    const data = {
      note: invitationMailNote,
      parentIds: selectedParentIds,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("user/send-parent-invitation", data)
      .then((res) => {
        if (res.data.success == true) {
          notification(t("Invitation(s) Sent Successfully"));
          setInvitationPopup(false);
          setSelectedUserId([]);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (studentId) {
      let isParentListMounted = true;
      const getParentList = () => {
        const data = {
          student_id: studentId,
          ascdesc: sortOrder ? "desc" : "asc",
          order_by: sortBy,
        };
        setIsLoading(true);
        ApiCalling.apiCallBodyDataPost("parent/by-student", data).then(
          (res) => {
            if (isParentListMounted) {
              setIsLoading(false);

              setParentList(res?.data);
            }
          }
        );
      };
      getParentList();
      return () => {
        isParentListMounted = false;
      };
    }
  }, [studentId, sortOrder, sortBy]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="medium-large-text font-color-primary ">
          {t("Contact")}
        </div>
        {studentId > 0 && (
          <div className="d-flex align-items-center gap-2">
            {selectedUserId.length > 0 && (
              <button
                className="btn secondary-sm-btn"
                onClick={openInvitationNotePopup}
              >
                {t("Send Invite")}
              </button>
            )}
            <button
              className="link-button"
              onClick={() => {
                setAddContact(true);
              }}
            >
              <i className="fas fa-plus mr-1"></i> {t("Add New Contact")}
            </button>
          </div>
        )}
      </div>
      <AddContactComponent
        show={addContact}
        onSave={() => {
          setAddContact(false);
          onSave();
        }}
        notification={(msg) => {
          if (msg) {
            notification(msg);
          }
        }}
        studentId={studentId}
        onClose={() => {
          setAddContact(false);
        }}
        AddPrimary={false}
      />
      <div className="row">
        <div className="col-lg-12">
          <div
            className="table-wrapper table-div"
            style={{ overflowX: "auto" }}
          >
            {!isLoading ? (
              <table className="table">
                <thead>
                  <tr className="text-nowrap">
                    <th
                      scope="col"
                      width="36px"
                      style={{ position: "relative", top: "8px" }}
                    >
                      <label className="fancy-checkbox element-left">
                        <input
                          type="checkbox"
                          name="allselect"
                          onChange={handleAllSelectedParent}
                        />
                        <span></span>
                      </label>
                    </th>
                    <th scope="col">
                      {t("Name")}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("user.firstName");
                        }}
                      />
                    </th>

                    <th scope="col">
                      {t("Relation")}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("relation.type");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Phone Number")}

                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("user.mobile");
                        }}
                      />
                    </th>

                    <th scope="col">
                      {t("Email")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("user.email");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Registration")}{" "}
                      {/* <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("user.email");
                        }}
                      /> */}
                    </th>
                  </tr>
                </thead>

                {parentList.length == 0 ? (
                  <tfoot>
                    <tr>
                      <td colSpan={5}>{t("No parents found")}</td>
                    </tr>
                  </tfoot>
                ) : (
                  <tbody>
                    {parentList?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <label className="fancy-checkbox element-left">
                            <input
                              type="checkbox"
                              name={item.first_name}
                              onChange={ParentChecked}
                              value={item.User.id}
                              checked={selectedUserId.includes(item.User.id)}
                              disabled={item?.User?.email_verified}
                            />
                            <span></span>
                          </label>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {Util.isValidData(item?.User?.avatar) ? (
                              <img
                                className="rounded-circle "
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={imageMyProfile + item?.User?.avatar}
                                alt=""
                              />
                            ) : (
                              <img
                                className="rounded-circle "
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={unknownImg}
                                alt=""
                              />
                            )}

                            <a
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push({
                                  pathname: "/parentContact",
                                  studentId: studentId,
                                  data: data,
                                  id: item?.id,
                                  UserId: item?.User?.id,
                                });
                              }}
                            >
                              {item.User.firstName + " " + item.User.lastName}
                            </a>
                          </div>
                        </td>
                        <td>{item?.StudentParentRelationType?.type}</td>

                        <td>
                          {item.User.mobile ? (
                            <>
                              <img
                                src={Call}
                                alt=""
                                width={"16px"}
                                className="mr-1"
                              />
                              <span>{item.User.mobile}</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{item.User.email}</td>
                        <td>
                          <TagButton
                            style={"btn btn-secondary"}
                            size={"100px"}
                            title={`${
                              item.User.email_verified ? "Completed" : "Pending"
                            }`}
                            buttonStyle={
                              item.User.email_verified
                                ? greenTagStyle
                                : orangeTagStyle
                            }
                            icon="fa fa-circle"
                            iconSize="6px"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            ) : (
              <Loader />
            )}
          </div>
          <Modal
            show={invitationPopup}
            onHide={invitationSent}
            animation={true}
            centered={true}
            className="modal-view"
          >
            <div className="d-flex justify-content-between align-items center p-4 border-0">
              <h6>{t("Add Note")}</h6>

              <button className="hidden-btn" onClick={invitationSent}>
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            <Modal.Body>
              <div className="input-filed">
                <span className="basic-text required">{t("Note")}</span>
                <textarea
                  style={{ width: "100%", minHeight: "120px" }}
                  className={`form-control ${
                    invitationMailNoteError.length > 0
                      ? "input-error"
                      : "custom-input"
                  }`}
                  type="text"
                  placeholder={t("Enter Note")}
                  value={invitationMailNote}
                  onChange={(event) => {
                    setInvitationMailNote(event.target.value);
                    setInvitationMailNoteError("");
                  }}
                />
                {invitationMailNoteError.length > 0 && (
                  <span className="invalid-error">
                    {" "}
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {invitationMailNoteError}
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button
                className="btn btn-lg custom-primary"
                onClick={inviteAllSelected}
              >
                {t("Invite All Selected User")} ({selectedUserId.length})
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ContactDetailsCard;
