import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Box, SwipeableDrawer } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import Util from "../../Util";
import Search from "../../assets/Icon/Search.svg";
import close from "../../assets/Icon/close.svg";
import ApiCalling from "../../network/ApiCalling";
import Loader from "../../components/common/Loader";
import SchoolsTable from "./SchoolsTable";
import Switch from "../../components/common/Switch";
import { ToastComponent } from "../HOC/ToastHOC";

const SchoolsSection = ({
  selectedSchoolIds,
  setSelectedSchoolIds,
  changeSchoolsStatus,
  fetchSchoolsTrigger,
}) => {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();

  const [schools, setSchools] = useState([]);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchClick, setSearchClick] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [roleId, setRoleId] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedRowData, setClickedRowData] = useState(null);
  const [showSchoolSettingsDrawer, setShowSchoolSettingsDrawer] =
    useState(false);
  const [isPaymentsAllowed, setIsPaymentsAllowed] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastSubtitle, setToastSubtitle] = useState("");
  const [toastType, setToastType] = useState("success");

  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const handleAllSelectedSchools = (e) => {
    if (e.target.checked) {
      setSelectedSchoolIds(schools.map((item) => item.id));
    } else {
      setSelectedSchoolIds([]);
    }
  };

  const schoolsChecked = (event) => {
    event.nativeEvent.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // event.preventDefault();
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedSchoolIds([...selectedSchoolIds, id]);
    } else {
      const data = selectedSchoolIds.filter((s) => s !== id);
      setSelectedSchoolIds(data);
    }
  };

  useEffect(() => {
    console.log("getAllSchools useEffect");
    let mounted = true;

    const getAllSchools = () => {
      const data = {
        page_size: recordPerPage,
        page_number: currentPage,
        role_id: roleId,
        str_search: searchClick,
        order_by: sortBy,
        ascdesc: sortOrder ? "desc" : "asc",
      };
      setIsLoading(true);
      ApiCalling.apiCallBodyDataPost("school/all", data)
        .then((res) => {
          if (mounted) {
            setSchools(res.data?.data);
            setTotalPages(res.data?.totalPages);
            setTotal(res.data?.total);
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getAllSchools();

    return () => {
      mounted = false;
    };
  }, [
    currentPage,
    recordPerPage,
    roleId,
    searchClick,
    sortBy,
    sortOrder,
    fetchSchoolsTrigger,
  ]);

  useEffect(() => {
    if (!clickedRowData?.id) return;
    const clickedSchool = schools.find(
      (school) => school?.id === clickedRowData?.id
    );
    setIsPaymentsAllowed(clickedSchool?.is_payments_allowed);
  }, [clickedRowData?.id, schools]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6 text-start">
          <h3>Manage schools</h3>
        </div>
        <div className={`col-md-6  ${getLanguage !== "ar" && "text-end"}`}>
          {selectedSchoolIds.length > 0 ? (
            <div className="d-flex justify-content-end gap-2">
              <button onClick={() => changeSchoolsStatus({ active: true })}>
                Change to active
              </button>
              <button onClick={() => changeSchoolsStatus({ active: false })}>
                Change to inactive
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="table-div">
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search "
                placeholder={t("Search school by email or name")}
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon">
                  <img
                    src={search.length > 0 ? close : Search}
                    className="svg-icon"
                    alt=""
                    onClick={searchClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="table-wrapper">
                <SchoolsTable
                  schools={schools}
                  selectedSchoolIds={selectedSchoolIds}
                  handleAllSelectedSchools={handleAllSelectedSchools}
                  schoolsChecked={schoolsChecked}
                  setClickedRowData={setClickedRowData}
                  setShowSchoolSettingsDrawer={setShowSchoolSettingsDrawer}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                />
              </div>

              {totalPages > 0 && (
                <div
                  className="row m-0 table-footer  border-top"
                  style={{ height: "72px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <div>{t("Students Per Page")}</div>
                      <select
                        style={{ width: "72px", cursor: "pointer" }}
                        className="select-dropdown custom-input"
                        value={recordPerPage}
                        onChange={(e) => {
                          setRecordPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <div>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <div
                          className={`link-button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePreviousClick();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <KeyboardArrowLeftIcon />
                          <span className="basic-bold-text">
                            {t("Previous")}
                          </span>
                        </div>
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={(event, page) => {
                            setCurrentPage(page);
                          }}
                          size="small"
                          hideNextButton
                          hidePrevButton
                        />
                        <div
                          className={`link-button ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNextClick();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="basic-bold-text">{t("Next")}</span>
                          <KeyboardArrowRightIcon />
                        </div>
                      </Stack>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <SwipeableDrawer
        anchor={getLanguage === "ar" ? "left" : "right"}
        open={showSchoolSettingsDrawer}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", height: "100%" }} role="presentation">
            <div
              className="container-fluid d-flex flex-column"
              style={{
                height: "100%",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div className="flex-grow-1" style={{ padding: "20px" }}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="drawer-header">{t("Settings")}</div>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => {
                      setShowSchoolSettingsDrawer(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="large-bold-text">{clickedRowData?.name}</div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>Allow Payments</div>
                  <Switch
                    checked={isPaymentsAllowed}
                    onChange={(event) => {
                      setIsPaymentsAllowed(event.target.checked);
                    }}
                  />
                </div>
              </div>

              <div
                className="border-top "
                style={{
                  position: "sticky",
                  width: "656px",
                  bottom: "0px",
                  right: getLanguage !== "ar" && "0px",
                  background: "#ffffff",
                  padding: "10px",
                }}
              >
                <button
                  className="secondary-md-btn btn ml-2"
                  onClick={() => {
                    setShowSchoolSettingsDrawer(false);
                    // onClose();
                    // reset();
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  style={{ float: "right" }}
                  className="primary-lg-btn btn"
                  onClick={() => {
                    const clickedSchool = schools.find(
                      (school) => school?.id === clickedRowData?.id
                    );
                    const data = {
                      school_id: clickedSchool.id,
                      is_payments_allowed: isPaymentsAllowed,
                    };
                    ApiCalling.apiCallBodyDataPost(
                      "school/payment/status",
                      data
                    )
                      .then((res) => {
                        if (res.data.status) {
                          setShowSchoolSettingsDrawer(false);
                          setShowNotification(true);
                          setToastTitle(res.data?.message);
                          setToastSubtitle("");
                          setToastType("success");
                        } else {
                          setShowSchoolSettingsDrawer(false);
                          setToastTitle("Error");
                          setToastSubtitle(res.data?.message);
                          setToastType("error");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  {t("Save")}
                </button>
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      {showNotification && (
        <ToastComponent
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          title={toastTitle}
          subtitle={toastSubtitle}
          type={toastType}
        />
      )}
    </div>
  );
};

export default SchoolsSection;
