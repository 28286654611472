import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Sort from "../../../assets/Icon/Short.svg";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import ModalContent from "../../../components/common/ModalContent";

const ActivityCategoryList = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();

  const [isLoading, setIsLoading] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [newActivityName, setNewActivityName] = useState("");
  const [newActivityNameError, setNewActivityNameError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalActivities, setTotalActivities] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("Category");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedCategoryList = [...activityList].sort((a, b) => {
    if (sortColumn === "Category") {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }
    return 0;
  });

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const activityHandleClose = () => {
    setShowAddModal(false);
    setNewActivityNameError("");
    setNewActivityName("");
  };
  const activityHandleSaveAndClose = () => {
    setNewActivityNameError("");
    let error = false;
    if (newActivityName === "") {
      setNewActivityNameError(t("Category is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          title: newActivityName,
        };
        ApiCalling.apiCallBodyDataPost("activity-category/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              setShowAddModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("Error");
          });
      } else {
        const data = {
          title: newActivityName,
          school_id: school.id,
          is_default: 0,
        };
        ApiCalling.apiCallBodyDataPost("activity-category/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              setShowAddModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };
  const activityHandleShow = () => {
    setShowAddModal(true);
    setEditValue(null);
    setNewActivityNameError("");
    setNewActivityName("");
  };
  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`activity-category/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setIsToast(t("activity Deleted Successfully"));
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getData = async () => {
    setIsLoading(true);
    let data = {
      page_size: recordPerPage,
      page_number: currentPage,
    };
    ApiCalling.apiCallParamsGet("activity-category/list")
      .then((res) => {
        setIsLoading(false);
        setActivityList(res.data || []);
        setTotalPages(res.data.totalPages);
        setTotalActivities(res.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    // setIsLoading(false);
    getData();
  }, [currentPage, recordPerPage]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="heading text-right mb-3 px-0 ">
            <div></div>
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={activityHandleShow}
            >
              {t("Register a Category")}
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="body p-0">
            <div className="table-div overflow-hidden p-0">
              <div className="col-lg col-md-12 p-0 m-0">
                <Modal
                  show={showAddModal}
                  onHide={activityHandleClose}
                  animation={true}
                  centered={true}
                  dialogClassName="modal-view"
                  onShow={() => {
                    setIsClickedOnce(false);
                    if (editValue !== null) {
                      setNewActivityName(editValue.title);
                    }
                  }}
                >
                  <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                      {editValue !== null
                        ? t("Edit a Category")
                        : t("Register a Category")}
                    </h6>

                    <button
                      className="hidden-btn"
                      onClick={activityHandleClose}
                    >
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <ModalContent isLoading={isLoading}>
                    <Modal.Body>
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Category")}
                        </label>
                        <input
                          className={`form-control ${
                            newActivityNameError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={newActivityName}
                          onChange={(event) => {
                            setNewActivityName(event.target.value);
                            setNewActivityNameError("");
                          }}
                        />
                        {newActivityNameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {newActivityNameError}
                          </span>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn custom-primary"
                        onClick={activityHandleSaveAndClose}
                      >
                        {t("Save")}
                      </Button>
                    </Modal.Footer>
                  </ModalContent>
                </Modal>

                {!isLoading ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th></th>
                          <th scope="col">
                            {t("Category")}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Category");
                              }}
                            />
                          </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {activityList.length > 0 ? (
                        <tbody>
                          {sortedCategoryList?.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                {(currentPage - 1) * recordPerPage + index + 1}
                              </td>
                              <td>{Util.getIconActivity(item)}</td>
                              <td>{item.title}</td>
                              <td className="text-right">
                                {!item.is_default && (
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(item);
                                      setShowAddModal(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                )}
                              </td>
                              <td>
                                {!item.is_default && (
                                  <a
                                    href="#"
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(item.id);
                                    }}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={5}>
                              {t(
                                "No Category Found. Click the 'Register a Category' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                    {activityList.length > 0 && (
                      <div className="small text-muted mb-3 pl-3">
                        <i>
                          {activityList.length} {t("Categories found")}.
                        </i>
                      </div>
                    )}
                    {totalPages > 0 && (
                      <div
                        className="row m-0 border-top"
                        style={{ height: "72px" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <div className="basic-text">
                              {t("Students Per Page")}
                            </div>
                            <div>
                              <select
                                name=""
                                id=""
                                className="select-dropdown custom-input"
                                value={recordPerPage}
                                onChange={(e) => {
                                  setRecordPerPage(e.target.value);
                                }}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <Stack
                              spacing={2}
                              direction="row"
                              alignItems="center"
                            >
                              <div
                                className={`link-button ${
                                  currentPage == 1 ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePreviousClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <KeyboardArrowLeftIcon />
                                <span
                                  className="basic-text"
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("Previous")}
                                </span>
                              </div>
                              <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={(event, page) => {
                                  setCurrentPage(page);
                                }}
                                size="small"
                                hideNextButton
                                hidePrevButton
                              />
                              <div
                                className={`link-button ${
                                  currentPage == totalPages ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNextClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span
                                  className="basic-text"
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("Next")}
                                </span>
                                <KeyboardArrowRightIcon />
                              </div>
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
              <ModalComponent
                show={showDeleteConfirmation}
                onHide={() => {
                  setShowDeleteConfirmation(false);
                }}
                title={t("Delete")}
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                subTitle={t("Are you sure, to delete this Activity Category?")}
                size="sm"
                onCancelClick={() => {
                  setShowDeleteConfirmation(false);
                }}
                onConfirmClick={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(ActivityCategoryList);
