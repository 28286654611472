import { SET_UNREAD_MESSAGES } from "../actions/SetUnreadMessagesAction";

const initialState = {
  unreadMessagesCount: 0,
};

const messagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_MESSAGES:
      console.log("setting SET_UNREAD_MESSAGES", action.payload);
      return {
        ...state,
        unreadMessagesCount: action.payload,
      };
    default:
      return state;
  }
};

export default messagingReducer;
