import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../../../network/ApiCalling";
import Select from "react-dropdown-select";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Sort from "../../../../assets/Icon/Short.svg";
import Loader from "../../../../components/common/Loader";
import Util from "../../../../Util";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";

export default function CheckInReport() {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [studentStatus, setStudentStatus] = useState("All");
  const [studentId, setStudentId] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [checkInReportList, setCheckInReportList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const statusList = [
    { value: "All", title: "All" },
    { value: true, title: "Active" },
    { value: false, title: "Inactive" },
  ];
  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const ChangeDateRange = (range) => {
    if (range) {
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setStartDate();
    setEndDate();
  };
  const changeGrade = (data) => {
    setGradeId(data[0].id);
  };

  const changeRoom = (data) => {
    setRoomId(data[0].id);
  };
  const changeStatus = (data) => {
    setStudentStatus(data[0].value);
  };
  const changeStudent = (data) => {
    setStudentId(data[0].id);
  };

  const gradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const roomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };
  const getStudentList = () => {
    if (gradeId > 0 || roomId > 0) {
      setIsLoading(true);
      let data = {
        page_size: 1000,
        page_number: 1,
        classroom_id: roomId,
        grade_id: gradeId,
        str_search: "",
        active: "true",
        ascdesc: "asc",
        order_by: "",
      };
      ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
        const modifiedStudentList = res.data.data.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.last_name}`,
        }));
        setStudentList(modifiedStudentList);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    }
  };
  const exportToExcel = () => {
    const dataForExport = checkInReportList.map((stu) => ({
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),

      StudentStatus:
        studentStatus == true
          ? "Active"
          : studentStatus == false
          ? "InActive"
          : "All",
      StudentID: stu.id,
      StudentName: `${stu.Student?.first_name} ${stu.Student?.last_name}`,
      date: stu.date,
      Grade: stu?.Student?.grade?.title,
      classRoom: stu?.Student?.classroom?.name,
      checkInTime:
        stu?.checkin_time !== null
          ? moment(stu.checkin_time).format("HH:mm")
          : "-",
      checkOutTime:
        stu?.checkout_time !== null
          ? moment(stu.checkout_time).format("HH:mm")
          : "-",
      SignedInBy: stu?.Created_By.firstName + " " + stu?.Created_By.lastName,
      SignedOutBy:
        stu?.Modified_By !== null
          ? stu?.Modified_By.firstName + " " + stu?.Modified_By.lastName
          : "-",
      totalHours:
        stu.checkin_time && stu.checkout_time !== null
          ? moment(stu.checkout_time).diff(moment(stu.checkin_time), "hours")
          : "-",
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "checkIn Report");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };

  const getCheckInReportList = () => {
    const data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      grade_id: gradeId,
      classroom_id: roomId,
      isActive: studentStatus,
      student_id: studentId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("daily-attendance/get-checkin-report", data)
      .then((res) => {
        // console.log("getCheckIn report",res.data)
        setIsLoading(false);
        setCheckInReportList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (startDate && endDate !== null) {
      getCheckInReportList();
    }
  }, [
    startDate,
    endDate,
    gradeId,
    roomId,
    studentStatus,
    sortBy,
    sortOrder,
    studentId,
  ]);
  useEffect(() => {
    if (roomId > 0) {
      setStudentId(0);
      getStudentList();
    }
  }, [roomId]);
  useEffect(() => {
    gradeList();
    roomList();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid summary">
        <div className="row ">
          <div className="row ">
            <div className="col-lg-10 col-md-10 p-1">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <div>
                  <div>
                    <DateRangePicker
                      className="mr-2 custom-range-picker"
                      onChange={(range) => ChangeDateRange(range)}
                      onClean={handleCleanDate}
                      placeholder="Select Date"
                    />
                  </div>
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, title: "Grade" }, ...gradeData]}
                    labelField="title"
                    valueField="id"
                    values={[{ id: 0, title: "Grade" }]}
                    onChange={(values) => changeGrade(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, name: "Room" }, ...roomData]}
                    labelField="name"
                    valueField="id"
                    values={[{ id: 0, name: "Room" }]}
                    onChange={(values) => changeRoom(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[...statusList]}
                    labelField="title"
                    valueField="value"
                    values={[{ value: "all", title: "Student Status" }]}
                    onChange={(values) => changeStatus(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    placeholder="Student"
                    options={[{ id: 0, name: "Student" }, ...studentList]}
                    onChange={(values) => changeStudent(values)}
                    style={{ width: "100%" }}
                    labelField="name"
                    values={[{ id: 0, name: "Student" }]}
                    valueField="id"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 text-right ">
              <button
                className="btn btn-lg text-nowrap custom-primary m-1"
                type="button"
                onClick={exportToExcel}
                disabled={startDate == null}
              >
                {t("Export Excel")}
              </button>
            </div>
          </div>

          <div className="table-responsive table-div overflow-hidden mt-3 ">
            <div
              className="col-lg-12 col-md-12 p-0 m-0 "
              style={{ overflowX: "auto" }}
            >
              <div className="">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr className="text-nowrap">
                            <th scope="col">
                              {t("Date")}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("attendance.date");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Student Id")}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.id");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Student Name")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.first_name");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Room")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("classroom.name");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Grade")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("grade.title");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Check-In")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("attendance.checkin_time");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Check-Out")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("attendance.checkout_time");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Signed In By")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("created.firstName");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Signed Out By")}{" "}
                              <img
                                src={Sort}
                                className=" fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("modified.firstName");
                                }}
                              />
                            </th>
                            <th scope="col">{t("Total Hrs")} </th>
                          </tr>
                        </thead>
                        {startDate && endDate !== null ? (
                          <>
                            {checkInReportList.length > 0 ? (
                              <>
                                {checkInReportList?.map((val, index) => {
                                  const checkinTime =
                                    val.checkin_time !== null &&
                                    moment(val.checkin_time);
                                  const checkoutTime =
                                    val.checkout_time !== null &&
                                    moment(val.checkout_time);
                                  const totalHours =
                                    val.checkin_time &&
                                    val.checkout_time !== null &&
                                    checkoutTime.diff(checkinTime, "hours");

                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {moment(val.date).format(
                                            Util.getMomentDateTimeFormat(
                                              school.date_format
                                            )
                                          )}
                                        </td>

                                        <td>
                                          {Util.studentIdFormat(val.student_id)}
                                        </td>
                                        <td>
                                          {val.Student?.first_name +
                                            " " +
                                            val.Student?.last_name}
                                        </td>
                                        <td>{val.Student?.grade?.title}</td>
                                        <td>{val.Student?.classroom?.name}</td>
                                        <td>
                                          {val.checkin_time !== null
                                            ? moment(val.checkin_time).format(
                                                Util.getMomentDateTimeFormat(
                                                  school.time_format
                                                )
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {val.checkout_time !== null
                                            ? moment(val.checkout_time).format(
                                                Util.getMomentDateTimeFormat(
                                                  school.time_format
                                                )
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {val.Created_By.firstName +
                                            " " +
                                            val.Created_By.lastName}
                                        </td>
                                        <td>
                                          {val.Modified_By !== null
                                            ? val.Modified_By.firstName +
                                              " " +
                                              val.Modified_By.lastName
                                            : "-"}
                                        </td>
                                        <td>
                                          {val.checkin_time !== null &&
                                          val.checkout_time !== null
                                            ? totalHours
                                            : "-"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                              </>
                            ) : (
                              <tfoot>
                                <tr>
                                  <td colSpan={7}>{t("No Records found")}</td>
                                </tr>
                              </tfoot>
                            )}
                          </>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={10}>
                                {t(
                                  "Please select period to generate the report"
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
