import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Modal, Button, Spinner } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import ModalContent from "../../../components/common/ModalContent";

const TimingList = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [timingList, setTimingList] = useState([]);
  const [timingTitle, setTimingTitle] = useState("");
  const [timingTitleError, setTimingTitleError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTimingModal, SetShowTimingModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("Timing");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedTimingList = [...timingList].sort((a, b) => {
    if (sortColumn === "Timing") {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (sortColumn === "Student opted") {
      return sortDirection === "asc"
        ? a.Students.length - b.Students.length
        : b.Students.length - a.Students.length;
    }
    return 0;
  });

  const handleAddClose = () => {
    SetShowTimingModal(false);
    setTimingTitle("");
    setTimingTitleError("");
  };
  const handleAddShow = () => {
    SetShowTimingModal(true);
    setTimingTitle("");
    setTimingTitleError("");
    setEditValue(null);
  };

  const registerSaveAndClose = () => {
    let error = false;
    setTimingTitleError("");
    if (timingTitle === "") {
      setTimingTitleError(t("Title is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          title: timingTitle,
        };

        ApiCalling.apiCallBodyDataPost("timing/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              SetShowTimingModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          title: timingTitle,
        };
        ApiCalling.apiCallBodyDataPost("timing/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              SetShowTimingModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`timing/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setIsToast(t("Timing Deleted Successfully"));
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("timing/list").then((res) => {
      setTimingList(res.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            {/* <PageHeader
              HeaderText="Dashboard"
              Breadcrumb={[{ name: "Timing" }]}
            /> */}
            <div className="row">
              <div className="header text-right px-0 mb-2">
                <button
                  className={`${
                    Util.hasPermission("MASTERS_ADD")
                      ? "btn custom-primary"
                      : "no-permission"
                  }`}
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#fff", padding: "5px, 10px" }}
                  onClick={handleAddShow}
                >
                  {t("Register new Timing")}
                </button>
                <Modal
                  show={showTimingModal}
                  onHide={handleAddClose}
                  animation={true}
                  centered={true}
                  dialogClassName="modal-view"
                  onShow={() => {
                    setIsClickedOnce(false);
                    if (editValue !== null) {
                      setTimingTitle(editValue.title);
                    }
                  }}
                >
                  <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                      {editValue !== null
                        ? t("Edit Timing")
                        : t("Register Timing")}
                    </h6>

                    <button className="hidden-btn" onClick={handleAddClose}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <ModalContent isLoading={isLoading}>
                    <Modal.Body>
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Timing Title")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            timingTitleError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={timingTitle}
                          onChange={(event) => {
                            setTimingTitle(event.target.value);
                            setTimingTitleError("");
                          }}
                        />
                        {timingTitleError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {timingTitleError}
                          </span>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn custom-primary"
                        onClick={registerSaveAndClose}
                      >
                        {t("Save")}
                      </Button>
                    </Modal.Footer>
                  </ModalContent>
                </Modal>
              </div>
            </div>
            <div className="row clearfix ">
              <div className="table-div overflow-hidden mt-2 p-0">
                <div className="col-lg-12 col-md-12 p-0 m-0">
                  <div className="body">
                    {!isLoading ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th scope="col">
                                {t("Timing")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("Timing");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Student opted")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("Student opted");
                                  }}
                                />
                              </th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          {timingList.length > 0 ? (
                            <tbody>
                              {sortedTimingList?.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.title}</td>
                                  <td>{item.Students.length}</td>
                                  <td className="text-right">
                                    <a
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEditValue(item);
                                        SetShowTimingModal(true);
                                      }}
                                      className={`${
                                        !Util.hasPermission("MASTERS_EDIT") &&
                                        "no-permission"
                                      }`}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </a>
                                  </td>
                                  <td>
                                    {item.Students.length === 0 ? (
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowDeleteConfirmation(true);
                                          setDeleteId(item.id);
                                        }}
                                        className={`${
                                          Util.hasPermission("MASTERS_DELETE")
                                            ? "pl-5"
                                            : "no-permission"
                                        }`}
                                      >
                                        <i className="fa fa-trash-o text-danger"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={5}>
                                  {t(
                                    "There are no Timing. Click the 'Register new Timing' button to create one."
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                        {timingList.length > 0 && (
                          <div className="small text-muted pl-3 mb-3 border-top py-3">
                            <i>
                              {timingList.length} {t("Timing found")}.
                            </i>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <ModalComponent
                    show={showDeleteConfirmation}
                    onHide={() => {
                      setShowDeleteConfirmation(false);
                    }}
                    title={t("Delete")}
                    onCancel={t("Cancel")}
                    onConfirm={t("Confirm")}
                    subTitle={t("Are you sure, to delete this Time?")}
                    size="sm"
                    onCancelClick={() => {
                      setShowDeleteConfirmation(false);
                    }}
                    onConfirmClick={handleDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(TimingList);
