export const greenTagStyle = {
  border: "1px solid #15A84B",
  color: "#15A84B",
};

export const orangeTagStyle = {
  border: "1px solid #EAA12E",
  color: "#EAA12E",
};

export const redTagStyle = {
  border: "1px solid #ED3131",
  color: "#ED3131",
};
export const browneTagStyle = {
  border: "1px solid #9c4600",
  color: "#9c4600",
};
