import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import ModalComponent from "../Dashbord/ModalComponent";
import { ToastComponent } from "../HOC/ToastHOC";
import UserSchoolsSection from "./UserSchoolsSection";
import UsersSection from "./UsersSection";
import SchoolsSection from "./SchoolsSection";
import ErrorLogsSection from "./ErrorLogsSection";

const USERS_SCHOOLS = "USERS_SCHOOLS";
const USERS_GLOBAL = "USERS_GLOBAL";
const SCHOOLS = "SCHOOLS";
const ERRORS = "ERRORS";
const UBER_ADMIN = "UBER_ADMIN";

const Admin = () => {
  const [selectedUserSchoolsIds, setSelectedUserSchoolsIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [selectedUserSchoolsStatus, setSelectedUserSchoolsStatus] =
    useState(null);
  const [selectedUsersStatus, setSelectedUsersStatus] = useState(null);
  const [selectedSchoolsStatus, setSelectedSchoolsStatus] = useState(null);
  const [openUserSchoolsStatusPopup, setOpenUserSchoolsStatusPopup] =
    useState(false);
  const [openUsersStatusPopup, setOpenUsersStatusPopup] = useState(false);
  const [openSchoolsStatusPopup, setOpenSchoolsStatusPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastSubtitle, setToastSubtitle] = useState("");
  const [toastType, setToastType] = useState("success");
  const [fetchUserSchoolsTrigger, setFetchUserSchoolsTrigger] = useState(false);
  const [fetchUsersTrigger, setFetchUsersTrigger] = useState(false);
  const [fetchSchoolsTrigger, setFetchSchoolsTrigger] = useState(false);
  const [selectedTab, setSelectedTab] = useState(USERS_GLOBAL);
  const [switchState, setSwitchState] = useState(false);

  const { t } = useTranslation();
  const user = Util.getUser();

  const userSchoolRoles = user?.user_Schools?.map(
    (userSchool) => userSchool?.role?.reference
  );

  const isUberAdmin = userSchoolRoles.includes(UBER_ADMIN);

  const tabsStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: "#6418c3",
    },
  };

  const tabStyle = {
    textTransform: "none",
    fontSize: "14px",
    "&.Mui-selected": {
      color: "#6418c3",
      fontWeight: "600",
    },
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const changeSelectedMultipleStatus = ({ active }) => {
    setSelectedUserSchoolsStatus(active);
    console.log("selectedUserSchoolsIds", selectedUserSchoolsIds);
    setOpenUserSchoolsStatusPopup(true);
  };

  const changeUsersStatus = ({ active }) => {
    setSelectedUsersStatus(active);
    console.log("selectedUserIds", selectedUserIds);
    setOpenUsersStatusPopup(true);
  };

  const changeSchoolsStatus = ({ active }) => {
    setSelectedSchoolsStatus(active);
    console.log("selectedSchoolIds", selectedSchoolIds);
    setOpenSchoolsStatusPopup(true);
  };

  const handleConfirmUserSchoolsStatus = () => {
    const userSchoolIds = selectedUserSchoolsIds.join(",");

    const data = {
      user_school_ids: userSchoolIds,
      status: selectedUserSchoolsStatus,
    };

    console.log("data", data);

    ApiCalling.apiCallBodyDataPost(
      "users-school/update-users-school-status",
      data
    )
      .then((res) => {
        if (res.data.status) {
          setOpenUserSchoolsStatusPopup(false);
          setSelectedUserSchoolsIds([]);
          setSelectedUserSchoolsStatus();
          setShowNotification(true);
          setToastTitle(res.data?.message);
          setToastSubtitle("");
          setToastType("success");
          setFetchUserSchoolsTrigger(
            (prevFetchUserSchoolsTrigger) => !prevFetchUserSchoolsTrigger
          );
        } else {
          setOpenUserSchoolsStatusPopup(false);
          setShowNotification(true);
          setToastTitle("Error");
          setToastSubtitle(res.data?.message);
          setToastType("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmUsersStatus = () => {
    const userIds = selectedUserIds.join(",");

    const data = {
      user_ids: userIds,
      status: selectedUsersStatus,
    };

    console.log("data", data);

    ApiCalling.apiCallBodyDataPost("user/update-status", data)
      .then((res) => {
        if (res.data.status) {
          setOpenUsersStatusPopup(false);
          setSelectedUserIds([]);
          setSelectedUsersStatus();
          setShowNotification(true);
          setToastTitle(res.data?.message);
          setToastSubtitle("");
          setToastType("success");
          setFetchUsersTrigger(
            (prevFetchUsersTrigger) => !prevFetchUsersTrigger
          );
        } else {
          setOpenUsersStatusPopup(false);
          setShowNotification(true);
          setToastTitle("Error");
          setToastSubtitle(res.data?.message);
          setToastType("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmSchoolsStatus = () => {
    console.log("confirm schools status");
    const schoolIds = selectedSchoolIds.join(",");

    const data = {
      school_ids: schoolIds,
      status: selectedSchoolsStatus,
    };
    const email = {
      school_ids: schoolIds,
    };

    console.log("data", data);
    console.log(switchState);

    ApiCalling.apiCallBodyDataPost("school/update-status", data)
      .then((res) => {
        if (res.data.status) {
          setOpenSchoolsStatusPopup(false);
          setSelectedSchoolIds([]);
          setSelectedSchoolsStatus();
          setShowNotification(true);
          setToastTitle(res.data?.message);
          setToastSubtitle("");
          setToastType("success");
          setFetchSchoolsTrigger(
            (prevFetchUsersTrigger) => !prevFetchUsersTrigger
          );
          if (switchState) {
            ApiCalling.apiCallBodyDataPost("user/sendInviteEmail", email)
              .then((res) => {
                console.log(res);
                setSwitchState(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          setOpenSchoolsStatusPopup(false);
          setShowNotification(true);
          setToastTitle("Error");
          setToastSubtitle(res.data?.message);
          setToastType("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setSwitchState(!switchState);
  };

  const renderUsersSection = () => (
    <UsersSection
      selectedUserIds={selectedUserIds}
      setSelectedUserIds={setSelectedUserIds}
      changeUsersStatus={changeUsersStatus}
      fetchUsersTrigger={fetchUsersTrigger}
    />
  );

  const renderUserSchoolsSection = () => (
    <UserSchoolsSection
      selectedUserSchoolsIds={selectedUserSchoolsIds}
      setSelectedUserSchoolsIds={setSelectedUserSchoolsIds}
      changeSelectedMultipleStatus={changeSelectedMultipleStatus}
      fetchUserSchoolsTrigger={fetchUserSchoolsTrigger}
    />
  );

  const renderSchoolsSection = () => (
    <SchoolsSection
      selectedSchoolIds={selectedSchoolIds}
      setSelectedSchoolIds={setSelectedSchoolIds}
      changeSchoolsStatus={changeSchoolsStatus}
      fetchSchoolsTrigger={fetchSchoolsTrigger}
      // changeSelectedMultipleStatus={changeSelectedMultipleStatus}
      // fetchUserSchoolsTrigger={fetchUserSchoolsTrigger}
    />
  );

  const renderErrorLogsSection = () => <ErrorLogsSection />;

  return (
    <div className="container-fluid">
      <div className="body">
        <div className="col-lg col-md-12 p-3">
          <div className="row py-3">
            <div className="col-md-4 text-start">
              <h2 className="page-head">Admin Dashboard</h2>
            </div>
          </div>

          {(isUberAdmin && (
            <div>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={tabsStyle}
              >
                <Tab
                  sx={tabStyle}
                  value={USERS_GLOBAL}
                  label="Manage users globally"
                />
                <Tab
                  sx={tabStyle}
                  value={USERS_SCHOOLS}
                  label="Manage users in schools"
                />
                <Tab sx={tabStyle} value={SCHOOLS} label="Manage schools" />
                <Tab sx={tabStyle} value={ERRORS} label="Error logs" />
              </Tabs>

              <div className="pt-3">
                {selectedTab === USERS_GLOBAL && renderUsersSection()}
                {selectedTab === USERS_SCHOOLS && renderUserSchoolsSection()}
                {selectedTab === SCHOOLS && renderSchoolsSection()}
                {selectedTab === ERRORS && renderErrorLogsSection()}
              </div>
            </div>
          )) || <>{renderUserSchoolsSection()}</>}
        </div>
      </div>
      <ModalComponent
        show={openUserSchoolsStatusPopup}
        onHide={() => {
          setOpenUserSchoolsStatusPopup(false);
          setSelectedUserSchoolsIds([]);
          setSelectedUserSchoolsStatus();
        }}
        title={t("Change user status in school")}
        subTitle={t(
          `Are you sure want to change user status to ${
            selectedUserSchoolsStatus ? "active" : "inactive"
          }?`
        )}
        // size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setOpenUserSchoolsStatusPopup(false);
          setSelectedUserSchoolsIds([]);
          setSelectedUserSchoolsStatus();
        }}
        onConfirmClick={handleConfirmUserSchoolsStatus}
      />
      <ModalComponent
        show={openUsersStatusPopup}
        onHide={() => {
          setOpenUsersStatusPopup(false);
          setSelectedUserSchoolsIds([]);
          setSelectedUserSchoolsStatus();
        }}
        title={t("Change user status")}
        subTitle={t(
          `Are you sure want to change user status to ${
            selectedUsersStatus ? "active" : "inactive"
          }?`
        )}
        // size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setOpenUsersStatusPopup(false);
          setSelectedUserSchoolsIds([]);
          setSelectedUserSchoolsStatus();
        }}
        onConfirmClick={handleConfirmUsersStatus}
      />
      <ModalComponent
        show={openSchoolsStatusPopup}
        onHide={() => {
          setOpenSchoolsStatusPopup(false);
          setSelectedSchoolIds([]);
          setSelectedSchoolsStatus();
        }}
        title={t("Change school status")}
        subTitle={t(
          `Are you sure want to change school status to ${
            selectedSchoolsStatus ? "active" : "inactive"
          }?`
        )}
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setOpenSchoolsStatusPopup(false);
          setSelectedSchoolIds([]);
          setSelectedSchoolsStatus();
          setSwitchState(false);
        }}
        onConfirmClick={handleConfirmSchoolsStatus}
        email={true}
        handleChange={handleChange}
        defaultValue={switchState}
      />
      {showNotification && (
        <ToastComponent
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          title={toastTitle}
          subtitle={toastSubtitle}
          type={toastType}
        />
      )}
    </div>
  );
};

export default Admin;
