import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, SwipeableDrawer } from "@mui/material";
import ApiCalling from "../../network/ApiCalling";
import { imageStudentList } from "../CommonImage";
import Loader from "../../components/common/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Util from "../../Util";
import { useTranslation } from "react-i18next";
import ToastHOC from "../HOC/ToastHOC";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Calander from "../../assets/Icon/calendar.svg";
import Search from "../../assets/Icon/Search.svg";
import Invalid from "../../assets/Icon/invalid.svg";
import clock from "../../assets/Icon/clock.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import emptyCheck from "../../assets/Icon/check-box-empty.svg";
import check from "../../assets/Icon/check-box.svg";
import ExitAlert from "../../components/ExitAlert";

function AddActivityComp(props) {
  const show = props.show;
  const onClose = props.onClose;
  const onSave = props.onSave;
  const history = useHistory();
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [activityById, setActivityId] = useState(null);
  const [isStudentLoading, setIsStudentLoading] = useState(false);
  const datepickerRef = useRef(null);
  const timepickerRef = useRef(null);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStudentsError, setSelectedStudentsError] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [activityCategoryList, setActivityCategoryList] = React.useState([]);
  const [activityCategoryId, setActivityCategoryId] = React.useState(0);
  const [activityCategoryIdError, setActivityCategoryIdError] = useState("");
  const [createdDate, setCreatedDate] = useState(null);
  const [createdDateError, setCreatedDateError] = useState("");
  const [description, setDescription] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [startTime, setStartTime] = useState(null);
  const [startTimeError, setStartTimeError] = React.useState("");
  const [endTime, setEndTime] = React.useState(null);
  const [endTimeError, setEndTimeError] = React.useState("");
  const [stepper1, setStepper1] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const changeClassRoom = (e) => {
    setClassId(e.target.value);
    setIsStateUpdated(true);
  };

  const changeGrade = (e) => {
    setGradeId(e.target.value);
    setIsStateUpdated(true);
  };

  // const searchClicked = () => {
  //   setSearchClick(search);
  // };
  // const EnterHandler = (event) => {
  //   if (event.key === "Enter") {
  //     setSearchClick(search);
  //   }
  // };
  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.id));
    } else {
      setSelectedStudentId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedStudentId.includes(id)) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };
  const changeDate = (date) => {
    setCreatedDate(date);
    setCreatedDateError("");
    setIsStateUpdated(true);
  };

  const handleActivityCategoryChange = (e) => {
    setActivityCategoryId(e.target.value);
    setActivityCategoryIdError("");
    setIsStateUpdated(true);
  };
  const handleStartTimeChanged = (time) => {
    setStartTime(time);
    setStartTimeError("");
    setIsStateUpdated(true);
  };
  const handleEndTimeChanged = (time) => {
    if (time < startTime) {
      setEndTimeError(t("End time cannot be less than start time"));
      setStartTime(time);
    } else {
      setEndTime(time);
      setEndTimeError("");
    }
    setIsStateUpdated(true);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
    setIsStateUpdated(true);
  };

  const reset = () => {
    setStepper1(true);
    setGradeId(0);
    setClassId(0);
    setSearch("");
    setSearchClick("");
    setDescriptionError("");
    setStartTimeError("");
    setEndTimeError("");
    setActivityCategoryIdError("");
    setCreatedDateError("");
    setActivityCategoryId(0);
    setDescription("");
    setStartTime(null);
    setEndTime(null);
    setSelectedStudentsError("");
    setSelectedStudentId([]);
  };

  const handleSave = () => {
    setIsStateUpdated(false);
    setDescriptionError("");
    setStartTimeError("");
    setEndTimeError("");
    setActivityCategoryIdError("");
    setCreatedDateError("");

    let error = false;
    if (createdDate === null) {
      setCreatedDateError(t("Date is required"));
      error = true;
    }
    if (description === "") {
      setDescriptionError(t("Description is required"));
      error = true;
    }
    if (activityCategoryId == 0) {
      setActivityCategoryIdError(t("Category is required"));
      error = true;
    }
    if (startTime === null) {
      setStartTimeError(t("StartTime is required"));
      error = true;
    }

    if (!error) {
      if (activityById !== null) {
        const UpdateFormData = new FormData();
        UpdateFormData.append("id", activityById);
        UpdateFormData.append("activity_category_id", activityCategoryId);
        UpdateFormData.append("description", description);
        UpdateFormData.append("date", createdDate);
        UpdateFormData.append("start_time", startTime);
        if (endTime) {
          UpdateFormData.append("end_time", endTime);
        }
        UpdateFormData.append("student_ids", selectedStudentId);

        ApiCalling.apiCallBodyDataPost(
          `activity/update-activity`,
          UpdateFormData
        )
          .then((res) => {
            // console.log("Activity update::", res.data);

            onSave(t("Activity Edited Successfully"));
            reset();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formdata = new FormData();
        formdata.append("activity_category_id", activityCategoryId);
        formdata.append("description", description);
        formdata.append("date", createdDate);
        formdata.append("start_time", startTime);
        if (endTime) {
          formdata.append("end_time", endTime);
        }
        formdata.append("student_ids", selectedStudentId);
        formdata.append("grad_id", gradeId);
        formdata.append("room_id", classId);

        ApiCalling.apiCallBodyDataPost(`activity/add-activity`, formdata)
          .then((res) => {
            // console.log("Activity Added::", res.data);
            onSave(t("Activity Created Successfully"));
            reset();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const getClassList = async () => {
    // setLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((classroom) => ({
          id: classroom.id,
          name: `${classroom.Grade.title}-${classroom.name}`,
        }));
      setClassList(modifiedClassList);
      // setTimeout(() => {
      //   // setLoading(false);
      // }, 1000);
    });
  };

  const CustomInput = ({ value, onClick, icon }) => (
    <div className=" d-flex" onClick={onClick}>
      <input className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  );

  const getGradeList = async () => {
    // setLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
      // setTimeout(() => {
      //   // setLoading(false);
      // }, 1000);
    });
  };
  const activityCategory = () => {
    setLoading(true);
    ApiCalling.apiCallParamsGet("activity-category/list").then((res) => {
      setLoading(false);
      setActivityCategoryList(res.data);
    });
  };
  const getStudentList = () => {
    setIsStudentLoading(true);
    let data = {
      page_size: 1000,
      page_number: 1,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      active: "true",
      ascdesc: "asc",
      order_by: "",
    };
    ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
      if (res.data.data) {
        setStepper1(true);
        setStudentList(res.data.data);
        setFilteredStudentList(res.data.data);
        setIsStudentLoading(false);
      }
    });
  };

  const actById = () => {
    // setLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/getById/${activityById}`)
      .then((res) => {
        setLoading(false);
        setDescription(res.data.description);
        if (res.data.ActivityStudents && res.data.ActivityStudents.length > 0) {
          let stu = [];
          res.data.ActivityStudents.map((val) => {
            if (val.Student) stu.push(val.Student?.id);
          });
          setSelectedStudentId(stu);
        }
        setActivityCategoryId(res.data.activity_category_id);
        setCreatedDate(
          res.data.created_on ? new Date(res.data.created_on) : null
        );
        setStartTime(
          Util.isValidData(res.data.start_time) ? res.data.start_time : null
        );
        setEndTime(res.data.end_time);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setStepper1(true);
    getClassList();
    getGradeList();
    activityCategory();
  }, []);

  useEffect(() => {
    if (activityById === null) {
      reset();
      setActivityId(null);
      setCreatedDate(new Date());
    } else {
      actById();
    }
  }, [activityById]);

  useEffect(() => {
    getStudentList();
  }, [classId, gradeId, searchClick]);

  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);
  useEffect(() => {
    setActivityId(props.activityById);
  }, [props]);
  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            setActivityId(null);
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {activityById !== null
                  ? t("Edit Activity")
                  : t("Create New Activity")}
              </div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    setActivityId(null);
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div
                  className="d-flex justify-content-between align-items-center mb-1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <div>
                    {stepper1 ? t("Select Students") : t("Activity Details")}
                  </div>
                  <div>{stepper1 ? 1 : 2}/2</div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className="active-stepper"></div>
                  <div
                    className={stepper1 ? "inactive-stepper" : "active-stepper"}
                  ></div>
                </div>
                {stepper1 ? (
                  <>
                    <div className="input-group mt-3">
                      <input
                        className="form-control search"
                        placeholder="Search students"
                        type="text"
                        value={search}
                        // onChange={(e) => {
                        //   setSearch(e.target.value);
                        // }}
                        // onKeyPress={(e) => EnterHandler(e)}
                        onChange={(e) => {
                          // setSearch_Staff(e.target.value);
                          setSearch(e.target.value);
                          const searchValue = e.target.value;
                          if (searchValue.trim() === "") {
                            setFilteredStudentList(studentList);
                          } else {
                            const filteredStudent = studentList.filter((s) =>
                              s.first_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            );
                            setFilteredStudentList(filteredStudent);
                          }
                        }}
                      />
                      <div className="input-group-prepend">
                        <div
                          className="search-icon"
                          style={{ paddingRight: "13px" }}
                          // onClick={searchClicked}
                        >
                          <img src={Search} className="svg-icon" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-6 mt-3">
                      <select
                        className="select-dropdown form-select custom-input"
                        value={gradeId}
                        onChange={changeGrade}
                      >
                        <option value={0}>{t("Select grade")}</option>
                        {gradeList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6 mt-3">
                      <select
                        className="select-dropdown form-select custom-input"
                        value={classId}
                        onChange={changeClassRoom}
                      >
                        <option value={0}>{t("Select room")}</option>
                        {classList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {!isStudentLoading ? (
                      <>
                        <div className="table-container mt-3">
                          <div className="table-wrapper">
                            <table className="table">
                              <thead>
                                <tr className="text-nowrap">
                                  <td
                                    scope="col"
                                    className="check-col"
                                    style={{ paddingRight: "0px", width: 0 }}
                                  >
                                    <label className="fancy-checkbox element-left">
                                      <input
                                        type="checkbox"
                                        name="allselect"
                                        onChange={handleAllSelectedStudent}
                                        checked={
                                          selectedStudentId.length > 0 &&
                                          selectedStudentId.length ===
                                            filteredStudentList.length
                                        }
                                      />
                                      <span tabIndex={0}></span>
                                    </label>
                                  </td>
                                  <th scope="col">
                                    {selectedStudentId.length > 0
                                      ? selectedStudentId.length +
                                        " " +
                                        t("Student Selected")
                                      : t("Student")}
                                  </th>
                                </tr>
                              </thead>
                              {filteredStudentList.length > 0 ? (
                                <tbody>
                                  {filteredStudentList
                                    .sort((a, b) =>
                                      selectedStudentId.includes(a.id)
                                        ? -1
                                        : selectedStudentId.includes(b.id)
                                        ? 1
                                        : 0
                                    )
                                    .map((item) => {
                                      return (
                                        <tr
                                          key={item.id}
                                          style={{
                                            backgroundColor: `${
                                              selectedStudentId.includes(
                                                item.id
                                              )
                                                ? "rgba(100, 24, 195, 0.04)"
                                                : ""
                                            }`,
                                          }}
                                          onClick={() =>
                                            handleRowClick(item.id)
                                          }
                                        >
                                          <td
                                            style={{
                                              cursor: "pointer",
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <img
                                              src={
                                                selectedStudentId.includes(
                                                  item.id
                                                )
                                                  ? check
                                                  : emptyCheck
                                              }
                                            />
                                            {/* <label className="fancy-checkbox element-left">
                                          <input
                                            type="checkbox"
                                            name={item.first_name}
                                            onChange={StudentChecked}
                                            value={item.id}
                                            checked={selectedStudentId.includes(
                                              item.id
                                            )}
                                          />
                                          <span></span>
                                        </label> */}
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              {item.photo ? (
                                                <img
                                                  className="rounded-circle "
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                  src={
                                                    imageStudentList +
                                                    item.photo
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                <Avatar
                                                  aria-label="recipe"
                                                  className="avatar-font"
                                                >
                                                  {item.first_name.toUpperCase()[0] +
                                                    item.last_name.toUpperCase()[0]}
                                                </Avatar>
                                              )}
                                              <a
                                                className="ml-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  history.push({
                                                    pathname: "/profileTabs",
                                                    studentId: item.id,
                                                  });
                                                }}
                                              >
                                                {item.first_name}{" "}
                                                {item.last_name}
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              ) : (
                                <tfoot>
                                  <tr>
                                    <td colSpan={2}>
                                      {t("Student Not Found")}
                                    </td>
                                  </tr>
                                </tfoot>
                              )}
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </>
                ) : (
                  <>
                    <div className="col-6 text-start mt-3">
                      <span className="basic-text required">
                        {t("Category")}
                      </span>
                      <select
                        name="feeTypeId"
                        id="feeTypeId"
                        className={`select-dropdown form-select custom-input ${
                          activityCategoryIdError.length > 0
                            ? "input-error"
                            : ""
                        }`}
                        value={activityCategoryId}
                        onChange={handleActivityCategoryChange}
                      >
                        <option value={0} disabled>
                          {t("Select category")}
                        </option>
                        {activityCategoryList
                          ?.filter(
                            (item) =>
                              item.reference !== "CHECK_IN" &&
                              item.reference !== "CHECK_OUT"
                          )
                          .map((item) => {
                            return (
                              <option key={item.key} value={item.id}>
                                {item?.title}
                              </option>
                            );
                          })}
                      </select>
                      {activityCategoryIdError.length > 0 && (
                        <div className="invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {activityCategoryIdError}
                        </div>
                      )}
                    </div>
                    <div className="col-6 text-start mt-3">
                      <span className="basic-text required">{t("Date")}</span>
                      <div className="input-filed ">
                        <DatePicker
                          placeholderText="Select date"
                          selected={createdDate}
                          onChange={changeDate}
                          dateFormat={school.date_format}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className={`datePicker ${
                            createdDateError.length > 0
                              ? "input-error"
                              : "datePicker"
                          }`}
                          popperPlacement="bottom"
                          customInput={<CustomInput icon={Calander} />}
                        />

                        {createdDateError.length > 0 && (
                          <div className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {createdDateError}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-6 text-start mt-2">
                      <span className="basic-text required">
                        {t("Start Time")}
                      </span>
                      <div className="input-filed ">
                        <div className="time-picker">
                          <DatePicker
                            selected={
                              Util.isValidData(startTime)
                                ? new Date(startTime)
                                : null
                            }
                            onChange={handleStartTimeChanged}
                            dateFormat={school.time_format}
                            popperPlacement="bottom"
                            className={`form-control ${
                              startTimeError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            customInput={<CustomInput icon={clock} />}
                            placeholderText={`${t("Select time")}*`}
                          />
                        </div>
                        {startTimeError.length > 0 && (
                          <div className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {startTimeError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-start mt-2">
                      <span
                        className="basic-text"
                        style={{ fontWeight: "400" }}
                      >
                        {t("End Time")} ({t("Optional")})
                      </span>
                      <div className="input-filed ">
                        <div className="time-picker" tabIndex="{0}">
                          <DatePicker
                            selected={
                              Util.isValidData(endTime)
                                ? new Date(endTime)
                                : null
                            }
                            onChange={handleEndTimeChanged}
                            dateFormat={school.time_format}
                            popperPlacement="bottom"
                            className={`form-control ${
                              endTimeError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            customInput={<CustomInput icon={clock} />}
                            timeCaption="Time"
                            placeholderText={`${t("Select time")}`}
                          />
                        </div>
                        {endTimeError.length > 0 && (
                          <div className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {endTimeError}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="input-filed text-start mx-1">
                      <span className="basic-text px-0 required">
                        {t("Description")}
                      </span>
                      <textarea
                        className={`text-area ${
                          descriptionError.length > 0
                            ? "input-error"
                            : " text-area auto-height"
                        }`}
                        placeholder="Write..."
                        rows="4"
                        cols={4}
                        value={description}
                        onChange={handleDescriptionChange}
                      ></textarea>
                      {descriptionError.length > 0 && (
                        <div className="invalid-error px-0">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {descriptionError}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              {stepper1 ? (
                <button
                  className="secondary-md-btn btn ml-2"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  {t("Cancel")}
                </button>
              ) : (
                <button
                  className="secondary-md-btn btn mr-2"
                  onClick={() => {
                    setStepper1(true);
                  }}
                >
                  {t("Previous")}
                </button>
              )}

              {stepper1 ? (
                <>
                  <button
                    style={{ float: "right" }}
                    className="primary-btn btn"
                    onClick={() => {
                      if (selectedStudentId.length > 0) {
                        setStepper1(false);
                      } else {
                        setSelectedStudentsError(t("Please Select Student!"));
                      }
                    }}
                  >
                    {t("Continue")}
                  </button>
                  {selectedStudentsError.length > 0 && (
                    <span
                      className="text-danger"
                      style={{ float: "right", margin: "12px" }}
                    >
                      {selectedStudentsError}
                    </span>
                  )}
                </>
              ) : (
                <button
                  style={{ float: "right" }}
                  className="primary-xl-btn btn"
                  onClick={handleSave}
                >
                  {t("Submit Activity")}
                </button>
              )}
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}
export default ToastHOC(AddActivityComp);
