import React, { useEffect, useState } from "react";
import ApiCalling from "../../network/ApiCalling";
import CloseIcon from "@mui/icons-material/Close";
import { parentIdentity, userIdentity } from "../CommonImage";
import Util from "../../Util";
import ToastHOC from "../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Edit from "../../assets/Icon/Edit-blue.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function WorkInfo(props) {
  const user = Util.getUser();
  const { t } = useTranslation();
  const history = useHistory();
  const userDetails = props.userDetails;
  const { setIsToast, setIsSubToast } = props;
  // const [userDetails,setUserDetails]=useState(props.userDetails);

  const [showWorkAcc, setShowWorkAcc] = useState(true);
  const [showAddressAcc, setShowAddressAcc] = useState(false);
  const [showFinanceAcc, setShowFinanceAcc] = useState(false);

  const [workDetailEdit, setWorkDetailEdit] = useState(false);
  const [workAddressEdit, setWorkAddressEdit] = useState(false);
  const [financialDetailEdit, setFinancialDetailEdit] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [workPhone, setWorkPhone] = useState(null);
  const [workAlternatePhone, setWorkAlternatePhone] = useState(null);

  const [workAddress1, setWorkAddress1] = useState("");
  const [workAddress2, setWorkAddress2] = useState("");
  const [workCityId, setWorkCityId] = useState(0);
  const [workZipCode, setWorkZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [workCountryId, setWorkCountryId] = useState(0);
  const [workStateId, setWorkStateId] = useState(0);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [employer, setEmployer] = useState("");
  const [familyIncome, setFamilyIncome] = useState("");

  const [workCountry, setWorkCountry] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [workState, setWorkState] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [city, setCity] = useState([]);

  useEffect(() => {
    if (userDetails) {
      setCompanyName(
        Util.isValidData(userDetails.work_company)
          ? userDetails.work_company
          : ""
      );
      setWorkPhone(
        Util.isValidData(userDetails.work_phone) ? userDetails.work_phone : ""
      );
      setWorkAlternatePhone(
        Util.isValidData(userDetails.work_phone_alternate)
          ? userDetails.work_phone_alternate
          : ""
      );
      setWorkAddress1(
        Util.isValidData(userDetails.work_address1)
          ? userDetails.work_address1
          : ""
      );
      setWorkAddress2(
        Util.isValidData(userDetails.work_address2)
          ? userDetails.work_address2
          : ""
      );
      setWorkCityId(
        Util.isValidData(userDetails.work_city_id)
          ? userDetails.work_city_id
          : 0
      );
      setWorkZipCode(
        Util.isValidData(userDetails.work_zip) ? userDetails.work_zip : ""
      );
      setWorkCountryId(
        Util.isValidData(userDetails.work_country_id)
          ? userDetails.work_country_id
          : 0
      );
      setWorkStateId(
        Util.isValidData(userDetails.work_state_id)
          ? userDetails.work_state_id
          : 0
      );
      setSelectedCountry(
        Util.isValidData(userDetails.Work_Country)
          ? userDetails.Work_Country
          : ""
      );
      setSelectedState(
        Util.isValidData(userDetails.Work_State) ? userDetails.Work_State : ""
      );
      setSelectedCity(
        Util.isValidData(userDetails.Work_City) ? userDetails.Work_City : ""
      );
      setEmployer(
        Util.isValidData(userDetails.employer) ? userDetails.employer : ""
      );
      setFamilyIncome(
        Util.isValidData(userDetails.income_family)
          ? userDetails.income_family
          : ""
      );
    }
  }, [userDetails]);

  const handleWorkDetailsSave = (e) => {
    e.preventDefault();
    const data = {
      user_id: user.id,
      ...(companyName !== "" && { work_company: companyName }),
      ...(workPhone !== null && { work_phone: workPhone }),
      ...(workAlternatePhone !== null && {
        work_phone_alternate: workAlternatePhone,
      }),
    };

    ApiCalling.apiCallBodyDataPost("user/edit-parent-workdata", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(t("User Updated Successfully"));
          setIsSubToast(t("You have successfully change Work Details"));

          setWorkDetailEdit(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleWorkAddressDetailsSave = (e) => {
    e.preventDefault();
    let error = false;
    setPinCodeError("");
    if (workZipCode && Util.Zip_regex.test(workZipCode) === false) {
      setPinCodeError(
        t("Please enter valid zip-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      const data = {
        user_id: user.id,
        ...(workAddress1 !== "" && { work_address1: workAddress1 }),
        ...(workAddress2 !== "" && { work_address2: workAddress2 }),
        ...(workCountryId !== 0 && { work_country_id: workCountryId }),
        ...(workStateId !== 0 && { work_state_id: workStateId }),
        ...(workCityId !== 0 && { work_city_id: workCityId }),
        ...(workZipCode !== "" && { work_zip: workZipCode }),
      };

      ApiCalling.apiCallBodyDataPost("user/edit-parent-workdata", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsToast(t("User Updated Successfully"));
            setIsSubToast(
              t("You have successfully change work Address Details")
            );

            setWorkAddressEdit(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleFinanceDetailsSave = (e) => {
    e.preventDefault();
    const data = {
      user_id: user.id,
      ...(familyIncome !== "" && { income_family: familyIncome }),
      ...(employer !== "" && { employer: employer }),
    };

    ApiCalling.apiCallBodyDataPost("user/edit-parent-workdata", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(t("User Updated Successfully"));
          setIsSubToast(t("You have successfully change Finance Details"));

          setFinancialDetailEdit(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const WorkCountryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setWorkCountry(res.data);
    });
  };
  const workStateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${workCountryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setWorkState(res.data);
      }
    );
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${workStateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    if (workStateId) {
      cityList();
    }
  }, [workStateId]);
  useEffect(() => {
    WorkCountryList();
  }, []);
  useEffect(() => {
    if (workCountryId) {
      workStateList();
    }
  }, [workCountryId]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);

  return (
    <div>
      <div className="container-80">
        <div className="school-details my-4">
          <div className="p-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                  {t("Work Details")}
                </div>

                {!workDetailEdit && (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#6418C3",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setWorkDetailEdit(!workDetailEdit);
                        setShowWorkAcc(true);
                      }}
                      className="link-button"
                    >
                      <img src={Edit} alt="" className="edit-svg " />
                      {t("Edit")}
                    </span>
                    <div className="vertical-bar"></div>
                    <div
                      className="accoridan-btn"
                      onClick={() => setShowWorkAcc(!showWorkAcc)}
                    >
                      <i
                        className={
                          showWorkAcc ? "fa fa-angle-up" : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                )}
              </div>
              {showWorkAcc && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Company Name")}</span>
                      {!workDetailEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(companyName) ? companyName : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className={`form-control custom-input`}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">
                        {t("work Phone Number")}
                      </span>
                      {!workDetailEdit ? (
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#07112b",
                          }}
                        >
                          {Util.isValidData(workPhone) ? workPhone : "-"}
                        </div>
                      ) : (
                        <>
                          <div
                            className={`form-control custom-input`}
                            tabIndex={0}
                          >
                            <PhoneInput
                              tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={workPhone}
                              onChange={(value) => {
                                setWorkPhone(value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">
                        {t("Work Alternate Mobile Number")}
                      </span>
                      {!workDetailEdit ? (
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#07112b",
                          }}
                        >
                          {Util.isValidData(workAlternatePhone)
                            ? workAlternatePhone
                            : "-"}
                        </div>
                      ) : (
                        <>
                          <div
                            className={`form-control custom-input`}
                            tabIndex={0}
                          >
                            <PhoneInput
                              tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={workAlternatePhone}
                              onChange={(value) => {
                                setWorkAlternatePhone(value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {workDetailEdit && (
                    <div className="text-end mt-2">
                      <button
                        className="btn secondary-lg-btn rounded-btn  mr-2  m-0 px-2"
                        onClick={() => {
                          // resetPersonalDetails();
                          setShowWorkAcc(false);
                          setWorkDetailEdit(false);
                        }}
                      >
                        {t("Cancel")}
                      </button>

                      <button
                        className="primary-lg-btn btn rounded-btn"
                        onClick={handleWorkDetailsSave}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                  {t("Work Address Details")}
                </div>

                {!workAddressEdit && (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#6418C3",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setWorkAddressEdit(true);
                        setShowAddressAcc(true);
                        setShowWorkAcc(false);
                        setShowFinanceAcc(false);
                      }}
                      className="link-button"
                    >
                      <img src={Edit} alt="" className="edit-svg " />
                      {t("Edit")}
                    </span>
                    <div className="vertical-bar"></div>
                    <div
                      className="accoridan-btn"
                      onClick={() => setShowAddressAcc(!showAddressAcc)}
                    >
                      <i
                        className={
                          showAddressAcc ? "fa fa-angle-up" : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                )}
              </div>
              {showAddressAcc && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Address 1")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(workAddress1) ? workAddress1 : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className="form-control custom-input"
                            value={workAddress1}
                            onChange={(e) => {
                              setWorkAddress1(e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text">{t("Address 2")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(workAddress2) ? workAddress2 : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className="form-control custom-input"
                            value={workAddress2}
                            onChange={(e) => {
                              setWorkAddress2(e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text">{t("Country")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(selectedCountry)
                            ? selectedCountry
                            : "-"}
                        </div>
                      ) : (
                        <>
                          <select
                            name=""
                            id=""
                            className="select-dropdown  form-select custom-input"
                            value={workCountryId}
                            onChange={(e) => {
                              setWorkCountryId(e.target.value);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Country")}
                            </option>
                            {workCountry?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("State")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(selectedState)
                            ? selectedState
                            : "-"}
                        </div>
                      ) : (
                        <>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={workStateId}
                            onChange={(e) => {
                              setWorkStateId(e.target.value);
                            }}
                            disabled={!workCountryId}
                          >
                            <option value={0}>
                              {isCountryLoading ? "Loading..." : "Select State"}
                            </option>
                            {workState?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("City")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(selectedCity) ? selectedCity : "-"}
                        </div>
                      ) : (
                        <>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={workCityId}
                            onChange={(e) => {
                              setWorkCityId(e.target.value);
                            }}
                            disabled={!workStateId}
                          >
                            <option disabled value={0}>
                              {isCountryLoading ? "Loading..." : "Select City"}
                            </option>
                            {city?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text">{t("ZIP Code")}</span>
                      {!workAddressEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(workZipCode) ? workZipCode : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className={`form-control custom-input ${
                              pinCodeError.length > 0 ? "input-error" : ""
                            }`}
                            value={workZipCode}
                            onChange={(e) => {
                              setWorkZipCode(e.target.value);
                              setPinCodeError("");
                            }}
                          />
                          {pinCodeError.length > 0 && (
                            <span className="text-danger">
                              <i
                                className="fa fa-exclamation-circle mr-2"
                                aria-hidden="true"
                              ></i>
                              {pinCodeError}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {workAddressEdit ? (
                    <div className="text-end mt-2">
                      <button
                        className="btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                        onClick={() => {
                          setPinCodeError("");
                          setShowAddressAcc(false);
                          setWorkAddressEdit(false);
                        }}
                      >
                        {t("Cancel")}
                      </button>

                      <button
                        className=" btn primary-lg-btn rounded-btn"
                        onClick={handleWorkAddressDetailsSave}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                  {t("Financial Details")}
                </div>

                {!financialDetailEdit && (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#6418C3",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFinancialDetailEdit(!financialDetailEdit);
                        setShowFinanceAcc(true);
                        setShowAddressAcc(false);
                        setShowWorkAcc(false);
                      }}
                      className="link-button"
                    >
                      <img src={Edit} alt="" className="edit-svg " />
                      {t("Edit")}
                    </span>
                    <div className="vertical-bar"></div>
                    <div
                      className="accoridan-btn"
                      onClick={() => setShowFinanceAcc(!showFinanceAcc)}
                    >
                      <i
                        className={
                          showFinanceAcc ? "fa fa-angle-up" : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                )}
              </div>
              {showFinanceAcc && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Employer")}</span>
                      {!financialDetailEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(employer) ? employer : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className={`form-control custom-input`}
                            value={employer}
                            onChange={(e) => {
                              setEmployer(e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Family Income")}</span>
                      {!financialDetailEdit ? (
                        <div className="large-bold-text">
                          {Util.isValidData(familyIncome) ? familyIncome : "-"}
                        </div>
                      ) : (
                        <>
                          <input
                            className={`form-control custom-input`}
                            value={familyIncome}
                            onChange={(e) => {
                              setFamilyIncome(e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  {financialDetailEdit && (
                    <div className="text-end mt-2">
                      <button
                        className="btn secondary-lg-btn rounded-btn  mr-2  m-0 px-2"
                        onClick={() => {
                          setShowFinanceAcc(false);
                          setFinancialDetailEdit(false);
                        }}
                      >
                        {t("Cancel")}
                      </button>

                      <button
                        className="primary-lg-btn btn rounded-btn"
                        onClick={handleFinanceDetailsSave}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(WorkInfo);
