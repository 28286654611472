import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/common/Loader";
import Logo from "../../components/common/Logo";
import { useHistory } from "react-router-dom";
import Util from "../../Util";
import ApiCalling from "../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import ToastHOC from "../HOC/ToastHOC";
import Invalid from "../../assets/Icon/invalid.svg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const VerifyEmail = (props) => {
  const { setIsToast } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [codeVerify, setCodeVerify] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [getUserDetail, setUserDetail] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const resendCode = async () => {
    ApiCalling.apiCallParamsGet(
      "user/generate-verification-code/" + getUserDetail.email
    )
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const VerifyCode = async () => {
    setIsLoading(true);

    if (!executeRecaptcha) {
      setIsLoading(false);
      console.log("Execute recaptcha not yet available");
      setCodeErr(t("Recaptcha not yet available. Please try again."));
      return;
    }

    try {
     const data ={
        email: getUserDetail?.email,
        verificationCode: codeVerify
     }
     ApiCalling.apiCallBodyDataPost("user/verifyCode", data)
     .then((res) => {
       setIsLoading(false);
       if (res.data.status === true) {
        setIsToast(t(res.data.message));
        setTimeout(function () {
            history.push({
              pathname: "/",
            });
          }, 2000);
       }
    })
    } catch (error) {
      setIsLoading(false);
      setCodeErr(t("An error occurred. Please try again."));
      console.log("Error", error);
    }
  };

  const formHandler = (e) => {
    e.preventDefault();
    if (codeVerify === "") {
      setCodeErr(t("Code is required"));
    } else {
      VerifyCode();
    }
  };

  const changeCode = (e) => {
    e.preventDefault();
    setCodeVerify(e.target.value);
    setCodeErr("");
  };

  useEffect(() => {
    const getUserData = Util.getRegisteredUser();
    console.log(getUserData)
    if (getUserData) {
      setUserDetail(getUserData);
    }
  }, []);

  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="auth-box auth-wrapper">
              <Logo />
              <div className="card">
                <div className="header">
                  <h1 className="text-center">
                    {t("Verify your ")}
                  </h1>
                </div>
                <div className="body">
                  <form>
                    <div className="row ">
                      <div className="col">
                        <div className="input-filed">
                          <p>
                            {t("Code sent to")} {getUserDetail?.email}
                          </p>
                          <input
                            value={codeVerify}
                            onChange={changeCode}
                            className={`form-control custom-input ${
                              codeErr.length > 0 && "input-error"
                            }`}
                            placeholder={t("Verification code")}
                          />

                          {codeErr.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {codeErr}
                            </span>
                          )}
                          <span
                            className="float-right"
                            style={{ cursor: "pointer" }}
                          >
                            <a onClick={resendCode}>{t("Resend Code")} ?</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button
                          className="btn custom-primary btn-lg btn-block get-started-btn"
                          type="submit"
                          onClick={formHandler}
                        >
                          {t("Confirm")}
                        </button>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="bottom col-lg-10 offset-3">
                        <span>
                          {t("Already have an account?")}
                          <a className={"space-before-5"} href="login">
                            {t("Login")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(VerifyEmail);
