import React from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../components/common/Logo";

class Page404 extends React.Component {
  render() {
    return (
      <div className="theme-cyan">
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <Logo />
                </div>
                <div className="card">
                  <div className="header">
                    <h3>
                      <span className="clearfix title">
                        <span className="number left mr-1">404</span>
                        <span className="text">
                          Oops! <br />
                          Page Not Found
                        </span>
                      </span>
                    </h3>
                  </div>
                  <div className="body">
                    <p>
                      The page you were looking for could not be found, please{" "}
                      <a>contact us</a> to report this issue.
                    </p>
                    <div className="margin-top-30">
                      <a
                        className="btn btn-default"
                        onClick={() => {
                          this.props.history.push("/");
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>&nbsp;
                        <span>Go Back</span>
                      </a>
                      &nbsp;
                      <a className="btn custom-primary" href="/">
                        <i className="fa fa-home"></i>&nbsp;<span>Home</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Page404.propTypes = {};

const mapStateToProps = ({ loginReducer }) => ({});

export default connect(mapStateToProps, {})(Page404);
