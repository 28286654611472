import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import navigationReducer from "./navigationReducer";
import analyticalReducer from "./analyticalReducer";
import demographicReducer from "./demographicReducer";
import ioTReducer from "./ioTReducer";
import mailInboxReducer from "./mailInboxReducer";
import SelectedStudentReducer from "./SelectedStudentReducer";
import SwitchSchoolReducer from "./SwitchSchoolReducer";
import notifyUserUpdateReducer from "./notifyUserUpdateReducer";
import SwitchTabReducer from "./SwitchTabReducer";
import ToggleReducer from "./ToggleReducer";
import messagingReducer from "./messagingReducer";
import ImpersonateReducer from "./ImpersonateReducer";

export default combineReducers({
  loginReducer,
  navigationReducer: navigationReducer,
  analyticalReducer: analyticalReducer,
  demographicReducer: demographicReducer,
  ioTReducer: ioTReducer,
  mailInboxReducer: mailInboxReducer,
  SelectedStudentReducer: SelectedStudentReducer,
  SwitchSchoolReducer: SwitchSchoolReducer,
  notifyUserUpdateReducer: notifyUserUpdateReducer,
  SwitchTabReducer: SwitchTabReducer,
  ToggleReducer: ToggleReducer,
  messagingReducer,
  impersonateUser: ImpersonateReducer,
});
