import React, { useEffect, useState } from "react";
import Logo from "../../components/common/Logo";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";

export default function SelectRole(props) {
  const history = useHistory();
  const email = props.location.email;
  const firstName = props.location.firstName;
  const lastName = props.location.lastName;

  const { t } = useTranslation();

  const [roleId, setRoleId] = useState(0);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [rollIdError, setRollIdError] = useState("");
  const [termsError, setTermsError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;
    setRollIdError("");
    setTermsError("");
    if (roleId == 0) {
      setRollIdError(t("Please select 'who you are'"));
      error = true;
    }
    if (!isTermsChecked) {
      setTermsError(t("Please accept terms of service"));
      error = true;
    }
    if (!error) {
      const data = {
        email: email,
        password: "",
        firstName: firstName,
        lastName: lastName,
        parent_staff: parseInt(roleId),
      };

      ApiCalling.apiCallBodyDataPost("user/add-user-google", data)
        .then((res) => {
          if (res.data.status === true) {
            Util.saveUser(res.data.user);
            if (res.data.user.user_Schools.length > 0) {
              if (res.data.user.user_Schools[0].RoleId > 0) {
                Util.saveSelectedSchool(res.data.user.user_Schools[0].school);
                Util.saveParentMode(false);
                history.push("/Dashboard");
              } else {
                Util.saveParentMode(false);
                history.push("/waitingSchool");
              }
            } else {
              if (roleId == 1) {
                Util.saveParentMode(false);
                history.push("/JoinSchool");
              } else {
                Util.saveParentMode(true);
                history.push("/ParentsDashboard");
              }
            }
          }
        })
        .catch((err) => {
          console.log("Error");
        });
    }
  };
  useEffect(() => {
    if (!email) {
      history.push("/registration");
    }
  }, []);
  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          <div className="auth-box">
            <Logo />
            <div className="card">
              <div className="header">
                <h1 className="text-center">
                  {t(`Welcome ${firstName}, want to signup as`)}
                </h1>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col">
                    <select
                      className="form-select select-dropdown"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setRoleId(e.target.value);
                        setRollIdError("");
                      }}
                    >
                      <option selected disabled>
                        {t("Who you are")}
                      </option>
                      <option value={1}>{t("Staff / Teacher")}</option>
                      <option value={2}>{t("Parent")}</option>
                    </select>
                    <span className={"cl-input-error"}>{rollIdError}</span>
                  </div>
                  <div className="form-group clearfix mt-4">
                    <label className="fancy-checkbox element-left">
                      <input
                        type="checkbox"
                        value={isTermsChecked}
                        onChange={(input) => {
                          let { value, checked } = input.target;
                          setIsTermsChecked(checked);
                          setTermsError("");
                        }}
                      />
                      <span tabIndex={0}>
                        {t(
                          "by creating an account, I agree to the Terms of service"
                        )}
                      </span>
                    </label>
                    <div className={"cl-input-error"}>{termsError}</div>
                  </div>
                  <div className="col">
                    <button
                      onClick={handleSubmit}
                      className="btn custom-primary btn-lg btn-block get-started-btn"
                    >
                      {t("Submit")}
                    </button>
                  </div>

                  <div className="row mt-4">
                    <div className="col">
                      <Link to="registration">
                        <i className="fa-solid fa-chevron-left"></i> {t("Back")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
