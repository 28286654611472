import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/assets/scss/main.scss";
import "./assets/assets/scss/color_skins.scss";
import "../node_modules/font-awesome/scss/font-awesome.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import i18n from "./i18nextConf";
import { I18nextProvider } from "react-i18next";
import ErrorBoundary from "./ErrorBoundry";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const store = createStore(reducers, applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById("root"));
// <GoogleOAuthProvider clientId="88435559780-oe0lruvcknqf467s43stb03f4i63fqve.apps.googleusercontent.com">

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="23820145239-6fqa9ht4kfvu9iub3a5n61k7bl3hnue5.apps.googleusercontent.com">
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback="...">
            <I18nextProvider i18n={i18n}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </I18nextProvider>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
