import React, { useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../assets/Icon/Search.svg";
import { Avatar } from "@mui/material";
import ApiCalling from "../../network/ApiCalling";
import { imageMyProfile } from "../CommonImage";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import { SeparateDot } from "../../components/common/separateDot";
import student from "../../assets/Icon/user.svg";
import emptyCheck from "../../assets/Icon/check-box-empty.svg";
import check from "../../assets/Icon/check-box.svg";
import ExitAlert from "../../components/ExitAlert";
import Close from "../../assets/Icon/close.png";
import Loader from "../../components/common/Loader";

export default function NewGroup({
  show,
  onClose,
  onGroupClick,
  onSave,
  onCreateGroup,
  data,
}) {
  // console.log("DATA",data);
  const { t } = useTranslation();
  const user = Util.getUser();
  const getLanguage = Util.getSelectedLanguage();
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [staffSearchClick, setStaffSearchClick] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [isStaffLoading, setIsStaffLoading] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [stepper1, setStepper1] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStudentsError, setSelectedStudentsError] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [isStudentLoading, setIsStudentLoading] = useState(false);
  const [selectedStaffError, setSelectedStaffError] = useState("");

  const changeClassRoom = (e) => {
    setClassId(e.target.value);
    setIsStateUpdated(true);
  };

  const changeGrade = (e) => {
    setGradeId(e.target.value);
    setIsStateUpdated(true);
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
    setGroupNameError("");
  };

  const reset = () => {
    setStepper1(0);
    setSelectedStaffId([]);
    setGroupName("");
    setGroupNameError("");
    setSelectedStaffId([]);
    setSelectedStudentId([]);
    setSelectedStaffError("");
    setSelectedStudentsError("");
    setSearchClick("");
    setStaffSearchClick("");
    setGroupId(0);
  };

  const handleSaveGroup = () => {
    let error = false;
    setGroupNameError("");
    setSelectedStudentsError("");
    setSelectedStaffError("");
    if (groupName == "") {
      setGroupNameError(t("Name is required"));
      error = true;
    }
    if (selectedStaffId.length <= 0) {
      setSelectedStaffError("Please Select Staff");
      error = true;
    }
    console.log(selectedStaffId, selectedStaffError);
    if (!error) {
      console.log(selectedStudentId, selectedStaffId, groupName);
      const selectedStaffIds = selectedStaffId;
      const selectedParentIds = selectedStudentId;
      if(show.isEdit){
        const data = {
          name: groupName,
          user_ids: [...selectedParentIds, ...selectedStaffIds].join(","),
          id: groupId
        };

        ApiCalling.apiCallBodyDataPost("group/update", data)
        .then((res) => {
          if (res.data.status == true) {
            onGroupClick(res.data.group);
            onSave(res.data.message);
            onClose();
            reset();
          }
          reset();
        })
        .catch((error) => {
          console.log("error", error);
        });
      }else{
        const data = {
          name: groupName,
          user_ids: [...selectedParentIds, ...selectedStaffIds].join(","),
        };

        ApiCalling.apiCallBodyDataPost("group/add-chatroom", data)
        .then((res) => {
          if (res.data.status == true) {
            onGroupClick(res.data.group);
            onSave(res.data.message);
            onClose();
            reset();
          }
          reset();
        })
        .catch((error) => {
          console.log("error", error);
        });
      }
    }
  };

  useEffect(() => {
    if (show.show && show.isEdit) {
      setGroupName(data?.name);
      setGroupId(data?.id);
      const groupPeople = data.GroupPeople;
      if (groupPeople.length) {
        groupPeople.map((ele) => {
          if (ele.User.is_staff && ele.User.is_parent) {
            setSelectedStudentId((prevSelected) => [...prevSelected, ele.User.id])
          }else{
            setSelectedStaffId((prevSelected) => [...prevSelected, ele.User.id])
          }
        });
      }
    }
  }, [show]);

  const getClassList = async () => {
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((classroom) => ({
          id: classroom.id,
          name: `${classroom.Grade.title}-${classroom.name}`,
        }));
      setClassList(modifiedClassList);
    });
  };

  const getGradeList = async () => {
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
    });
  };

  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.User.id));
    } else {
      setSelectedStudentId([]);
    }
    setIsStateUpdated(true);
  };

  const handleAllSelectedStaff = (e) => {
    if (e.target.checked) {
      setSelectedStaffId(staffList.map((item) => item.id));
    } else {
      setSelectedStaffId([]);
    }
    // setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedStudentId.includes(id)) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const handleStaffRowClick = (id) => {
    if (!selectedStaffId.includes(id)) {
      setSelectedStaffId([...selectedStaffId, id]);
    } else {
      const data = selectedStaffId.filter((s) => s !== id);
      setSelectedStaffId(data);
    }
    setSelectedStaffError("");
    setIsStateUpdated(true);
  };

  const getStudentList = () => {
    setIsStudentLoading(true);
    let data = {
      page_size: 1000,
      page_number: 1,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      active: "true",
      ascdesc: "asc",
      order_by: "",
    };
    ApiCalling.apiCallBodyDataPost("parent/list", data).then((res) => {
      if (res.data.data) {
        setStudentList(res.data.data);
        setFilteredStudentList(res.data.data);
        setIsStudentLoading(false);
      }
    });
  };

  const staffUser = async () => {
    const data = {
      page_size: 1000,
      page_number: 1,
      role_id: 0,
      str_search: staffSearchClick,
      order_by: "",
      ascdesc: "asc",
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("user/list", data)
      .then((res) => {
        setStaffList(res.data.data);
        setFilteredStaffList(res.data.data);
        setIsStaffLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClassList();
    getGradeList();
    getStudentList();
    staffUser();
    console.log(filteredStudentList, selectedStudentId)
  }, []);

  useEffect(() => {
    getStudentList();
  }, [classId, gradeId, searchClick]);

  useEffect(() => {
    staffUser();
  }, [staffSearchClick]);

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show.show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {show.isEdit ? t("Edit Group") : t("Create New Group")}
              </div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div
                  className="d-flex justify-content-between align-items-center mb-1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <div>
                    {stepper1 === 0
                      ? t("Group Details")
                      : stepper1 === 1
                      ? t("Select Students")
                      : "Select Staff"}
                  </div>
                  <div>{stepper1 === 0 ? 1 : stepper1 == 1 ? 2 : 3}/3</div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className="active-stepper"></div>
                  <div
                    className={
                      stepper1 == 1 || stepper1 == 2
                        ? "active-stepper"
                        : "inactive-stepper"
                    }
                  ></div>
                  <div
                    className={
                      stepper1 == 2 ? "active-stepper" : "inactive-stepper"
                    }
                  ></div>
                </div>
                <>
                  {stepper1 === 0 ? (
                    <>
                      <div className="text-start mt-3">
                        <span className="">{t("Name of the group")}?</span>
                        <input
                          className="form-control"
                          placeholder="Group name"
                          type="text"
                          value={groupName}
                          onChange={handleGroupName}
                        />
                      </div>
                    </>
                  ) : stepper1 === 1 ? (
                    <>
                      <div className="input-group mt-3">
                        <input
                          className="form-control search"
                          placeholder="Search parents"
                          type="text"
                          value={search}
                          onChange={(e) => {
                            // setSearch_Staff(e.target.value);
                            setSearch(e.target.value);
                            const searchValue = e.target.value;
                            if (searchValue.trim() === "") {
                              setFilteredStudentList(studentList);
                            } else {
                              const filteredStudent = studentList.filter(
                                (s) =>
                                  s.User.firstName
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                  s.User.lastName
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                              );
                              setFilteredStudentList(filteredStudent);
                            }
                          }}
                        />
                        <div className="input-group-prepend">
                          <div
                            className="search-icon"
                            style={{ paddingRight: "13px" }}
                          >
                            <img src={Search} className="svg-icon" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="col-6 mt-3">
                        <select
                          className="select-dropdown form-select custom-input"
                          value={gradeId}
                          onChange={changeGrade}
                        >
                          <option value={0}>{t("Select grade")}</option>
                          {gradeList?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 mt-3">
                        <select
                          className="select-dropdown form-select custom-input"
                          value={classId}
                          onChange={changeClassRoom}
                        >
                          <option value={0}>{t("Select room")}</option>
                          {classList?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {!isStudentLoading ? (
                        <>
                          <div className="table-container mt-3">
                            <div className="table-wrapper">
                              <table className="table">
                                <thead>
                                  <tr className="text-nowrap">
                                    <td
                                      scope="col"
                                      className="check-col"
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <label className="fancy-checkbox element-left">
                                        <input
                                          type="checkbox"
                                          name="allselect"
                                          onChange={handleAllSelectedStudent}
                                          checked={
                                            selectedStudentId.length > 0 &&
                                            selectedStudentId.length ===
                                              filteredStudentList.length
                                          }
                                        />
                                        <span tabIndex={0}></span>
                                      </label>
                                    </td>
                                    <th scope="col">
                                      {selectedStudentId.length > 0
                                        ? selectedStudentId.length +
                                          " " +
                                          t("Parent Selected")
                                        : t("Parent")}
                                    </th>
                                  </tr>
                                </thead>
                                {filteredStudentList.length > 0 ? (
                                  <tbody>
                                    {filteredStudentList
                                      .sort((a, b) =>
                                        selectedStudentId.includes(a.id)
                                          ? -1
                                          : selectedStudentId.includes(b.id)
                                          ? 1
                                          : 0
                                      )
                                      .map((item) => {
                                        return (
                                          <tr
                                            key={item.id}
                                            style={{
                                              backgroundColor: `${
                                                selectedStudentId.includes(
                                                  item.User.id
                                                )
                                                  ? "rgba(100, 24, 195, 0.04)"
                                                  : ""
                                              }`,
                                            }}
                                            onClick={() =>
                                              handleRowClick(item.User.id)
                                            }
                                          >
                                            <td
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "0px",
                                                width: 0,
                                              }}
                                            >
                                              <img
                                                src={
                                                  selectedStudentId.includes(
                                                    item.User.id
                                                  )
                                                    ? check
                                                    : emptyCheck
                                                }
                                              />
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                {item.photo ? (
                                                  <img
                                                    className="rounded-circle "
                                                    style={{
                                                      width: "40px",
                                                      height: "40px",
                                                    }}
                                                    src={
                                                      imageMyProfile +
                                                      item.User.photo
                                                    }
                                                    alt=""
                                                  />
                                                ) : (
                                                  <Avatar
                                                    aria-label="recipe"
                                                    className="avatar-font"
                                                  >
                                                    {item.User.firstName.toUpperCase()[0] +
                                                      item.User.lastName.toUpperCase()[0]}
                                                  </Avatar>
                                                )}
                                                <a
                                                  className="ml-2"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  {item?.User?.firstName}{" "}
                                                  {item?.User?.lastName}
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                ) : (
                                  <tfoot>
                                    <tr>
                                      <td colSpan={2}>
                                        {t("Student Not Found")}
                                      </td>
                                    </tr>
                                  </tfoot>
                                )}
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </>
                  ) : (
                    <>
                      <div className="input-group mt-3">
                        <input
                          className="form-control search"
                          placeholder="Search staff"
                          type="text"
                          value={staffSearchClick}
                          onChange={(e) => {
                            setStaffSearchClick(e.target.value);
                          }}
                        />
                        <div className="input-group-prepend">
                          <div
                            className="search-icon"
                            style={{ paddingRight: "13px" }}
                          >
                            <img src={Search} className="svg-icon" alt="" />
                          </div>
                        </div>
                      </div>
                      {!isStaffLoading ? (
                        <>
                          <div className="table-container mt-3">
                            <div className="table-wrapper">
                              <table className="table">
                                <thead>
                                  <tr className="text-nowrap">
                                    <td
                                      scope="col"
                                      className="check-col"
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <label className="fancy-checkbox element-left">
                                        <input
                                          type="checkbox"
                                          name="allselectStaff"
                                          onChange={handleAllSelectedStaff}
                                          checked={
                                            selectedStaffId.length > 0 &&
                                            selectedStaffId.length ===
                                              filteredStaffList.length
                                          }
                                        />
                                        <span tabIndex={0}></span>
                                      </label>
                                    </td>
                                    <th scope="col">
                                      {selectedStaffId.length > 0
                                        ? selectedStaffId.length +
                                          " " +
                                          t("Staff Selected")
                                        : t("Staff")}
                                    </th>
                                  </tr>
                                </thead>
                                {filteredStaffList.length > 0 ? (
                                  <tbody>
                                    {filteredStaffList
                                      .sort((a, b) =>
                                        selectedStaffId.includes(a.id)
                                          ? -1
                                          : selectedStaffId.includes(b.id)
                                          ? 1
                                          : 0
                                      )
                                      .map((item) => {
                                        return (
                                          <tr
                                            key={item.id}
                                            style={{
                                              backgroundColor: `${
                                                selectedStaffId.includes(
                                                  item.id
                                                )
                                                  ? "rgba(100, 24, 195, 0.04)"
                                                  : ""
                                              }`,
                                            }}
                                            onClick={() =>
                                              handleStaffRowClick(item.id)
                                            }
                                          >
                                            <td
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "0px",
                                                width: 0,
                                              }}
                                            >
                                              <img
                                                src={
                                                  selectedStaffId.includes(
                                                    item.id
                                                  )
                                                    ? check
                                                    : emptyCheck
                                                }
                                              />
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                {item.photo ? (
                                                  <img
                                                    className="rounded-circle "
                                                    style={{
                                                      width: "40px",
                                                      height: "40px",
                                                    }}
                                                    src={
                                                      imageMyProfile +
                                                      item.photo
                                                    }
                                                    alt=""
                                                  />
                                                ) : (
                                                  <Avatar
                                                    aria-label="recipe"
                                                    className="avatar-font"
                                                  >
                                                    {item.firstName.toUpperCase()[0] +
                                                      item.lastName.toUpperCase()[0]}
                                                  </Avatar>
                                                )}
                                                <a
                                                  className="ml-2"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  {item.firstName}{" "}
                                                  {item.lastName}
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                ) : (
                                  <tfoot>
                                    <tr>
                                      <td colSpan={2}>
                                        {t("Staff Not Found")}
                                      </td>
                                    </tr>
                                  </tfoot>
                                )}
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </>
                  )}
                </>
              </div>
            </div>

            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {stepper1 == 0 ? (
                  <button
                    className="secondary-md-btn btn ml-2"
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    {t("Cancel")}
                  </button>
                ) : (
                  <button
                    className="secondary-md-btn btn mr-2"
                    onClick={() => {
                      setStepper1((prv) => prv - 1);
                      setGroupNameError("");
                    }}
                  >
                    {t("Previous")}
                  </button>
                )}
                {stepper1 == 0 || stepper1 == 1 ? (
                  <div className="d-flex align-item center justify-content-end gap-2">
                    {groupName.length <= 0 && stepper1 === 0 && (
                      <span className="text-danger mt-2">{groupNameError}</span>
                    )}
                    {selectedStudentsError.length > 0 && stepper1 === 1 && (
                      <span className="text-danger mt-2">
                        {selectedStudentsError}
                      </span>
                    )}
                    <button
                      className="primary-lg-btn btn"
                      onClick={() => {
                        if (groupName.length <= 0 && stepper1 == 0) {
                          setGroupNameError("Please Enter Group Name");
                        } else if (stepper1 == 0) {
                          setStepper1((prv) => prv + 1);
                        }
                        if (selectedStudentId.length <= 0 && stepper1 == 1) {
                          setSelectedStudentsError(t("Please Select Student!"));
                        } else if (stepper1 == 1) {
                          setStepper1((prv) => prv + 1);
                        }
                      }}
                    >
                      {t("Continue")}
                    </button>
                  </div>
                ) : (
                  <>
                    {stepper1 === 2 && (
                      <div className="d-flex justify-content-end align-items center gap-2">
                        {selectedStaffError.length > 0 && stepper1 === 2 && (
                          <span className="text-danger mt-2">
                            {selectedStaffError}
                          </span>
                        )}
                        <button
                          className={`${
                            Util.hasPermission("GROUP_ADD")
                              ? "primary-sm-btn btn mr-1"
                              : "no-permission"
                          }`}
                          style={{ float: "right" }}
                          onClick={handleSaveGroup}
                        >
                          {t("Save Group")}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
