import React from "react";

import logo from "../../assets/Icon/classadiaLogo.svg";

function Logo({ wrapperClass, width = "100%" }) {
  return (
    <div className={wrapperClass ? wrapperClass : "navbar-brand"}>
      <img
        src={logo}
        alt="Classadia"
        className="img-responsive"
        style={{ width: width }}
      />
      {/* <span className={wrapperClass ? "classadia-logo" : ""}>Classadia</span> */}
    </div>
  );
}

export default Logo;
