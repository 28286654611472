import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../network/ApiCalling";
import Loader from "../../components/common/Loader";
import ErrorLogsTable from "./ErrorLogsTable";

const ErrorLogsSection = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorLogs, setErrorLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [expandedErrorLogIds, setExpandedErrorLogIds] = useState([]);

  const handleExpandAllErrorLogs = (e) => {
    if (!expandedErrorLogIds.length) {
      setExpandedErrorLogIds(errorLogs.map((item) => item.id));
    } else {
      setExpandedErrorLogIds([]);
    }
  };

  const expandErrorLog = (id) => {
    if (!expandedErrorLogIds.includes(id)) {
      setExpandedErrorLogIds([...expandedErrorLogIds, id]);
    } else {
      const data = expandedErrorLogIds.filter((s) => s !== id);
      setExpandedErrorLogIds(data);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  useEffect(() => {
    console.log("getErrorLogs useEffect");
    let mounted = true;

    const getErrorLogs = () => {
      const data = {
        page_size: recordPerPage,
        page_number: currentPage,
      };
      setIsLoading(true);
      ApiCalling.apiCallBodyDataPost("error-logs/list", data)
        .then((res) => {
          if (mounted) {
            setErrorLogs(res.data?.data);
            setTotalPages(res.data?.totalPages);
            setTotalRecords(res.data?.total);
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getErrorLogs();

    return () => {
      mounted = false;
    };
  }, [currentPage, recordPerPage]);

  return (
    <div className="table-div">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              <ErrorLogsTable
                errorLogs={errorLogs}
                expandedErrorLogIds={expandedErrorLogIds}
                handleExpandAllErrorLogs={handleExpandAllErrorLogs}
                expandErrorLog={expandErrorLog}
              />
            </div>
            {totalPages > 0 && (
              <div
                className="row m-0 table-footer  border-top"
                style={{ height: "72px" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <div>{t("Students Per Page")}</div>
                    <select
                      style={{ width: "72px", cursor: "pointer" }}
                      className="select-dropdown custom-input"
                      value={recordPerPage}
                      onChange={(e) => {
                        setRecordPerPage(e.target.value);
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <div>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <div
                        className={`link-button ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePreviousClick();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <KeyboardArrowLeftIcon />
                        <span className="basic-bold-text">{t("Previous")}</span>
                      </div>
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, page) => {
                          setCurrentPage(page);
                        }}
                        size="small"
                        hideNextButton
                        hidePrevButton
                      />
                      <div
                        className={`link-button ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNextClick();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="basic-bold-text">{t("Next")}</span>
                        <KeyboardArrowRightIcon />
                      </div>
                    </Stack>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorLogsSection;
