import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/common/Table";
import TagButton from "../Dashbord/TagButton";
import { greenTagStyle, orangeTagStyle } from "../Dashbord/TagButtonStyle";

const UserSchoolsTable = ({
  userSchools,
  selectedUserSchoolsIds,
  handleAllSelectedUserSchools,
  userSchoolsChecked,
  setSortBy,
  setSortOrder,
}) => {
  const { t } = useTranslation();

  const data = [
    ...userSchools?.map((userSchool) => {
      return {
        checked: selectedUserSchoolsIds.includes(userSchool.id),
        userEmail: userSchool?.user?.email,
        name: `${userSchool?.user?.firstName} ${userSchool?.user?.lastName}`,
        school: userSchool?.school?.name,
        role: userSchool?.role?.role,
        status: userSchool?.active ? "Active" : "Inactive",
      };
    }),
  ];

  const columns = [
    {
      name: "checked",
      content: (
        <label className="fancy-checkbox element-left">
          <input
            type="checkbox"
            name="allselect"
            onChange={handleAllSelectedUserSchools}
            checked={
              userSchools.length > 0 &&
              userSchools.length === selectedUserSchoolsIds.length
            }
          />
          <span></span>
        </label>
      ),
    },
    {
      name: "userEmail",
      content: <div className="m-l-5">{t("User Email")}</div>,
      sortable: true,
      sortBy: "user.email",
    },
    {
      name: "name",
      content: <>{t("Name")}</>,
      sortable: true,
      sortBy: "user.firstName",
    },
    {
      name: "school",
      content: <>{t("School")}</>,
      sortable: true,
      sortBy: "school.name",
    },
    {
      name: "role",
      content: <>{t("Role")}</>,
      sortable: true,
      sortBy: "role.role",
    },
    {
      name: "status",
      content: <>{t("Status")}</>,
      sortable: true,
      sortBy: "uss.active",
    },
  ];

  const customRenderers = [
    ...userSchools.map((userSchool) => {
      return {
        checked: (data) => {
          return (
            <label className="fancy-checkbox element-left">
              <input
                type="checkbox"
                name={userSchool.id}
                onChange={userSchoolsChecked}
                value={userSchool.id}
                checked={selectedUserSchoolsIds.includes(userSchool.id)}
              />
              <span></span>
            </label>
          );
        },
        status: (data) => (
          <TagButton
            size={"100px"}
            title={data}
            buttonStyle={userSchool.active ? greenTagStyle : orangeTagStyle}
            icon="fa fa-circle"
            iconSize="6px"
          />
        ),
      };
    }),
  ];

  const rowStyle = (row) => {
    if (row.checked) {
      return { backgroundColor: "rgba(100, 24, 195, 0.04)" };
    }
  };

  return (
    <Table
      data={data}
      columns={columns}
      customRenderers={customRenderers}
      rowStyle={rowStyle}
      setSortBy={setSortBy}
      setSortOrder={setSortOrder}
    />
  );
};

export default UserSchoolsTable;
