import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "../../components/PageHeader";
import Util from "../../Util";
import { Dropdown, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { imageSwitchSchool } from "../../screens/CommonImage";
import ApiCalling from "../../network/ApiCalling";
import ToastHOC from "../../screens/HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import Loader from "../common/Loader";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function SwitchSchool(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const user = Util.getUser();
  const [schools, setSchools] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setSchools(user.user_Schools.filter((userSchool) => userSchool?.SchoolId));
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`school/${selectedSchoolId}`)
      .then((res) => {
        if (res.data.success == true) {
          setIsLoading(false);
          setIsToast(t("School Deleted Successfully!"));
          setShowDeletePopup(false);
          ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
            .then((res) => {
              setSchools(
                res.data.user_Schools.filter(
                  (userSchool) => userSchool?.SchoolId
                )
              );
              Util.saveUser(res.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const switchSchool = async () => {
    setShowPopup(false);
    const user = await Util.getUser();
    await Util.removeUser();
    await Util.saveUser(user);
    await Util.saveParentMode(false);
    await Util.saveSelectedSchool(selectedSchool);
    history.push("/Dashboard");
  };

  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="body card">
            <div className="col-lg col-md-12 p-4">
              <div className="d-wrapper">
                {schools.length === 0 ? (
                  <div className="row m-4">
                    <div className="col-lg-4 offset-lg-4">
                      <p className="text-center text-muted">
                        {t("No School found")} :-(
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row m-4">
                      <div className="col-lg-6 offset-lg-3">
                        {schools.map((school, index) => {
                          return (
                            <div key={index}>
                              {school.school.id !==
                              Util.getSelectedSchool().id ? (
                                <div
                                  className="row p-3 m-2"
                                  style={{ backgroundColor: "#FAFAFA" }}
                                  key={school.school.id}
                                >
                                  <div
                                    className="col-lg-3"
                                    onClick={() => {
                                      setShowPopup(true);
                                      setSelectedSchool(school.school);
                                    }}
                                  >
                                    {school.school.logo ? (
                                      <img
                                        className="rounded-circle user-photo"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={
                                          imageSwitchSchool + school.school.logo
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="col-lg-7"
                                    onClick={() => {
                                      setShowPopup(true);
                                      setSelectedSchool(school.school);
                                    }}
                                  >
                                    <h4>{school.school.name}</h4>
                                    <div>
                                      {t("Admin")}:{" "}
                                      {school.school.contact_email}
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <a
                                      className={`${
                                        Util.hasPermission("SCHOOL_DELETE")
                                          ? "btn btn-light text-danger"
                                          : "no-permission"
                                      }`}
                                      onClick={() => {
                                        setShowDeletePopup(true);
                                        setSelectedSchoolId(school.school.id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        animation={true}
        centered={true}
        dialogClassName="modal-view"
        show={showPopup}
      >
        <Modal.Body>
          {!isLoading ? (
            <>
              {t("Are you sure to Logout from this School")}?
              <br /> {t("You will be redirected to the ")}{" "}
              <b>{selectedSchool?.name}</b> {t("after logout from this school")}
              .
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn custom-primary" onClick={switchSchool}>
            {t("Yes")}
          </button>
          <button
            className="btn btn-light"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            {t("No")}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        animation={true}
        centered={true}
        dialogClassName="modal-view"
        show={showDeletePopup}
      >
        <Modal.Body>
          {t("Are you sure to")}{" "}
          <b className="text-danger">{t("Delete this School")}</b>?<br />
          <br />
          {t(
            "Once deleted all School related detail, will also be deleted like Students, Rooms, etc..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleDelete}>
            {t("Delete")}
          </button>
          <button
            className="btn btn-light"
            onClick={() => {
              setShowDeletePopup(false);
            }}
          >
            {t("Cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ToastHOC(SwitchSchool);
