import React from "react";
import "../Dashbord/DashboardCustom.css";
import Close from "../../assets/Icon/close-white.png";
import Util from "../../Util";

const NotificationAlert = ({
  title,
  subTitle,
  icon,
  onClose,
  type = "success",
}) => {
  const isLanguage = Util.getSelectedLanguage();

  return (
    <div className="row w-100">
      <div className="">
        <div
          className="alert alert-warning alert-dismissible fade show border-0"
          role="alert"
          style={{
            background: "#07112B",
            borderRadius: "12px",
            // height: "72px",
            color: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            paddingInlineStart: "20px",
            // width: "375px ",
            position: "fixed",
            zIndex: 1,
            top: 80,
            right: 10,
            // right: isLanguage !== "ar" && 10,
            // left: isLanguage == "ar" && 10,
          }}
        >
          <div
            style={{
              borderRadius: 100,
              width: "40px",
              height: "40px",
              backgroundColor: "rgba(21, 168, 75, 0.2)",
              position: "relative",
              marginRight: "5px",
            }}
          >
            <div
              className="icon"
              style={{
                borderRadius: 100,
                width: "30px",
                height: "30px",
                backgroundColor: type === "success" ? "#15A84B" : "#ED3131",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFFFFF",
                marginRight: "10px",
                position: "absolute",
                top: 5,
                left: 5,
              }}
            >
              <i className={icon} color="#ffffff"></i>
            </div>
          </div>

          <div className="textWrap text-align-left">
            <h6 className="m-0 p-0 head-toast">{title}</h6>
            <p className="small-text m-0 p-0">{subTitle}</p>
          </div>

          <div
            style={{ position: "absolute", right: 15, top: 20 }}
            onClick={onClose}
          >
            <img src={Close} alt="" style={{ cursor: "pointer" }} />
          </div>
          {/* <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i className="fa fa-times"></i>
      </button> */}
        </div>
      </div>
    </div>
  );
};

export default NotificationAlert;
