import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { InvoiceStatus } from "../../../Enums";
import { invoiceDownload } from "../../CommonImage";
import InvoiceView from "../../ParentsDashboard/Billing/InvoiceView";
import ToastHOC from "../../HOC/ToastHOC";
import GeneratePayment from "./AddPayment";
import Loader from "../../../components/common/Loader";


function AllInvoices(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const { setIsToast } = props;
  const updateData = props?.updateData;
  const studentId = props?.student?.id;
  const student = props?.student;
  const message = props?.message;
  const [isLoading, setIsLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const DownloadInvoice = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/download-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          window.open(invoiceDownload + res.data.invoice, "_blank");
        } else {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sendReminder = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/send-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAllInvoices = () => {
    setIsLoading(true);
    const data = {
      student_id: studentId,
      page: currentPage,
      size: recordPerPage,
    };
    ApiCalling.apiCallBodyDataPost("billing-invoice/student", data)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setAllInvoices(res.data.response);
          setTotalPages(res.data.total_pages);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Util.isValidData(studentId)) {
      getAllInvoices();
    } else {
      history.push("/billing-plan-list");
    }
  }, [currentPage, recordPerPage, studentId, updateData]);

  
  return (
    <div className="container-fluid mt-4">
      <InvoiceView
        show={isVisibleInvoice}
        onClose={() => {
          setIsVisibleInvoice(false);
        }}
        invoiceId={invoiceId}
      />
      <GeneratePayment
        show={isVisiblePayment}
        onClose={(msg) => {
          if (msg) {
            setIsToast(msg);
          }
          setIsVisiblePayment(false);
        }}
        onSave={(msg) => {
          if (msg) {
            setIsToast(msg);
          }
          setIsVisiblePayment(false);
          getAllInvoices();
        }}
        studentIds={studentId}
        studentName={student?.first_name + " " + student?.last_name}
        invoiceDetails={invoiceData}
      />
      
      {!isLoading ? (
        <table
          className="table"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr>
              <th>{t("Invoice")}</th>
              <th>{t("Fee Type")}</th>
              <th>{t("Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th></th>
            </tr>
          </thead>
          {allInvoices.length > 0 ? (
            <tbody>
              {allInvoices.map((a, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{Util.invoiceIdFormat(a.id)}</td>
                      <td>
                        {a.types.length > 0
                          ? a.types.map((f) => f.Fee_Type).join(", ")
                          : "-"}
                      </td>
                      <td>
                        {moment(a.date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getInvoiceStatus(a.status)}
                          buttonStyle={
                            a.status == 0
                              ? redTagStyle
                              : a.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.amountFormat(a.total)}</td>
                      <td>{Util.invoiceFormat(a.due)}</td>

                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              {(InvoiceStatus.UNPAID == a.status ||
                                InvoiceStatus.PARTIAL_PAID == a.status) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsVisiblePayment(true);
                                      setInvoiceData(a);
                                    }}
                                  >
                                    {t("Log a Payment ")}
                                  </a>

                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      sendReminder(a.id);
                                    }}
                                  >
                                    {t("Send reminder")}
                                  </a>
                                </>
                              )}

                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setIsVisibleInvoice(true);
                                  setInvoiceId(a.id);
                                }}
                              >
                                {t("View")}
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  DownloadInvoice(a.id);
                                }}
                              >
                                {t("Download")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={8}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
      
      <div className="d-flex justify-content-end mt-2">
        <Stack spacing={2} direction="row" alignItems="center">
          <div
            className={`link-button ${currentPage == 1 ? "disabled" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              handlePreviousClick();
            }}
            style={{ cursor: "pointer" }}
          >
            <KeyboardArrowLeftIcon />
            <span className="basic-bold-text" >
              {t("Previous")}
            </span>
          </div>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
            size="small"
            hideNextButton
            hidePrevButton
          />
          <div
            className={`link-button ${
              currentPage == totalPages ? "disabled" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleNextClick();
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="basic-bold-text" >
              {t("Next")}
            </span>
            <KeyboardArrowRightIcon />
          </div>
        </Stack>
      </div>
      
    </div>
  );
}
export default ToastHOC(AllInvoices);
