import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Util from "../../Util";
import "../Dashbord/DashboardCustom.css";
import ApiCalling from "../../network/ApiCalling";
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import ToastHOC from "../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import TagButton from "./TagButton";
import Post from "./Post/Post";
import ExitAlert from "../../components/ExitAlert";
import moment from "moment";
import Calender from "../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "../../assets/Icon/add post.svg";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import CardAnnouncement from "../../components/ActivityCard/Card-Announcement";
import CardEvent from "../../components/ActivityCard/CardEvent";

function Dashboard(props) {
  // console.log("SwitchSchool", SwitchSchool.isSwitch);
  const { t } = useTranslation();
  const history = useHistory();
  const pendingToast = props.history.location.pendingToast;
  const { setIsToast } = props;

  const deleteActivityId = props.location.deleteActivityId;
  const [activityList, setActivityList] = useState([]);
  const [staff, setStaff] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page_number, setPage_number] = useState(0);
  const [loading, setLoading] = useState(false);
  const [endOfList, setEndOfList] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);

  const school = Util.getSelectedSchool();
  const [studentList, setStudentList] = useState([]);
  const [studentId, setStudentId] = useState(0);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [activityId, setActivityId] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);

  const [date, setDate] = useState(null);
  const [activityCategoryId, setActivityCategoryId] = useState(0);
  const [isNoData, setIsNoData] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const user = Util.getUser();
  const recordPerPage = 20;

  const reset = () => {
    setActivityList([]);
    setCurrentPage(1);
    setEndOfList(false);
  };

  const activityCategoryList = [
    { value: "EVENT", title: "Event" },
    { value: "POST", title: "Post" },
    { value: "ANNOUNCEMENT", title: "Announcement" },
    { value: "ACTIVITY", title: "Activity" },
  ];
  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className="custom-input__field"
        value={value}
        readOnly
        placeholder="Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calendar" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  useEffect(() => {
    let isStudentMounted = true;
    const getStudent = async () => {
      let data = {
        page_size: 1000,
        page_number: 1,
        classroom_id: classId,
        grade_id: gradeId,
        str_search: "",
        active: "true",
      };
      ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
        if (isStudentMounted) {
          const modifiedClassList = res.data.data
            .filter((s) => s.classroom_id == classId)
            .map((s) => ({
              id: s.id,
              first_name: `${s.first_name} ${s.last_name}`,
            }));
          setStudentList(modifiedClassList);
        }
      });
    };
    getStudent();
    return () => {
      isStudentMounted = false;
    };
  }, [classId, gradeId]);
  // const changeGrade = (data) => {
  //   setCurrentPage(1);
  //   setActivityList([]);
  //   setActivityList([]);
  //   setGradeId(data[0].id);
  // };
  // const changeStudent = (data) => {
  //   setCurrentPage(1);
  //   setActivityList([]);
  //   setStudentId(data[0].id);
  // };
  // const changeClassRoom = (data) => {
  //   setCurrentPage(1);
  //   setActivityList([]);
  //   setClassId(data[0].id);
  // };

  // const changeCheck = (data) => {
  //   setCurrentPage(1);
  //   setActivityList([]);
  //   setActivityCategoryId(data[0].id);
  // };

  const changeDate = (dt) => {
    reset();
    setDate(dt);
  };

  useEffect(() => {
    let isGradeListMounted = true;
    const getGradeList = async () => {
      ApiCalling.apiCallParamsGet("grade/list").then((res) => {
        if (isGradeListMounted) {
          setGradeList(res.data);
        }
      });
    };
    getGradeList();
    return () => {
      isGradeListMounted = false;
    };
  }, []);
  useEffect(() => {
    let isClassListMounted = true;
    const getClassList = async () => {
      ApiCalling.apiCallParamsGet("classroom/list").then((res) => {
        if (isClassListMounted) {
          const modifiedClassList = res.data
            .filter((g) => g.Grade.id == gradeId)
            .map((grade) => ({
              id: grade.id,
              name: `${grade.Grade.title}-${grade.name}`,
            }));
          setClassList(modifiedClassList);
        }
      });
    };
    getClassList();
    return () => {
      isClassListMounted = false;
    };
  }, [gradeId]);

  const groupActivitiesByDate = (activityList) => {
    const groupedActivities = {};
    activityList.sort((a, b) => {
      const momentA = moment(a.created_on, "DD/MM/YYYY");
      const momentB = moment(b.created_on, "DD/MM/YYYY");

      return momentB - momentA;
    });

    activityList.forEach((activity) => {
      // const createdDate = new Date(activity.created_on).toLocaleDateString();
      const createdDate = moment(activity.created_on).format("MM/DD/YYYY");

      if (!groupedActivities[createdDate]) {
        groupedActivities[createdDate] = [];
      }

      groupedActivities[createdDate].push(activity);
    });

    return groupedActivities;
  };

  const getActivityList = () => {
    const formattedDate = Util.getFormattedDate(date);
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
      room_id: classId,
      grade_id: gradeId,
      student_id: studentId,
      room_id: classId,
      grade_id: gradeId,
      student_id: studentId,
      startDate: date !== null ? formattedDate : null,
    };
    setLoading(true);
    ApiCalling.apiCallBodyDataPost("activity/getActivities", data)
      .then((res) => {
        if (res.data.data.length > 0) {
          setLoading(false);
          // setActivityList((prevList) => [...prevList, ...res.data.data]);

          setActivityList((prevActivityList) => ({
            ...prevActivityList,
            ...groupActivitiesByDate(res.data.data),
          }));
          setIsNoData(false);
          setStaff(true);
          setTotalPages(res.data.totalPages);
          setPage_number(res.data.page_number);
          if (res.data.totalPages === res.data.page_number) {
            setEndOfList(true);
            setIsNoData(false);
          }
        } else {
          setIsNoData(true);
          setEndOfList(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getActivityList();
  }, [currentPage, classId, gradeId, studentId, date]);

  const handleLike = (id) => {
    if (isClickedOnce) {
      return;
    }
    setIsClickedOnce(true);
    ApiCalling.apiCallBodyDataPost(`activity/like/${id}`)
      .then((res) => {
        setIsClickedOnce(false);
        let f = [];
        for (const date in activityList) {
          const updatedActivities = activityList[date].map((data) => {
            if (data.id === id) {
              data.ActivityLike.push({ created_by: user.id, CreatedBy: user });
            }
            return data;
          });

          f[date] = updatedActivities;
        }
        setActivityList(f);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDisLike = (id) => {
    ApiCalling.apiCallDelete(`activity/Unlike/${id}`)
      .then((res) => {
        const updatedActivityList = { ...activityList };

        for (const date in updatedActivityList) {
          updatedActivityList[date] = updatedActivityList[date].map((data) => {
            if (data.id === id) {
              data.ActivityLike = data.ActivityLike.filter(
                (like) => like.created_by !== user.id
              );
            }
            return data;
          });
        }

        setActivityList(updatedActivityList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteActivity = (id) => {
    ApiCalling.apiCallDelete(`activity/${id}`)
      .then((res) => {
        if (res.data.status === true) {
          setIsToast("Post Deleted Successfully");
        }

        reset();
        getActivityList();
      })
      .catch((error) => [console.log(error)]);
  };

  useEffect(() => {
    // setIsDataUpdated(false);
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    if (totalPages == currentPage) {
      setEndOfList(true);
      setIsNoData(false);
    }
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;

      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const windowBottom = windowHeight + window.pageYOffset;

      if (windowBottom >= docHeight - 100 && !loading && !endOfList) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, endOfList]);

  return (
    <>
      <div className="container-fluid">
        <div className="container d-wrapper">
          <div className="activity-container">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex gap-2 pt-3 pl-0">
                <div className="">
                  <DatePicker
                    placeholderText="Select Date"
                    selected={date}
                    onChange={changeDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperPlacement="bottom"
                    customInput={<CustomInput icon={Calender} />}
                    maxDate={new Date()}
                  />
                </div>
                {/* <div>
                  <Select
                    className="select-custom "
                    options={[
                      { value: 0, title: "Category" },
                      ...activityCategoryList,
                    ]}
                    onChange={(values) => changeCheck(values)}
                    labelField="title"
                    values={[{ value: 0, title: "Category" }]}
                    valueField="value"
                  />
                </div> */}
                {/* <span className="ml-2">{t("Activity Type")}: </span>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, name: "All" }, ...classList]}
                    onChange={(values) => changeCheck(values)}
                    labelField="name"
                    values={[{ id: 0, name: "All" }]}
                    valueField="id"
                  />
                  <span className="ml-2">{t("Grade")}: </span>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, title: "All" }, ...gradeList]}
                    onChange={(values) => changeGrade(values)}
                    labelField="title"
                    values={[{ id: 0, title: "All" }]}
                    valueField="id"
                  />

                  <span className="ml-2">{t("Rooms")}: </span>
                  <Select
                    className="select-custom"
                    options={[
                      {
                        id: gradeId == 0 ? 0 : 0,
                        name: gradeId == 0 ? "Select grade" : "All",
                      },
                      ...classList,
                    ]}
                    onChange={(values) => changeClassRoom(values)}
                    labelField="name"
                    values={[{ id: 0, name: "All" }]}
                    valueField="id"
                  />
                  <span className="ml-2">{t("Rooms")}: </span>
                  <Select
                    className="select-custom"
                    options={[
                      {
                        id: gradeId == 0 ? 0 : 0,
                        name: gradeId == 0 ? "Select grade" : "All",
                      },
                      ...classList,
                    ]}
                    onChange={(values) => changeClassRoom(values)}
                    labelField="name"
                    values={[{ id: 0, name: "All" }]}
                    valueField="id"
                  />

                  <span className="ml-2">{t("Students")}: </span>
                  <Select
                    className="select-custom"
                    options={[
                      {
                        id: classId == 0 ? 0 : 0,
                        first_name: classId == 0 ? "Select Room" : "All",
                      },
                      ...studentList,
                    ]}
                    onChange={(values) => changeStudent(values)}
                    labelField="first_name"
                    values={[{ id: 0, first_name: "All" }]}
                    valueField="id"
                  /> */}
              </div>
              <div className="pt-4 pl-0">
                <button
                  className="btn primary-sm-btn"
                  type="button"
                  onClick={() => {
                    setIsOpenPost(true);
                    setActivityId(0);
                    setCurrentPage(1);
                  }}
                >
                  <img src={AddIcon} className="mr-1" height={20} width={20} />
                  {t("Add New Post")}
                </button>
              </div>
            </div>

            {isNoData ? (
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{ paddingTop: "20%", paddingBottom: "20%" }}
                >
                  <p className="text-center text-secondary">
                    {t("No NewsFeed found")} :-(
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {Object.keys(activityList).map((date, index) => (
                  <div key={index}>
                    <div className="d-flex gap-2 mt-4 mb-3">
                      <img
                        src={Calender}
                        alt=""
                        style={{ cursor: "pointer" }}
                        width={20}
                        height={20}
                      />
                      <div className="large-bold-text">
                        {moment(date, "MM/DD/YYYY").format("MMMM DD, YYYY")}
                      </div>
                    </div>
                    {activityList[date].map((activity, index) => {
                      return (
                        <div key={index}>
                          {activity.type === "ANNOUNCEMENT" && (
                            <CardAnnouncement activity={activity} />
                          )}
                          {activity.type === "POST" && (
                            <ActivityCard
                              staff={staff}
                              activity={activity}
                              activityDetails={() => {
                                history.push({
                                  pathname: "/Dashboard-Activity",
                                  activityId: activity.id,
                                });
                              }}
                              activityDetailsPage={() => {
                                history.push({
                                  pathname: "/Dashboard-Activity",
                                  activityId: activity.id,
                                });
                              }}
                              likeOnClick={() => {
                                handleLike(activity.id);
                              }}
                              dislikeOnClick={() => {
                                handleDisLike(activity.id);
                              }}
                              deleteActivity={() => {
                                deleteActivity(deleteActivityId);
                              }}
                              editActivity={(id) => {
                                setIsOpenPost(true);
                                setActivityId(id);
                              }}
                            />
                          )}
                          {activity.type == "EVENT" && (
                            <CardEvent activity={activity} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
                {loading && <Loader />}
                {endOfList && (
                  <div className="extra-small-text align-center text-muted pb-2">
                    ----{t("End")}----
                  </div>
                )}
              </div>
            )}
          </div>
          <Post
            show={isOpenPost}
            onClose={() => {
              setIsOpenPost(false);
            }}
            onSave={(msg) => {
              if (msg) {
                setIsToast(msg);
                reset();
                getActivityList();
              }
              setIsOpenPost(false);
              setActivityId(0);
            }}
            onEdit={(msg) => {
              if (msg) {
                setIsToast(msg);
                reset();
                getActivityList();
              }
              setIsOpenPost(false);
              setActivityId(0);
            }}
            activityId={activityId}
          />
        </div>
      </div>
    </>
  );
}

export default ToastHOC(Dashboard);
