import React, { useState } from "react";
import LogoImage from "../../assets/images/loading.gif";
import RecaptchaWrapper from "./RecaptchaWrapper";

const LoadingHOC = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setIsLoading] = useState(false);

    const setLoadingState = (isComponentLoading) => {
      setIsLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading ? (
          <div className="theme-cyan">
            <div
              className="page-loader-wrapper"
              style={{ display: isLoading ? "block" : "none" }}
            >
              <div className="loader">
                <div className="m-t-30">
                  <img src={LogoImage} width="48" height="48" alt="Lucid" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <RecaptchaWrapper path={props?.location?.pathname}>
            <WrappedComponent {...props} setIsLoading={setLoadingState} />
          </RecaptchaWrapper>
        )}
      </>
    );
  }

  return HOC;
};

export default LoadingHOC;
