import React from "react";
import { Rings } from "react-loader-spinner";

function Loader({ variant = "big" }) {
  if (variant === "small") {
    return (
      <div className="m-auto w-100 h-100">
        <div className="row h-100">
          <div className="loader-wrapper d-flex justify-content-center align-items-center">
            <Rings
              height="50"
              width="50"
              color="#6418C3"
              radius="10"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="loader-wrapper">
            <Rings
              height="100"
              width="100"
              color="#6418C3"
              radius="10"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
