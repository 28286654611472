import React, { useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../assets/Icon/Search.svg";
import { Avatar } from "@mui/material";
import { imageMyProfile } from "../CommonImage";
import ApiCalling from "../../network/ApiCalling";
import student from "../../assets/Icon/user.svg";
import { SeparateDot } from "../../components/common/separateDot";
import check from "../../assets/Icon/check-double.svg";
import { useTranslation } from "react-i18next";
import Util from "../../Util";

export default function NewParentChat({ show, onClose, onParentClick }) {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const [search_Parent, setSearch_Parent] = useState("");
  const [filteredParentList, setFilteredParentList] = useState([]);
  const [parentList, setParentList] = useState([]);

  const getParentList = () => {
    ApiCalling.apiCallBodyDataGet("chatroom/get-parent")
      .then((res) => {
        setParentList(res.data);
        setFilteredParentList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getParentList();
  }, []);
  return (
    <div>
      <SwipeableDrawer
        // hideBackdrop={true}
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={onClose}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("New Chat Parent")}</div>
              <IconButton aria-label="close" size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="body mt-3">
              <div className="row mt-2">
                <div className="input-group">
                  <input
                    className="form-control search "
                    placeholder="Search"
                    type="text"
                    value={search_Parent}
                    onChange={(e) => {
                      // setSearch_Parent(e.target.value);
                      setSearch_Parent(e.target.value);
                      const searchValue = e.target.value;
                      if (searchValue.trim() === "") {
                        setFilteredParentList(parentList);
                      } else {
                        const filteredParent = parentList.filter((s) =>
                          s.firstName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        );
                        setFilteredParentList(filteredParent);
                      }
                    }}
                  />
                  <div className="input-group-prepend">
                    <div className="search-icon">
                      <img src={Search} className="svg-icon" alt="" />
                    </div>
                  </div>
                </div>
                {filteredParentList.length > 0 ? (
                  <div className="chat-staff-container mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredParentList.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      if (item.email_verified == true) {
                                        e.preventDefault();
                                        onParentClick(item);
                                      }
                                    }}
                                  >
                                    {item.avatar ? (
                                      <img
                                        className="rounded-circle "
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={imageMyProfile + item.avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        aria-label="recipe"
                                        className="avatar-font"
                                      >
                                        {item.firstName.toUpperCase()[0] +
                                          item.lastName.toUpperCase()[0]}
                                      </Avatar>
                                    )}
                                    <a
                                      className="ml-3"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // onParentClick(item);
                                      }}
                                    >
                                      {item.firstName} {item.lastName}
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <img
                                            className="mr-1"
                                            src={student}
                                            height={12}
                                            width={12}
                                          />
                                          {item.child[0].Student.first_name +
                                            " " +
                                            item.child[0].Student.last_name}
                                          <SeparateDot />
                                          {t("Class")}:{" "}
                                          {item.child[0].Student.classroom !==
                                            null &&
                                            item.child[0].Student.classroom
                                              .name}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  {item.email_verified == false && (
                                    <div className="text-danger small-text">
                                      {t("Not Registered")}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 offset-lg-12">
                      <div className="d-flex text-center p-5">
                        <i
                          className="card p-5 icon-book-open"
                          style={{
                            fontSize: "50px",
                            opacity: "0.3",
                          }}
                        ></i>
                      </div>
                      <p className="text-center small text-muted">
                        {t("Data Not Found")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
