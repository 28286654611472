import React from "react";
import { useTranslation } from "react-i18next";
import PDFPreview from "../../../components/common/PDFPreview";
import CloseIcon from "@mui/icons-material/Close";

const Document = ({ handleRemove, iconClass, fileName, fileSize, file }) => {
  const { t } = useTranslation();

  const isPDFFile = file.type === "application/pdf";

  return (
    <div className="card p-2 mb-0">
      <div className="file">
        <div className="hover">
          <button
            type="button"
            className="btn btn-icon float-right"
            onClick={handleRemove}
          >
            {/* <i className="fa fa-trash-o"></i> */}
            <CloseIcon />
          </button>
        </div>

        <div className="icon d-inline-block">
          <i className={iconClass}></i>
        </div>

        <div className="file-name">
          <p className="m-b-5 text-muted">{fileName}</p>
          {isPDFFile && <PDFPreview file={file} />}
          <small>
            {t("Size")}: {fileSize}{" "}
          </small>
        </div>
      </div>
    </div>
  );
};

export default Document;
