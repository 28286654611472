import React, { useState } from "react";
import BillDashboard from "./BillDashboard";
import BillPlanLibrary from "./BillPlanLibrary";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";

function DashboardTab(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container-fluid">
      <div className="body">
        <div className="col-lg col-md-12 p-3">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6418c3",
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  fontFamily: "Public Sans, sans-serif",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "#6418c3",
                    fontWeight: "600",
                  },
                }}
                value="1"
                label={t("Dashboard")}
              />
              <Tab
                sx={{
                  fontFamily: "Public Sans, sans-serif",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "#6418c3",
                    fontWeight: "600",
                  },
                }}
                value="2"
                label={t("Billing Plan Library")}
              />
            </Tabs>
          </Box>
          {value === "1" && <BillDashboard />}
          {value === "2" && <BillPlanLibrary />}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(DashboardTab);
