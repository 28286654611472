import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/common/Loader";
import Logo from "../../components/common/Logo";
import { useHistory } from "react-router-dom";
import "../Register/Custom.css";
import LoadingHOC from "../HOC/LoadingHOC";
import ApiCalling from "../../network/ApiCalling";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import Invalid from "../../assets/Icon/invalid.svg";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

const Registration = (props) => {
  const { t } = useTranslation();
  const { setIsLoading } = props;

  const history = useHistory();

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [rollIdError, setRollIdError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [userError, setUserError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState(null);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {}, [roleId]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setLoading(true);
          ApiCalling.apiCallParamsGet(
            "user/check-user-exists/" + res.data.email
          )
            .then((response) => {
              setLoading(false);
              if (response.data.status === false) {
                history.push({
                  pathname: "/select-Role",
                  firstName: res.data.given_name,
                  lastName: res.data.family_name,
                  email: res.data.email,
                });
              } else {
                setUserError(response.data.message);
              }
            })
            .catch((error) => {
              console.log("error");
            });
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const checkMail = async () => {
    const userDetails = {
      firstName,
      lastName,
      email,
      password,
      roleId,
    };
    ApiCalling.apiCallParamsGet("user/generate-verification-code/" + email)
      .then((res) => {
        setLoading(false);
        if (res.data.status === true) {
          Util.saveRegisteredUser(userDetails);
          history.push({
            pathname: "/verifyEmailAddress",
          });
        } else {
          if (res.data.error) {
            setRegistrationError(t(res.data.message));
          } else {
            setEmailError(t(res.data.message));
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const formHandler = (e) => {
    e.preventDefault();
    let error = false;
    setFNameError("");
    setLNameError("");
    setEmailError("");
    setPassError("");
    setRollIdError("");
    setTermsError("");
    setRegistrationError("");
    if (firstName === "") {
      setFNameError(t("First Name is required"));
      error = true;
    }
    if (lastName === "") {
      setLNameError(t("Last Name is required"));
      error = true;
    }
    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (password === "") {
      setPassError(t("Password is required"));
      error = true;
    } else if (Util.password(password) === false) {
      setPassError(t("password must be at least 5 characters long"));
      error = true;
    }
    if (roleId == 0) {
      setRollIdError(t("Please select 'who you are'"));
      error = true;
    }
    if (isTermsChecked === false) {
      setTermsError(t("Please accept terms of service"));
      error = true;
    }

    if (!error) {
      setLoading(true);
      checkMail();
    }
  };

  const changeName = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
    setFNameError("");
  };
  const changeLastName = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
    setLNameError("");
  };
  const checkEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const checkPassword = (e) => {
    setPassword(e.target.value);
    setPassError("");
  };
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="auth-box auth-wrapper">
              <Logo />
              <div className="card">
                <div className="header">
                  <h1 className="text-center">{t("Sign up")}</h1>
                </div>
                <div className="body">
                  {registrationError !== "" && (
                    <div className="text-danger m-2 text-center">
                      {registrationError}
                    </div>
                  )}
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <input
                            type="text"
                            className={`form-control custom-input ${
                              fNameError.length > 0 && "input-error"
                            }`}
                            placeholder={t("First name")}
                            value={firstName}
                            onChange={changeName}
                          />
                          {fNameError.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {fNameError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-filed">
                          <input
                            type="text"
                            value={lastName}
                            onChange={changeLastName}
                            className={`form-control custom-input ${
                              lNameError.length > 0 && "input-error"
                            }`}
                            placeholder={t("Last name")}
                          />
                          {lNameError.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {lNameError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <input
                            type="email"
                            value={email}
                            onChange={checkEmail}
                            className={`form-control custom-input ${
                              emailError.length > 0 && "input-error"
                            }`}
                            placeholder={t("Email address")}
                          />
                          {emailError.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {emailError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control custom-input ${
                              passError.length > 0 && "input-error"
                            }`}
                            defaultValue={password}
                            onChange={checkPassword}
                            placeholder={t("Password")}
                          />

                          <div
                            style={{
                              float: "right",
                              marginTop: "-32px",
                              marginRight: "5px",
                            }}
                          >
                            <button
                              onClick={togglePasswordVisibility}
                              style={{ background:"none"}}
                            >
                              {showPassword ? (
                                <VisibilityOff fontSize="small"/>
                              ) : (
                                <Visibility fontSize="small"/>
                              )}
                            </button>
                          </div>

                          {passError.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {passError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <div className="input-filed">
                          <select
                            className={`form-select select-dropdown custom-input ${
                              rollIdError.length > 0 && "input-error"
                            }`}
                            aria-label="Select Role"
                            onChange={(e) => {
                              setRoleId(e.target.value);
                              setRollIdError("");
                            }}
                          >
                            <option selected disabled>
                              {t("Who you are")}
                            </option>
                            <option value={1}>{t("Staff / Teacher")}</option>
                            <option value={2}>{t("Parent")}</option>
                          </select>
                          {rollIdError.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {rollIdError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="input-filed">
                          <label className="fancy-checkbox element-left">
                            <input
                              type="checkbox"
                              name="allselect"
                              defaultChecked={isTermsChecked}
                              onChange={(e) => {
                                setIsTermsChecked(e.target.checked);
                                setTermsError("");
                              }}
                            />
                            <span tabIndex={0}>
                              {t(
                                "by creating an account, I agree to the Terms of service"
                              )}
                            </span>
                          </label>
                          {termsError.length > 0 && (
                            <div className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {termsError}
                            </div>
                          )}
                        </div>
                        <button
                          className="btn custom-primary btn-lg btn-block get-started-btn"
                          type="submit"
                          onClick={formHandler}
                        >
                          {t("Get Started")}
                        </button>
                      </div>
                    </div>
                    <p className="text-center text-separator">{t("Or")}</p>
                    <div className="row mt-3">
                      <div className="col">
                        <a className="btn btn-signin-social" onClick={login}>
                          <i className="fa fa-google"></i>
                          {t("Sign in with Google")}
                        </a>
                      </div>
                    </div>
                    {userError.length > 0 && (
                      <span className="text-danger">{userError}</span>
                    )}
                    <div className="row mt-4">
                      <div className="bottom col-lg-10 offset-3">
                        <span>
                          {t("Already have an account?")}
                          <a className={"space-before-5"} href="login">
                            {t("Login")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingHOC(Registration);
