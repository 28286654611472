// import socketIOClient from "socket.io-client";
import React from "react";
import socketio from "socket.io-client";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const socket = socketio.connect(BASE_URL, {
  transports: ["websocket"],
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 1000, // Delay between reconnection attempts (1 second)
  reconnectionDelayMax: 5000, // Maximum delay for reconnection (5 seconds)
  timeout: 20000,
});
console.log("Connection", socket);
export const SocketContext = React.createContext();
