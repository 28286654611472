import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import "../../../screens/Dashbord/DashboardCustom.css";
import { useHistory, useLocation } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import Invalid from "../../../assets/Icon/invalid.svg";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import { switchTab } from "../../../actions/SwitchTabAction";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader";

const Permission = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [permissionList, setPermissionList] = useState([]);
  const [roleId, setRoleId] = useState();
  const [roleTitle, setRoleTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [readonly, setReadonly] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [typeTeacher, setTypeTeacher] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAddRole = props.location.isAddRole;
  const role = props.location.role;
  const history = useHistory();

  useEffect(() => {
    if (role) {
      setRoleId(role.id);
      setRoleTitle(role.role);
      setReadonly(role.readonly);
      setTypeTeacher(role.teacher);
    }
    getPermission();
  }, []);

  useEffect(() => {
    if (!role && !isAddRole) {
      history.push("/settings");
    }
  }, []);
  
  const getPermission = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("permission")
      .then((res) => {
        setIsLoading(false);
        let list = res.data;
        const tempList = list.map((obj) => {
          return {
            ...obj,
            isChecked: false,
          };
        });
        if (role) {
          getExistingPermission(tempList);
        } else {
          setPermissionList(tempList);
        }
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getExistingPermission = async (tempList) => {
    tempList.forEach(function (obj) {
      obj.isChecked = role.permissions.some((item) => {
        return item.reference === obj.reference;
      });
    });

    tempList.forEach((item, i) => {
      if (item.id_parent === null) {
        const tmpSubPermissionList = tempList.filter(
          (obj) => obj.id_parent === item.id
        );
        const tmpCheckedSubPermissionList = tmpSubPermissionList.filter(
          (data) => data.isChecked === true
        );

        if (
          tmpSubPermissionList.length === tmpCheckedSubPermissionList.length
        ) {
          item.isChecked = true;
        }
      }
    });

    setPermissionList(tempList);
  };
  const handleSave = (e) => {
    e.preventDefault();
    setTitleError("");
    let error = false;
    if (roleTitle === "") {
      setTitleError(t("Title is required"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);

      let p = [];
      permissionList.map((item) => {
        if (item.id_parent !== null && item.isChecked) {
          p.push(item.id);
        }
      });
      if (role) {
        let updateData = {
          id: role.id,
          role: roleTitle,
          permissions: p.toString(),
          teacher: typeTeacher ? "true" : "false",
        };
        ApiCalling.apiCallBodyDataPost("role/update", updateData)
          .then((res) => {
            if (res.data.status == true) {
              setIsLoading(false);
              dispatch(switchTab(true));
              history.push({
                pathname: "/settings",
                pendingToast: res.data.message,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const insertData = {
          role: roleTitle,
          permissions: p.toString(),
          teacher: typeTeacher ? "true" : "false",
        };
        ApiCalling.apiCallBodyDataPost("role/add", insertData)
          .then((res) => {
            setIsLoading(false);
            dispatch(switchTab(true));
            history.push({
              pathname: "/settings",
              pendingToast: res.data.message,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <BreadCrumbComponent
            Breadcrumb={[
              {
                name: "Settings",
                navigate: () => {
                  history.push("/settings");
                },
              },
              { name: `${t("Role")}` },
            ]}
          />
          <div className="">
            <div className="body card">
              <div className="row text-start">
                {!isLoading ? (
                  <div className="col-lg col-md-12 p-4 m-l-10 m-r-10">
                    <div className="input-filled mb-1">
                      <label className="basic-text required">
                        {t("Title")}
                      </label>
                      <input
                        type="text"
                        value={roleTitle}
                        readOnly={readonly}
                        className={`form-control custom-input ${
                          titleError.length > 0 && "input-error"
                        }`}
                        onChange={(e) => {
                          setRoleTitle(e.target.value);
                          setTitleError("");
                        }}
                      />
                      {titleError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {titleError}
                        </span>
                      )}
                    </div>

                    <label className="fancy-checkbox mt-3">
                      <input
                        name="checkbox"
                        type="checkbox"
                        disabled={readonly}
                        value={typeTeacher}
                        onChange={(e) => {
                          setTypeTeacher(e.target.checked);
                        }}
                        checked={typeTeacher}
                        
                      />
                      <span tabIndex={0}>
                        {t(
                          "Is a Teacher (can access only classes which assign to him/her as teacher) "
                        )}
                      </span>
                    </label>

                    <hr />
                    <label htmlFor="#">{t("Permissions")}</label>
                    <div className="row">
                      <div className="fancy-checkbox mx-4">
                        <div className="col-6">
                          {permissionList
                            .filter((item) => item.id_parent == null)
                            .map((item, index) => (
                           
                                <span
                                  key={item.id}
                                  style={{ display: "block" }}
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={item.isChecked}
                                      disabled={readonly}
                                      onChange={(e) => {
                                        let tmpList = [...permissionList];

                                        tmpList.forEach((item1, i) => {
                                          if (
                                            item1.id_parent === item.id ||
                                            item1.id === item.id
                                          ) {
                                            tmpList[i].isChecked =
                                              e.target.checked;
                                            // Util.hasPermission(item.reference)
                                          }
                                        });

                                        setPermissionList(tmpList);
                                      }}
                                    />
                                    <span tabIndex={0} className="h6 mt-3">
                                      {item.permission}
                                    </span>
                                  </label>

                                  {permissionList.map(
                                    (childItem, childIndex) => {
                                      if (childItem.id_parent == item.id) {
                                        return (
                                          <div className="fancy-checkbox mx-4" key={childIndex}>
                                            <label>
                                              <input
                                                type="checkbox"
                                                checked={childItem.isChecked}
                                                disabled={readonly}
                                                onChange={(e) => {
                                                  let tmpList = [
                                                    ...permissionList,
                                                  ];
                                                  tmpList[
                                                    childIndex
                                                  ].isChecked =
                                                    e.target.checked;
                                                  setPermissionList(tmpList);

                                                  tmpList.forEach((val, i) => {
                                                    if (
                                                      val.id_parent === null
                                                    ) {
                                                      const tmpSubPermissionList =
                                                        tmpList.filter(
                                                          (obj) =>
                                                            obj.id_parent ===
                                                            val.id
                                                        );
                                                      const tmpCheckedSubPermissionList =
                                                        tmpSubPermissionList.filter(
                                                          (data) =>
                                                            data.isChecked ===
                                                            true
                                                        );

                                                      if (
                                                        tmpSubPermissionList.length ===
                                                        tmpCheckedSubPermissionList.length
                                                      ) {
                                                        val.isChecked = true;
                                                      } else {
                                                        val.isChecked = false;
                                                      }
                                                    }
                                                  });
                                                }}
                                              />
                                              <span tabIndex={0}>
                                                {childItem.permission}
                                              </span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </span>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-end">
                        {!readonly ? (
                          <button
                            onClick={handleSave}
                            className={`${
                              Util.hasPermission("MASTERS_EDIT")
                                ? "btn custom-primary mt-2"
                                : "no-permission"
                            }`}
                          >
                            {t("Save")}
                          </button>
                        ) : (
                          <div className="alert alert-warning text-center">
                            {t(
                              "This role is default Role, User can't do any changes in this"
                            )}
                            .
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permission;
