import React from "react";
import ApiCalling from "../../network/ApiCalling";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class LocalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: "",
      error: null,
      stack: "",
    };
  }

  getErrorlog = () => {
    const data = {
      message: this.state.errorMessage,
      stack: this.state.stack,
      url: this.props.location.pathname,
      type: "BROWSER",
    };
    ApiCalling.apiCallBodyDataPost("error-logs/add", data)
      .then((res) => {
        console.log("Error", res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("getDerivedStateFromError", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(
      "componentDidCatch LEB - hasError",
      this.state.hasError,
      new Date().getTime()
    );
    console.error(
      "error error error ::::::",
      errorInfo.componentStack,
      this.props.location.pathname
    );
    this.setState(
      {
        hasError: true,
        errorMessage: error.message,
        stack: errorInfo.componentStack,
      },
      () => {
        this.getErrorlog();
      }
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className="d-flex justify-content-center text-danger">
            An unexpected error occurred
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
export default withRouter(LocalErrorBoundary);
