import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ParentTopbar from "./ParentTopbar";
import ParentSidebar from "./ParentSidebar";
import { Toggle } from "../actions/ToggleAction";
import Util from "../Util";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

export default function ParentNavbarMenu() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      // console.log("yes, im here")
      localStorage.clear();
      Util.removeUser();
      history.push("/");
    }
  }, [token]);
  
  const [isTabletSize, setIsTabletSize] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const handleMediaQueryChange = (event) => {
    setIsTabletSize(event.matches);
    let allLiSelect = document.querySelectorAll("#main-menu li");
    // allLiSelect.forEach((li) => {
    //   li.addEventListener("click", (event) => {
    //     if (li.querySelector("ul ")) {
    //     } else {
    //       document.getElementById("left-sidebar").style.left = "-260px";
    //       dispatch(Toggle(true));
    //     }
    //   });
    // });
    if (event.matches) {
      console.log("1");
      document.getElementById("left-sidebar").style.left = "-260px";
      document.getElementById("main-content").style.width = "100%";
      dispatch(Toggle(true));

      // document.getElementById("main-content").addEventListener("click", () => {
      //   document.getElementById("left-sidebar").style.left = "-260px";
      //   dispatch(Toggle(true));
      // });
    } else {
      document.body.style.backgroundColor = "";
      document.getElementById("main-content").style.width = "100%";
      document.getElementById("left-sidebar").style.left = "0";
      dispatch(Toggle(false));

      let origionalSize = "100%";
      let reduceSize = "260px";
      let size = `calc(${origionalSize} - ${reduceSize})`;
      document.getElementById("main-content").style.width = size;
    }
  };
  useEffect(() => {
    const mediaQueryList = window.matchMedia("(max-width: 768px)");

    mediaQueryList.addListener(handleMediaQueryChange);

    return () => {
      mediaQueryList.removeListener(handleMediaQueryChange);
    };
  }, [isTabletSize]);

  return (
    <div>
      {/* {isToastMessage ? (
          <Toast
            id="toast-container"
            show={isToastMessage}
            onClose={() => {
              this.props.tostMessageLoad(false);
            }}
            className="toast-info toast-top-right"
            autohide={true}
            delay={5000}
          >
            <Toast.Header className="toast-info mb-0">
              Hello, welcome to Lucid, a unique admin Template.
            </Toast.Header>
          </Toast>
        ) : null} */}

      <ParentSidebar />
    </div>
  );
}
