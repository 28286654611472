import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../../Util";
import ToastHOC from "../../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import sampleFile from "../../../../assets/File/sample Add-Staff.xlsx";
import BreadCrumbComponent from "../../../../components/BreadCrumbComponent";
import Invalid from "../../../../assets/Icon/invalid.svg";
import Download from "../../../../assets/Icon/download.jpg";
import CustomFileUpload from "../../CustomFileUpload";
import * as XLSX from "xlsx/xlsx.mjs";
import ApiCalling from "../../../../network/ApiCalling";
import Sort from "../../../../assets/Icon/Short.svg";
import ExitAlert from "../../../../components/ExitAlert";
import moment from "moment/moment";
import Calander from "../../../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Call from "../../../../assets/Icon/call.svg";
import Loader from "../../../../components/common/Loader";
import { object } from "prop-types";
import { parse } from "date-fns";

function BulkStaff(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();
  const pendingToast = props.location?.pendingToast;
  const history = useHistory();

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [documentFileName, setDocumentFileName] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [showFileData, setShowFileData] = useState(false);
  const [fields, setFields] = useState([
    {
      id: 1,
      firstName: "",
      lastName: "",
      email: "",
      dob: null,
      phoneNumber: null,
      race: "",
      race_id: 0,
      nationality: "",
      nationality_id: "",
      ethnicity: "",
      ethnicity_id: "",
      role: "",
      roleId: 0,
      address1: "",
      address2: "",
      country: "",
      country_id: 0,
      state: "",
      state_id: 0,
      city: "",
      city_id: 0,
      pinCode: "",
      preferredLanguage: "",
      preferredLanguage_id: 0,
    },
  ]);
  const [fieldError, setFieldError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [allStateList, setAllStateList] = useState([]);
  const [allCityList, setAllCityList] = useState([]);
  const [preferredLanguageList, setPreferredLanguageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [StateName, setStateName] = useState([]);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [isStatesUpdated, setIsStatesUpdated] = useState(false);
  const [isStateCall, setIsStateCall] = useState(true);
  const [isCitiesUpdated, setIsCitiesUpdated] = useState(false);
  const [isCityCall, setIsCityCall] = useState(true);
  const CustomInput = ({ value, onClick, data }) => (
    <div
      className=" d-flex position-relative"
      style={{ marginTop: "-54px" }}
      onClick={onClick}
    >
      <input className={`custom-input__field `} value={value} readOnly />
      <div className="custom-input__icon">
        <img
          src={Calander}
          alt="Calander"
          className="svg-icon position-absolute"
          style={{ top: "10px", right: "10px" }}
        />
      </div>
    </div>
  );

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const sortedFields = fields.slice().sort((a, b) => {
    if (!sortConfig.key) {
      return 0;
    }

    const valueA = a[sortConfig.key];
    const valueB = b[sortConfig.key];

    if (typeof valueA === "string" && typeof valueB === "string") {
      if (sortConfig.direction === "ascending") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      return 0;
    }
  });
  const handleEditClick = async (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = true;
    setFields(updatedFields);
    // console.log("fields",updatedFields[index].country_id)
  };
  const handleCancelClick = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = false;
    setFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleDocumentDragOver = (e) => {
    e.preventDefault();
  };

  const handleDocumentDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleDocumentChange(file);
  };

  const handleDocumentChange = (file) => {
    if (file) {
      const maxSize = 50 * 1024 * 1024;
      if (file.size <= maxSize) {
        setFile(file);
        setFileURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 50MB"));
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleDocumentChange(file);
    setFileError("");
    setIsStateUpdated(true);
  };

  const handleInputChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
    setIsStateUpdated(true);
  };

  const handleSelectRoleChange = (index, event) => {
    const newFields = [...fields];
    const selectedRoleId = event.target.value;

    const selectedRole = roleList.find(
      (role) => role.id === Number(selectedRoleId)
    );

    if (selectedRole) {
      newFields[index]["roleId"] = selectedRole.id;
      newFields[index]["role"] = selectedRole.role;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectRaceChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = raceList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["race_id"] = selected.id;
      newFields[index]["race"] = selected.race;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectNationalityChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["nationality_id"] = selected.id;
      newFields[index]["nationality"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectEthnicityChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = ethnicityList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["ethnicity_id"] = selected.id;
      newFields[index]["ethnicity"] = selected.ethnicity;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectCountryChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;
    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["country_id"] = selected.id;
      newFields[index]["country"] = selected.name;

      newFields[index]["state_id"] = 0;
      newFields[index]["state"] = "";

      newFields[index]["city_id"] = 0;
      newFields[index]["city"] = "";

      setFields(newFields);
    }

    if (selected) {
      setIsStateCall(false);
      setCountryName([...countryName, selected.name]);
    }

    setIsStateUpdated(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
    // return string
    //   .trim()
    //   .toLowerCase()
    //   .replace(/^\w/, (c) => c.toUpperCase());
  };
  const handleSelectStateChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selectedCountry = newFields[index]?.country;
    const formattedCountry = capitalizeFirstLetter(selectedCountry);

    if (allStateList && selectedCountry) {
      const statesOfSelectedCountry = allStateList[formattedCountry];

      const selectedState = statesOfSelectedCountry.find(
        (state) => state.id === Number(selectedId)
      );

      if (selectedState) {
        newFields[index]["state_id"] = selectedState.id;
        newFields[index]["state"] = selectedState.name;

        newFields[index]["city_id"] = 0;
        newFields[index]["city"] = "";

        setFields(newFields);
      }

      if (selectedState) {
        setIsCityCall(false);
        setStateName([...StateName, selectedState.name]);
      }

      setIsStateUpdated(true);
    }
  };
  const handleSelectCityChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selectedState = newFields[index]?.state;
    const formattedState = capitalizeFirstLetter(selectedState);
    if (allCityList && selectedState && allCityList[formattedState]) {
      const cityOfSelectedState = allCityList[formattedState];

      const selectedCity = cityOfSelectedState.find(
        (city) => city.id === Number(selectedId)
      );
      if (selectedCity) {
        newFields[index]["city_id"] = selectedCity.id;
        newFields[index]["city"] = selectedCity.name;
        setFields(newFields);
      }
    }

    setIsStateUpdated(true);
  };
  const handleSelectLanguageChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = allCityList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["preferredLanguage_id"] = selected.id;
      newFields[index]["preferredLanguage"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };

  const readFileDetails = (data) => {
    const countryNames = [];
    const stateNames = [];
    data.forEach((element) => {
      if (
        element.country &&
        element.country !== null &&
        element.country !== undefined
      ) {
        countryNames.push(element.country);
      }

      if (
        element.state &&
        element.state !== null &&
        element.state !== undefined
      ) {
        stateNames.push(element.state);
      }
    });
    if (countryNames.length > 0) {
      setCountryName(countryNames);
    } else {
      console.log("updated");
      setIsStatesUpdated(true);
    }
    if (stateNames.length > 0) {
      setStateName(stateNames);
    } else {
      setIsCitiesUpdated(true);
    }
  };
  const handleFileRead = async () => {
    setFileError("");
    let error = false;
    if (file == "") {
      setFileError(t("please select file"));
      error = true;
    }

    if (!error) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const fileData = XLSX.utils.sheet_to_json(firstSheet);
        await setFileDetails(fileData);
        await readFileDetails(fileData);
        setIsStateCall(true);
        setIsCityCall(true);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (isStatesUpdated && isCitiesUpdated) {
      if (fileDetails !== null) {
        processData(fileDetails);
      }
    }
  }, [isStatesUpdated, allStateList, isCitiesUpdated]);

  const processData = (fileData) => {
    console.log("process data call");
    var temp = [];
    var i = 0;

    fileData.forEach((element) => {
      var RoleId = 0;
      roleList.map((r) => {
        if (r.role.toLowerCase() === element?.role?.toLowerCase()) {
          RoleId = r.id;
        }
      });
      var raceId = 0;
      raceList.map((r) => {
        if (r.race.toLowerCase() === element?.race?.toLowerCase()) {
          raceId = r.id;
        }
      });
      var nationalityId = 0;
      countryList.map((r) => {
        if (
          r.nationality?.toLowerCase() === element?.nationality?.toLowerCase()
        ) {
          nationalityId = r.id;
        }
      });
      var ethnicityId = 0;
      ethnicityList.map((r) => {
        if (r.ethnicity.toLowerCase() === element?.ethnicity?.toLowerCase()) {
          ethnicityId = r.id;
        }
      });
      var countryId = 0;
      countryList.map((c) => {
        if (c.name.toLowerCase() == element?.country?.toLowerCase()) {
          countryId = c.id;
        }
      });

      var stateId = 0;

      // Object.keys(allStateList).forEach((country) => {
      //   if (country.toLowerCase() == element?.country?.toLowerCase()) {
      //     allStateList[country].forEach((s) => {
      //       if (s.name.toLowerCase() == element?.state?.toLowerCase()) {
      //         stateId = s.id;
      //       }
      //     });
      //   }
      // });
      // console.log("Element state:", element?.state);
      // console.log("All state list:", allStateList);

      // var cityId = 0;
      // Object.keys(allCityList).forEach((state) => {
      //   if (state.toLowerCase() === element?.state?.toLowerCase()) {
      //     allCityList[state].forEach((c) => {
      //       if (c.name.toLowerCase() === element?.city?.toLowerCase()) {
      //         cityId = c.id;
      //       }
      //     });
      //   }
      // });

      var languageId = 0;
      preferredLanguageList.map((c) => {
        if (c.name.toLowerCase() == element?.preferredLanguage?.toLowerCase()) {
          languageId = c.id;
        }
      });
      // const ExcelDateToJSDate = (serial) => {
      //   const utcDays = Math.floor(serial - 25569);
      //   const millisecondsPerDay = 24 * 60 * 60 * 1000;
      //   const date = new Date(0);

      //   date.setUTCDate(utcDays);
      //   if (utcDays >= 60) {
      //     date.setUTCDate(date.getUTCDate() + 1);
      //   }

      //   // Adjust for the time zone offset
      //   const timeZoneOffset = date.getTimezoneOffset();
      //   date.setMinutes(date.getMinutes() - timeZoneOffset);

      //   return date;
      // };
      const ExcelDateToJSDate = (serial) => {
        const parsedDate = parse(serial, "dd-MM-yyyy", new Date());
    
        return parsedDate;
      };
      const jsDate = ExcelDateToJSDate(element.dob);

      temp.push({
        id: i,
        firstName: element.firstName,
        lastName: element.lastName,
        dob:
       element.dob && Util.isValidDate(jsDate) ? jsDate :element.dob,
        phoneNumber: element.phoneNumber,
        email: element.email,
        role: element.role,
        roleId: RoleId,
        nationality: element.nationality,
        nationality_id: nationalityId,
        race: element.race,
        race_id: raceId,
        ethnicity: element.ethnicity,
        ethnicity_id: ethnicityId,
        address1: element.address1,
        address2: element.address2,
        city: element.city,
        // city_id: cityId,
        pinCode: element.pinCode,
        country: element.country,
        country_id: countryId,
        state: element.state,
        // state_id: stateId,
        preferredLanguage: element.preferredLanguage,
        preferredLanguage_id: languageId,
      });
      i++;
    });

    setFields(temp);
    setShowFileData(true);
    console.log("temp", temp);
  };

  const uploadFile = () => {
    setFieldError(false);
    const updatedFields = [...fields];
    var error = false;
    updatedFields.map((val, index) => {
      if (
        val.firstName == "" ||
        val.lastName == "" ||
        val.email == "" ||
        Util.email_regex.test(val.email) === false ||
        val.phoneNumber == null ||
        val.phoneNumber == undefined ||
        val.roleId == 0 ||
        (val.pinCode !== "" &&
          val.pinCode !== undefined &&
          Util.Zip_regex.test(val.pinCode) === false)
      ) {
        // handleEditClick(index);
        console.log("error", val);
        updatedFields[index].isEditing = true;
        setFieldError(true);
        error = true;
      }
    });
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      updatedFields.map((val, index) => {
        const formData = new FormData();
        if (val.firstName) {
          formData.append("firstName", val.firstName);
        }
        if (val.lastName) {
          formData.append("lastName", val.lastName);
        }
        if (val.dob) {
          formData.append("dob", new Date(val.dob).toISOString());
        }
        if (val.email) {
          formData.append("email", val.email);
        }
        if (val.phoneNumber) {
          formData.append("mobile", val.phoneNumber);
        }
        if (val.race_id) {
          formData.append("race_id", val.race_id);
        }
        if (val.nationality_id) {
          formData.append("nationality_id", val.nationality_id);
        }
        if (val.ethnicity_id) {
          formData.append("ethnicity_id", val.ethnicity_id);
        }
        if (val.address1) {
          formData.append("address1", val.address1);
        }
        if (val.address2) {
          formData.append("address2", val.address2);
        }
        if (val.city) {
          formData.append("city", val.city);
        }
        if (val.state) {
          formData.append("state", val.state);
        }
        if (val.country_id) {
          formData.append("country_id", val.country_id);
        }
        if (val.pinCode) {
          formData.append("zip", val.pinCode);
        }
        if (val.roleId) {
          formData.append("role_id", val.roleId);
        }
        if (val.preferredLanguage_id) {
          formData.append("preferred_language_id", val.preferredLanguage_id);
        }
        ApiCalling.apiCallBodyDataPost("user/create-staff-user", formData)
          .then((res) => {
            setFile("");
            updatedFields[index].isEditing = false;
            if (res.data.status == true) {
              setIsLoading(false);
              setIsClickedOnce(false);
              setIsStateUpdated(false);
              setTriggerExit({
                path: "/staff",
                onOk: true,
              });
              history.push({
                pathname: "/staff",
                pendingToast: t("Staff Created Successfully"),
              });
            } else {
              history.push({
                pathname: "/staff",
                pendingToast: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      });
    }
  };

  const getRoleList = async () => {
    ApiCalling.apiCallParamsGet("role/list")
      .then((res) => {
        setRoleList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRaceList = async () => {
    ApiCalling.apiCallParamsGet("race")
      .then((res) => {
        setRaceList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCountryList = async () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setCountryList(res.data);
    });
  };

  const getEthnicityList = async () => {
    ApiCalling.apiCallBodyDataGet("ethnicity")
      .then((res) => {
        setEthnicityList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateList = () => {
    setIsStateLoading(true);
    setIsStatesUpdated(false);
    const data = {
      countries: countryName,
    };
    return new Promise((resolve, reject) => {
      ApiCalling.apiCallBodyDataPost(`state/by-country`, data)
        .then((res) => {
          console.log("STATE API CALL");

          setIsStateLoading(false);
          setAllStateList(res.data);
          if (isStateCall) {
            setIsStatesUpdated(true);
          }

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getCityList = async () => {
    setIsCityLoading(true);
    setIsCitiesUpdated(false);
    const data = {
      states: StateName,
    };
    const res = await ApiCalling.apiCallBodyDataPost(
      `city/by-state`,
      data
    ).then((res) => {
      setIsCityLoading(false);

      setAllCityList(res.data);
      if (isCityCall) {
        setIsCitiesUpdated(true);
      }
    });
  };

  const LanguageList = async () => {
    ApiCalling.apiCallBodyDataGet("preferred-language/list")
      .then((res) => {
        setPreferredLanguageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (countryName.length > 0) {
      getStateList();
    }
  }, [countryName]);
  useEffect(() => {
    if (StateName.length > 0) {
      getCityList();
    }
  }, [StateName]);

  useEffect(() => {
    getRoleList();
    getRaceList();
    getCountryList();
    getEthnicityList();

    // allCityData();
    LanguageList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/BulkStaff" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <div>
      <div className="container-fluid">
        <div className="row mb-2 py-4">
          <BreadCrumbComponent
            Breadcrumb={[
              {
                name: "My School",
                navigate: () => {
                  history.push("/Dashboard");
                },
              },
              {
                name: "Staff List",
                navigate: () => {
                  history.push("/staff");
                },
              },
              { name: `${t("Add New Staff Bulk")}` },
            ]}
          />
        </div>
        {!showFileData ? (
          <div className="container-80">
            <div className="body">
              <div className="col-lg mb-2">
                <h2 className="page-top-head text-center">
                  {t("Add New Staff Bulk")}
                </h2>
              </div>
              <div className="row  text-start m-0">
                <div className="col-6">
                  <h2 className="page-head">{t("Upload Document")}</h2>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    <img src={Download} />
                    <a
                      href={sampleFile}
                      target="_blank"
                      className="link-button"
                    >
                      {t("Download Template File")}
                    </a>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="input-filed">
                    <span className="basic-text  required">
                      {t("Upload Document")}
                    </span>
                    <CustomFileUpload
                      onChange={handleFileChange}
                      fileName={file ? file.name : documentFileName}
                      showFile={fileURL.length > 0 ? fileURL : ""}
                      onDragOver={handleDocumentDragOver}
                      onDrop={handleDocumentDrop}
                      alertMessage={
                        documentFileName.length > 0 &&
                        t("(incase you select new file it will replace old)")
                      }
                      acceptedDocument="XLS,XLSX (max file 25mb)"
                      accept={".xlsx, .xls"}
                      className={`${
                        fileError.length > 0
                          ? "custom-file-error"
                          : "custom-file"
                      }`}
                    />
                    {fileError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {fileError}
                      </span>
                    )}
                  </div>
                  <div className=" d-flex justify-content-between mt-4">
                    <button
                      className="btn secondary-lg-btn"
                      onClick={() => {
                        history.push("/staff");
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn custom-primary"
                      onClick={handleFileRead}
                    >
                      {t("Check Document")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row m-0">
              <div className="col-6">
                <h2 className="page-head">{t("Preview Document")}</h2>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center justify-content-end gap-1">
                  <button
                    className="btn btn-lg custom-secondary"
                    onClick={() => {
                      setShowFileData(false);
                    }}
                  >
                    {t("Back")}
                  </button>
                  <button
                    className="btn btn-lg custom-primary"
                    onClick={uploadFile}
                  >
                    {t("Save Staff")}
                  </button>
                </div>
              </div>
              {!isLoading ? (
                <div className="col-lg-12">
                  <div className="table-div mt-2">
                    <div className="table-wrapper">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {t("First Name")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("firstName");
                                }}
                              />
                            </th>
                            <th>
                              {t("Last Name")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("lastName");
                                }}
                              />
                            </th>

                            <th>{t("Date of Birth")}</th>

                            <th>
                              {t("Email")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("email");
                                }}
                              />
                            </th>
                            <th>
                              {t("Role")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("role");
                                }}
                              />
                            </th>
                            <th>{t("Phone Number")}</th>
                            <th>
                              {t("Race")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("race");
                                }}
                              />
                            </th>
                            <th>
                              {t("Nationality")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("nationality");
                                }}
                              />
                            </th>
                            <th>
                              {t("Ethnicity")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("ethnicity");
                                }}
                              />
                            </th>
                            <th>
                              {t("Preferred Language")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("preferredLanguage");
                                }}
                              />
                            </th>
                            <th>
                              {t("Address 1")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("address1");
                                }}
                              />
                            </th>
                            <th>
                              {t("Address 2")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("address2");
                                }}
                              />
                            </th>

                            <th>
                              {t("Country")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("country");
                                }}
                              />
                            </th>
                            <th>
                              {t("State")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("state");
                                }}
                              />
                            </th>
                            <th>
                              {t("City")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("city");
                                }}
                              />
                            </th>
                            <th>{t("ZIP Code")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedFields.map((field, index) => {
                            const isEditing = field.isEditing;
                            return (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor: `${
                                    field.firstName == "" ||
                                    field.lastName == "" ||
                                    field.email == "" ||
                                    Util.email_regex.test(field.email) ===
                                      false ||
                                    field.phoneNumber == null ||
                                    field.phoneNumber == undefined ||
                                    field.roleId == 0 ||
                                    (field.pinCode !== "" &&
                                      field.pinCode !== undefined &&
                                      Util.Zip_regex.test(field.pinCode) ===
                                        false)
                                      ? "#ff000014"
                                      : ""
                                  }`,
                                }}
                              >
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.firstName)
                                        ? field.firstName
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.firstName}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("First Name")}
                                          name="firstName"
                                          className={`form-control custom-input ${
                                            fieldError &&
                                            field.firstName == "" &&
                                            "input-error"
                                          }`}
                                        />
                                        {fieldError &&
                                          field.firstName == "" && (
                                            <span className="text-danger invalid-error">
                                              <img
                                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                                src={Invalid}
                                              />
                                              {t("First Name is required")}
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.lastName)
                                        ? field.lastName
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.lastName}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("Last Name")}
                                          name="lastName"
                                          className={`form-control custom-input ${
                                            fieldError &&
                                            field.lastName == "" &&
                                            "input-error"
                                          }`}
                                        />
                                        {fieldError && field.lastName == "" && (
                                          <span className="text-danger invalid-error">
                                            <img
                                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                                              src={Invalid}
                                            />
                                            {t("Last Name is required")}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(fields[index].dob) ? (
                                        <>
                                          <img
                                            src={Calander}
                                            alt=""
                                            className="mr-1"
                                            style={{ cursor: "pointer" }}
                                          />{" "}
                                          {moment(fields[index].dob).format(
                                            Util.getMomentDateTimeFormat(
                                              school?.date_format
                                            )
                                          )}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className=""
                                        style={{ minWidth: "150px" }}
                                      >
                                        <DatePicker
                                          placeholderText="Select Date"
                                          selected={
                                            Util?.isValidDate(fields[index].dob)
                                              ? fields[index].dob
                                              : null
                                          }
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          onChange={(date) =>
                                            handleInputChange(index, {
                                              target: {
                                                name: "dob",
                                                value: date,
                                              },
                                            })
                                          }
                                          dateFormat={school.date_format}
                                          // wrapperClassName={` ${
                                          //   dateOfBirthError.length > 0 ? "datePicker-error" : ""
                                          // }`}
                                          popperPlacement="bottom"
                                          maxDate={new Date()}
                                          customInput={
                                            <CustomInput data={field.dob} />
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.email)
                                        ? field.email
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.email}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("Email")}
                                          name="email"
                                          className={`form-control custom-input ${
                                            ((fieldError &&
                                              field.email == "") ||
                                              Util.email_regex.test(
                                                field.email
                                              ) == false) &&
                                            "input-error"
                                          }`}
                                        />
                                        {((fieldError && field.email == "") ||
                                          Util.email_regex.test(field.email)) ==
                                          false && (
                                          <span className="text-danger invalid-error">
                                            <img
                                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                                              src={Invalid}
                                            />
                                            {t("Email is required")}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    Util.isValidData(field.role) &&
                                    roleList.some(
                                      (c) =>
                                        c.role.toLowerCase() ===
                                        field?.role?.toLowerCase()
                                    ) ? (
                                      field?.role
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className={`form-select select-dropdown custom-input ${
                                            fieldError &&
                                            field.roleId == 0 &&
                                            "input-error"
                                          }`}
                                          name="roleId"
                                          onChange={(event, i) =>
                                            handleSelectRoleChange(index, event)
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value={0}>
                                            {t("Select")}
                                          </option>
                                          {roleList.map((option) => {
                                            if (
                                              field?.role?.toLowerCase() ==
                                              option.role.toLowerCase()
                                            ) {
                                              return (
                                                <option
                                                  selected
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.role}
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option value={option.id}>
                                                  {option.role}
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                        {fieldError && field.roleId == 0 && (
                                          <span className="text-danger invalid-error">
                                            <img
                                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                                              src={Invalid}
                                            />
                                            {t("Role is required")}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.phoneNumber) ? (
                                        <>
                                          <img
                                            src={Call}
                                            alt=""
                                            width={"16px"}
                                            className="mr-1"
                                          />
                                          {field.phoneNumber}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.phoneNumber}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("phoneNumber")}
                                          name="phoneNumber"
                                          className={`form-control custom-input ${
                                            fieldError &&
                                            (field.phoneNumber == null ||
                                              field.phoneNumber == undefined) &&
                                            "input-error"
                                          }`}
                                        />
                                        {fieldError &&
                                          (field.phoneNumber === null ||
                                            field.phoneNumber ===
                                              undefined) && (
                                            <span className="text-danger invalid-error">
                                              <img
                                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                                src={Invalid}
                                              />
                                              {t("Phone is required")}
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.race) &&
                                      raceList.some(
                                        (c) =>
                                          c.race.toLowerCase() ===
                                          field?.race?.toLowerCase()
                                      )
                                        ? field.race
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="race_id"
                                          onChange={(event, i) =>
                                            handleSelectRaceChange(index, event)
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value={0}>
                                            {t("Select")}
                                          </option>
                                          {raceList.map((option) => {
                                            if (
                                              field?.race?.toLowerCase() ==
                                              option.race.toLowerCase()
                                            ) {
                                              return (
                                                <option
                                                  selected
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.race}
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option value={option.id}>
                                                  {option.race}
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.nationality) &&
                                      countryList.some(
                                        (c) =>
                                          c.nationality?.toLowerCase() ===
                                          field?.nationality?.toLowerCase()
                                      )
                                        ? field.nationality
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="nationality_id"
                                          onChange={(event, i) =>
                                            handleSelectNationalityChange(
                                              index,
                                              event
                                            )
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value={0}>
                                            {t("Select")}
                                          </option>
                                          {countryList.map((option) => {
                                            if (
                                              field?.nationality?.toLowerCase() ==
                                              option.nationality?.toLowerCase()
                                            ) {
                                              return (
                                                option.nationality && (
                                                  <option
                                                    selected
                                                    value={option.id}
                                                    key={option.id}
                                                  >
                                                    {option.nationality}
                                                  </option>
                                                )
                                              );
                                            } else {
                                              return (
                                                option.nationality && (
                                                  <option value={option.id}>
                                                    {option.nationality}
                                                  </option>
                                                )
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.ethnicity) &&
                                      ethnicityList.some(
                                        (c) =>
                                          c.ethnicity.toLowerCase() ===
                                          field?.ethnicity?.toLowerCase()
                                      )
                                        ? field.ethnicity
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="ethnicity_id"
                                          onChange={(event, i) =>
                                            handleSelectEthnicityChange(
                                              index,
                                              event
                                            )
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value={0}>
                                            {t("Select")}
                                          </option>
                                          {ethnicityList.map((option) => {
                                            if (
                                              field?.ethnicity?.toLowerCase() ==
                                              option.ethnicity.toLowerCase()
                                            ) {
                                              return (
                                                <option
                                                  selected
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.ethnicity}
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option value={option.id}>
                                                  {option.ethnicity}
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(
                                        field.preferredLanguage
                                      ) &&
                                      preferredLanguageList.some(
                                        (c) =>
                                          c.name.toLowerCase() ===
                                          field?.preferredLanguage?.toLowerCase()
                                      )
                                        ? field.preferredLanguage
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="preferredLanguage_id"
                                          onChange={(event, i) =>
                                            handleSelectLanguageChange(
                                              index,
                                              event
                                            )
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value="">
                                            {t("Select")}
                                          </option>
                                          {preferredLanguageList.map(
                                            (option) => {
                                              if (
                                                field?.preferredLanguage?.toLowerCase() ==
                                                option.name.toLowerCase()
                                              ) {
                                                return (
                                                  <option
                                                    selected
                                                    value={option.id}
                                                    key={option.id}
                                                  >
                                                    {option.name}
                                                  </option>
                                                );
                                              } else {
                                                return (
                                                  <option value={option.id}>
                                                    {option.name}
                                                  </option>
                                                );
                                              }
                                            }
                                          )}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.address1)
                                        ? field.address1
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.address1}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("address 1")}
                                          name="address1"
                                          className="form-control custom-input"
                                        />
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.address2)
                                        ? field.address2
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.address2}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("address 2")}
                                          name="address2"
                                          className="form-control custom-input"
                                        />
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.country) &&
                                      countryList.some(
                                        (c) =>
                                          c.name.toLowerCase() ===
                                          field?.country?.toLowerCase()
                                      )
                                        ? field.country
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="country_id"
                                          onChange={(event, i) =>
                                            handleSelectCountryChange(
                                              index,
                                              event
                                            )
                                          }
                                          style={{ minWidth: "150px" }}
                                        >
                                          <option value="">
                                            {t("Select Country")}
                                          </option>
                                          {countryList.map((option) => {
                                            if (
                                              field?.country?.toLowerCase() ==
                                              option.name.toLowerCase()
                                            ) {
                                              return (
                                                <option
                                                  selected
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.name}
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option value={option.id}>
                                                  {option.name}
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.state)
                                        ? field.state
                                        : "-"}
                                    </>
                                  ) : (
                                    // <>
                                    //   {Util.isValidData(field.state) &&
                                    //     Object.keys(allStateList).map(
                                    //       (country) => {
                                    //         if (
                                    //           country.toLowerCase() ===
                                    //           field?.country?.toLowerCase()
                                    //         ) {
                                    //           const hasMatchingState =
                                    //             allStateList[country].some(
                                    //               (s) =>
                                    //                 s.name.toLowerCase() ===
                                    //                 field?.state?.toLowerCase()
                                    //             );
                                    //           return hasMatchingState
                                    //             ? field.state
                                    //             : "-";
                                    //         }
                                    //         return null;
                                    //       }
                                    //     )}
                                    // </>

                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.state}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("State")}
                                          name="state"
                                          className="form-control custom-input"
                                        />
                                        {/* <select
                                          className="form-select select-dropdown custom-input"
                                          name="state_id"
                                          onChange={(event, i) =>
                                            handleSelectStateChange(
                                              index,
                                              event
                                            )
                                          }
                                          style={{ minWidth: "150px" }}
                                          disabled={field.country_id == 0}
                                        >
                                          <option value="">
                                            {isStateLoading
                                              ? t("Loading...")
                                              : t("Select State")}
                                          </option>
                                          {Object.keys(allStateList).map(
                                            (country, index) => {
                                              if (
                                                country.toLowerCase() ===
                                                field?.country?.toLowerCase()
                                              ) {
                                                return (
                                                  <>
                                                    {allStateList[country].map(
                                                      (state, stateIndex) => (
                                                        <option
                                                          selected={
                                                            state.name.toLowerCase() ===
                                                            field?.state?.toLowerCase()
                                                          }
                                                          key={stateIndex}
                                                          value={state.id}
                                                        >
                                                          {state.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </select> */}
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {/* {Util.isValidData(field.city) &&
                                        Object.keys(allCityList).map(
                                          (state) => {
                                            if (
                                              state.toLowerCase() ===
                                              field?.state?.toLowerCase()
                                            ) {
                                              const hasMatchingCities =
                                                allCityList[state].some(
                                                  (c) =>
                                                    c.name.toLowerCase() ===
                                                    field?.city?.toLowerCase()
                                                );
                                              return hasMatchingCities
                                                ? field?.city
                                                : "-";
                                            }
                                            return null;
                                          }
                                        )} */}
                                      <>
                                        {Util.isValidData(field.city)
                                          ? field.city
                                          : "-"}
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      {/* <div className="input-filed">
                                        <select
                                          className="form-select select-dropdown custom-input"
                                          name="city_id"
                                          onChange={(event, i) =>
                                            handleSelectCityChange(index, event)
                                          }
                                          style={{ minWidth: "150px" }}
                                          disabled={field.state_id === 0}
                                        >
                                          <option value="">
                                            {isCityLoading
                                              ? t("Loading...")
                                              : t("Select city")}
                                          </option>
                                          {Object.keys(allCityList).map(
                                            (state) => {
                                              if (
                                                state.toLowerCase() ==
                                                field?.state?.toLowerCase()
                                              ) {
                                                return (
                                                  <>
                                                    {allCityList[state].map(
                                                      (city) => (
                                                        <option
                                                          key={city.id}
                                                          value={city.id}
                                                          selected={
                                                            city.name.toLowerCase() ===
                                                            field?.city?.toLowerCase()
                                                          }
                                                        >
                                                          {city.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </select> */}
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="text"
                                          value={field.city}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("City")}
                                          name="city"
                                          className="form-control custom-input"
                                        />
                                      </div>
                                      {/* </div> */}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <>
                                      {Util.isValidData(field.pinCode)
                                        ? field.pinCode
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      <div className="input-filed">
                                        <input
                                          style={{
                                            fontSize: "13px",
                                            minWidth: "150px",
                                          }}
                                          type="number"
                                          value={field.pinCode}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          placeholder={t("PinCode")}
                                          name="pinCode"
                                          className={`form-control custom-input ${
                                            fieldError &&
                                            field.pinCode !== "" &&
                                            field.pinCode !== undefined &&
                                            Util.Zip_regex.test(
                                              field.pinCode
                                            ) === false &&
                                            "input-error"
                                          }`}
                                        />
                                        {fieldError &&
                                          field.pinCode !== "" &&
                                          field.pinCode !== undefined &&
                                          Util.Zip_regex.test(field.pinCode) ===
                                            false && (
                                            <span
                                              style={{ width: "150px" }}
                                              className="text-danger"
                                            >
                                              <img
                                                className="fa fa-exclamation-circle mr-1 invalid-svg"
                                                src={Invalid}
                                              />
                                              {t(
                                                "Please enter valid zip-code(i.e: 12345 or 12345-2563)"
                                              )}
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </td>

                                <td style={{ verticalAlign: "top" }}>
                                  {!isEditing ? (
                                    <div className="d-flex align-items-center gap-2">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleEditClick(index);
                                        }}
                                      ></i>
                                      <div className="dropdown">
                                        <button
                                          // className="btn btn-lg custom-primary dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{ backgroundColor: "#fff" }}
                                        >
                                          <i
                                            className="fa fa-ellipsis-h"
                                            aria-hidden="true"
                                          ></i>
                                        </button>

                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleRemoveField(index)
                                              }
                                            >
                                              {t("Delete")}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <i
                                        className="fa fa-check mr-3 text-success"
                                        style={{ marginTop: "13px" }}
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleCancelClick(index);
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-times text-danger"
                                        style={{ marginTop: "13px" }}
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleCancelClick(index);
                                        }}
                                      ></i>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </>
        )}
        <ExitAlert
          visible={alertModal}
          onClose={() => setIsAlertModal(false)}
          onOk={() => {
            setTriggerExit((obj) => ({
              ...obj,
              onOk: true,
            }));
            setIsAlertModal(false);
          }}
        />
      </div>
    </div>
  );
}
export default ToastHOC(BulkStaff);
