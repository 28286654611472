import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../components/common/Loader";
import Logo from "../components/common/Logo";
import { useHistory } from "react-router-dom";
import LoadingHOC from "./HOC/LoadingHOC";
import Util from "../Util";
import { useGoogleLogin } from "@react-oauth/google";

import ApiCalling from "../network/ApiCalling";
import axios from "axios";
import Invalid from "../assets/Icon/invalid.svg";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

const Login = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [user, setUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const user = Util.getUser();
    if (Util.isValidData(user) && Util.isValidData(user.id)) {
      if (Util.getParentMode()) {
        history.push("/ParentsDashboard");
      } else {
        if (Util.isValidData(Util.getSelectedSchool())) {
          history.push("/Dashboard");
        } else {
          history.push("/JoinSchool");
        }
      }
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  const handleGoogleLogin = () => {
    setIsLoading(true);
    login();
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const data = {
            email: res.data.email,
          };
          ApiCalling.apiCallBodyDataPost("user/google-login", data)
            .then((res) => {
              if (res.data.status === true) {
                const token = res.data.token.access_token;
                localStorage.setItem("token", token);
                makeEnviornment(res.data.user);
              } else {
                setLoginError(t(res.data.message));
              }
            })
            .catch((err) => {
              console.log("Error", err);
            });
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const getLogin = async () => {
    if (!executeRecaptcha) {
      setIsLoading(false);
      console.log("Execute recaptcha not yet available");
      setLoginError(t("Recaptcha not yet available. Please try again."));
      return;
    }

    try {
      const token = await executeRecaptcha("login_attempt");
      const data = {
        email: email,
        password: password,
        recaptcha_token: token,
      };

      ApiCalling.apiCallBodyDataPost("user/login", data)
        .then((res) => {
          setIsLoading(false);
          if (res === "ERR_NETWORK") {
            setLoginError(t("Server API not working"));
          } else if (res.data.status === true) {
            // console.log(res.data.token.access_token);
            const token = res.data.token.access_token;
            localStorage.setItem("token", token);
            makeEnviornment(res.data.user);
          } else {
            setLoginError(t(res.data.message));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Error", error);
        });
    } catch (error) {
      setIsLoading(false);
      setLoginError(t("An error occurred. Please try again."));
      console.log("Error", error);
    }
  };

  function makeEnviornment(user) {
    console.log("USER", user);
    if (Util.isValidData(user)) {
      Util.saveUser(user);
      if (user.is_staff) {
        if (Util.isArrayNotEmpty(user.user_Schools)) {
          if (user.user_Schools[0].RoleId) {
            const userSchools = user.user_Schools[0];
            const activeAcademicYears =
              userSchools?.school?.academic_year?.filter((year) => year.active);
            Util.saveRoleInSelectedSchool(userSchools.RoleId);
            Util.saveSelectedSchool(userSchools.school);
            Util.saveSelectedAcademicYear(activeAcademicYears[0]);
            Util.saveParentMode(false);
            history.push("/Dashboard");
          } else {
            Util.saveParentMode(false);
            history.push("/waitingSchool");
          }
        } else if (user.is_parent) {
          if (user.Parents.length > 0) {
            Util.saveSelectedStudent(user.Parents[0].Student);
            if (user.default_language !== null) {
              const data = {
                id: user.Parents[0].Student.School.id,
                name: user.Parents[0].Student.School.name,
                lng: user.default_language,
              };
              Util.saveSelectedSchool(data);
            } else {
              Util.saveSelectedSchool(user.Parents[0].Student.School);
            }
          }
          Util.saveParentMode(true);
          // Util.removeSelectedSchool();
          // Util.removeSelectedAcademicYear();
          history.push("/ParentsDashboard");
        } else {
          Util.saveParentMode(false);
          history.push("/JoinSchool");
        }
      } else {
        console.log("imher");
        if (user.is_parent) {
          console.log("4");
          if (user.Parents.length > 0) {
            console.log("5");
            Util.saveSelectedStudent(user.Parents[0].Student);
          }
          Util.saveParentMode(true);
          Util.removeSelectedSchool();
          Util.removeSelectedAcademicYear();
          history.push("/ParentsDashboard");
        }
        // else {
        //   Util.saveParentMode(false);
        //   history.push("/JoinSchool");
        // }
      }
    } else {
      setLoginError(t("Not getting data of user"));
    }
  }

  const loginHandler = (e) => {
    setLoginError("");
    setPassError("");
    let error = false;

    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPassError(t("Password is required"));
      error = true;
    } else if (Util.password(password) === false) {
      setPassError(t("password must be at least 5 characters long"));
      error = true;
    } else {
      setPassError("");
    }
    if (!error) {
      setIsLoading(true);
      getLogin();
    }
  };

  const checkEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const checkPassword = (e) => {
    setPassword(e.target.value);
    setPassError("");
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="theme-cyan">
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="auth-box">
                <Logo />
                <div className="card">
                  <div className="header">
                    <h1 className="lead">{t("Welcome back!")} </h1>
                  </div>

                  <div className="body">
                    {loginError != "" && (
                      <div className="text-danger m-2 text-center">
                        {loginError}
                      </div>
                    )}
                    <div className="form-auth-small">
                      <div className="input-filed">
                        <input
                          type="email"
                          value={email}
                          onChange={checkEmail}
                          className={`form-control custom-input ${
                            emailError.length > 0 && "input-error"
                          }`}
                          placeholder={t("Email address")}
                        />
                        {emailError.length > 0 && (
                          <span className=" invalid-error ">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {emailError}
                          </span>
                        )}
                      </div>
                      <div className="input-filed ">
                        <input
                          className={`form-control custom-input ${
                            passError.length > 0 && "input-error"
                          }`}
                          id="signin-password"
                          placeholder={t("Password")}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={checkPassword}
                        />

                        <div
                          style={{
                            float: "right",
                            marginTop: "-32px",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={togglePasswordVisibility}
                            style={{ background:"none"}}
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </button>
                        </div>

                        {passError.length > 0 && (
                          <span className={"cl-input-error invalid-error"}>
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {passError}
                          </span>
                        )}

                        <span
                          className="helper-text"
                          style={{ marginTop: "15px" }}
                        >
                          <a href={`${process.env.PUBLIC_URL}/forgotpassword`}>
                            {t("Forgot password?")}
                          </a>
                        </span>
                      </div>

                      <div className="row mt-4">
                        <div className="col">
                          <button
                            className="btn custom-primary btn-lg btn-block get-started-btn"
                            type="submit"
                            onClick={loginHandler}
                          >
                            {t("Login")}
                          </button>
                        </div>
                      </div>
                      <p className="text-center text-separator">{t("Or")}</p>
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn btn-signin-social"
                            onClick={handleGoogleLogin}
                          >
                            <i className="fa fa-google"></i>{" "}
                            {t("Sign in with Google")}
                          </button>
                        </div>
                      </div>
                      <div className="bottom">
                        <span>
                          {t("Don't have an account?")}
                          <a href="registration" className={"space-before-5"}>
                            {t("Register")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingHOC(Login);
