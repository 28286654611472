import React from "react";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import Table from "../../components/common/Table";
import { greenTagStyle, orangeTagStyle } from "../Dashbord/TagButtonStyle";
import TagButton from "../Dashbord/TagButton";

const SchoolsTable = ({
  schools,
  selectedSchoolIds,
  handleAllSelectedSchools,
  schoolsChecked,
  setClickedRowData,
  setShowSchoolSettingsDrawer,
  setSortBy,
  setSortOrder,
}) => {
  const { t } = useTranslation();

  const data = [
    ...schools?.map((school) => {
      return {
        id: school?.id,
        checked: selectedSchoolIds.includes(school.id),
        createdOn: Util.ISOToDateTime(school?.created_on),
        name: school?.name,
        email: school?.user_Schools[0]?.user?.email,
        status: school?.is_active ? "Active" : "Inactive",
      };
    }),
  ];

  const columns = [
    {
      name: "checked",
      content: (
        <label className="fancy-checkbox element-left">
          <input
            type="checkbox"
            name="allselect"
            onChange={handleAllSelectedSchools}
            checked={
              schools.length > 0 && schools.length === selectedSchoolIds.length
            }
          />
          <span></span>
        </label>
      ),
    },
    {
      name: "createdOn",
      content: <div className="m-l-5">{t("Created On")}</div>,
      sortable: true,
      sortBy: "school.created_on",
    },
    { name: "name", content: <>{t("Name")}</>, sortable: true, sortBy: "school.name" },
    {
      name: "email",
      content: <>{t("Super Admin Email Address")}</>,
      sortable: true,
      sortBy: "school.contact_email",
    },
    {
      name: "status",
      content: <>{t("Status")}</>,
      sortable: true,
      sortBy: "school.is_active",
    },
  ];

  const customRenderers = [
    ...schools?.map((school) => {
      return {
        checked: (data) => {
          return (
            <label className="fancy-checkbox element-left">
              <input
                type="checkbox"
                name={school.id}
                onChange={schoolsChecked}
                value={school.id}
                checked={selectedSchoolIds.includes(school.id)}
              />
              <span></span>
            </label>
          );
        },
        name: (data) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              setClickedRowData(school);
              setShowSchoolSettingsDrawer(true);
            }}
          >
            {data}
          </div>
        ),
        status: (data) => (
          <TagButton
            size={"100px"}
            title={data}
            buttonStyle={school.is_active ? greenTagStyle : orangeTagStyle}
            icon="fa fa-circle"
            iconSize="6px"
          />
        ),
      };
    }),
  ];

  const rowStyle = (row) => {
    if (row.checked) {
      return { backgroundColor: "rgba(100, 24, 195, 0.04)" };
    }
  };

  return (
    <Table
      data={data}
      columns={columns}
      customRenderers={customRenderers}
      rowStyle={rowStyle}
      setSortBy={setSortBy}
      setSortOrder={setSortOrder}
    />
  );
};

export default SchoolsTable;
