import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ApiCalling from "../../../../network/ApiCalling";
import Select from "react-dropdown-select";
import Loader from "../../../../components/common/Loader";
import { useTranslation } from "react-i18next";
import Sort from "../../../../assets/Icon/Short.svg";
import Util from "../../../../Util";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";

export default function SummaryReport() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [studentStatus, setStudentStatus] = useState("all");
  const [attendanceStatus, setAttendanceStatus] = useState([]);
  const [summaryReportList, setSummaryReportList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [gradeName, setGradeName] = useState("");
  const [roomName, setRoomName] = useState("");

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const statusList = [
    { value: "all", title: "All" },
    { value: true, title: "Active" },
    { value: false, title: "Inactive" },
  ];
  const AttendanceList = [
    { value: 4, title: "All Attendance" },
    { value: 0, title: "Absent" },
    { value: 1, title: "Present" },
    { value: 2, title: "Leave" },
  ];

  const ChangeDateRange = (range) => {
    if (range) {
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setStartDate();
    setEndDate();
  };

  const changeGrade = (data) => {
    setGradeId(data[0].id);
    if (data[0].id == 0) {
      setGradeName("");
    } else {
      setGradeName(data[0].title);
    }
  };

  const changeRoom = (data) => {
    setRoomId(data[0].id);
    if (data[0].id == 0) {
      setRoomName("");
    } else {
      setRoomName(data[0].title);
    }
  };
  const changeStatus = (data) => {
    setStudentStatus(data[0].value);
  };
  const changeAttendanceStatus = (data) => {
    setAttendanceStatus(data);
  };
  const customRenderer = ({ props, state, methods }) => {
    const { values } = state;
    const selectedDays = values
      ?.map((value) => value.title.substring(0, 5))
      .join(", ");
    const hasSelectedValues = selectedDays.length > 0;
    return (
      <>
        <div
          className={` ${
            hasSelectedValues
              ? "react-dropdown-multi-selected"
              : "selected-weekDays"
          }`}
        >
          {selectedDays || props.placeholder}
          {hasSelectedValues && (
            <span
              className={`ml-2`}
              onClick={() => {
                methods.clearAll();
              }}
            >
              ×
            </span>
          )}
        </div>
      </>
    );
  };
  const gradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const roomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };

  const getSummaryReportList = () => {
    const data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      grade_id: gradeId,
      status: attendanceStatus.map((d) => d.value).join(","),
      isActive: studentStatus,
      classroom_id: roomId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("student/get-attendance-count", data)
      .then((res) => {
        setIsLoading(false);
        setSummaryReportList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportToExcel = () => {
    const dataForExport = summaryReportList.map((stu) => ({
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
      StudentID: stu.id,
      StudentName: stu.first_name,
      PresentDays: stu.presentCount,
      AbsentDays: stu.absentCount,
      LeaveDays: stu.leaveCount,
      AcademicYear: stu.academic_year !== null ? stu.academic_year : "-",
      Term: stu.term !== null ? stu.term : "-",

      Grade: gradeName.length > 0 ? gradeName : stu.grade,
      classRoom: roomName.length > 0 ? roomName : stu.classroom,
      ...(attendanceStatus.length > 0 && {
        AttendanceStatus: attendanceStatus.map((v) => v.title),
      }),
      ...(studentStatus !== "all" && {
        StudentStatus: studentStatus === true ? "Active" : "InActive",
      }),
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Attendance Summary Report"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    gradeList();
    roomList();
  }, []);

  useEffect(() => {
    if (startDate && endDate !== null) {
      getSummaryReportList();
    }
  }, [
    startDate,
    endDate,
    gradeId,
    roomId,
    attendanceStatus,
    studentStatus,
    sortBy,
    sortOrder,
  ]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid summary">
        <div className="row">
          <div className="col-lg-10 col-md-10 p-1">
            <div className="d-flex flex-wrap align-items-center gap-2">
              <DateRangePicker
                className="mr-2 custom-range-picker"
                onChange={(range) => ChangeDateRange(range)}
                onClean={handleCleanDate}
                placeholder="Select Date"
              />

              <Select
                className="select-custom"
                options={[{ id: 0, title: "Grade" }, ...gradeData]}
                labelField="title"
                valueField="id"
                values={[{ id: 0, title: "Grade" }]}
                onChange={(values) => changeGrade(values)}
              />

              <Select
                className="select-custom"
                options={[{ id: 0, name: "Room" }, ...roomData]}
                labelField="name"
                valueField="id"
                values={[{ id: 0, name: "Room" }]}
                onChange={(values) => changeRoom(values)}
              />

              <Select
                className="select-custom"
                options={[...statusList]}
                labelField="title"
                valueField="value"
                values={[{ value: "all", title: "Student Status" }]}
                onChange={(values) => changeStatus(values)}
              />

              <Select
                options={[...AttendanceList]}
                labelField="title"
                valueField="value"
                onChange={(values) => changeAttendanceStatus(values)}
                searchable={false}
                className="select-custom selected-weekDays text-nowrap"
                placeholder="Attendance Status"
                style={{ width: "100%" }}
                multi
                contentRenderer={customRenderer}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 text-right">
            <button
              className="btn btn-lg text-nowrap custom-primary m-1"
              type="button"
              onClick={exportToExcel}
              disabled={startDate == null}
            >
              {t("Export Excel")}
            </button>
          </div>
        </div>

        <div
          className="table-div text-nowrap mt-3"
          style={{ overflowX: "auto" }}
        >
          <div className="body">
            {!isLoading ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      {t("Student Id")}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("student.id");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Student Name")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("first_name");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Present Days")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("presentCount");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Absent Days")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("absentCount");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Leave Days")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("leaveCount");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Academic Year")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("academic_year");
                        }}
                      />
                    </th>
                    <th scope="col">
                      {t("Term")}{" "}
                      <img
                        src={Sort}
                        className="ml-1 fa-sort"
                        alt=""
                        onClick={() => {
                          handleSort("term");
                        }}
                      />
                    </th>
                  </tr>
                </thead>
                {startDate && endDate !== null ? (
                  <>
                    {summaryReportList.length > 0 ? (
                      <>
                        {summaryReportList.map((dt, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>{Util.studentIdFormat(dt.id)}</td>

                                <td>{dt.first_name + " " + dt.last_name}</td>
                                <td>{dt.presentCount}</td>
                                <td>{dt.absentCount}</td>
                                <td>{dt.leaveCount}</td>
                                <td>
                                  {dt.academic_year !== null
                                    ? dt.academic_year
                                    : "-"}
                                </td>
                                <td>{dt.term !== null ? dt.term : "-"}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </>
                    ) : (
                      <tfoot>
                        <tr>
                          <td colSpan={7}>{t("No Records found")}</td>
                        </tr>
                      </tfoot>
                    )}
                  </>
                ) : (
                  <tfoot>
                    <tr>
                      <td colSpan={7}>
                        {t("Please select period to generate the report")}
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
