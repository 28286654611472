import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Util from "../Util";
import { imageMyProfile } from "../screens/CommonImage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  AdminPanelSettingsOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import ChevronDown from "../assets/Icon/chevron-down.svg";
import { useTranslation } from "react-i18next";
import ApiCalling from "../network/ApiCalling";
import { useDispatch, useSelector } from "react-redux";
import { userImpersonate } from "../actions";

export default function UserComponent(props) {
  const { t } = useTranslation();
  const user = Util.getUser();
  const getLanguage = Util.getSelectedLanguage();

  const history = useHistory();
  const parentMode = Util.getParentMode();

  const isUserUpdate = useSelector((state) => state.notifyUserUpdateReducer);
  const isUserImpersonate = useSelector((state) => state.impersonateUser);
  
  const [userDetail, setUserDetail] = useState(user);
  // console.log("user", userDetail);
  const UBER_ADMIN = "UBER_ADMIN";
  const SUPER_ADMIN = "SUPER_ADMIN";

  const userSchoolRoles = userDetail?.user_Schools?.map(
    (userSchool) => userSchool?.role?.reference
  );

  const isUberAdmin = userSchoolRoles.includes(UBER_ADMIN);
  const isSuperAdmin = userSchoolRoles.includes(SUPER_ADMIN);

  const currentUserSchool = userDetail?.user_Schools?.find(
    (userSchool) => userSchool?.school?.id === Util.getSelectedSchool()?.id
  );

  const uberAdminRole = userDetail?.user_Schools?.find(
    (userSchool) => userSchool?.role?.reference === UBER_ADMIN
  );
  const dispatch = useDispatch();

  const getUserData = () => {
    ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
      .then((res) => {
        setUserDetail(res.data);
        Util.saveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isUserUpdate.isUpdate == true || isUserImpersonate.isUpdate == true) {
      getUserData();
    }
  }, [isUserUpdate, isUserImpersonate]);

  const loginBack = () => {
    const data = {
      user_id: userDetail.id,
    };
    ApiCalling.apiCallBodyDataPost("user_impersonate/get-impersonate", data)
      .then((res) => {
        if (res.data.status === true) {
          console.log(res.data);
          localStorage.clear();
          const token = res.data.token.access_token;
          localStorage.setItem("token", token);
          makeEnviornment(res.data.user);
          dispatch(userImpersonate(true));
          Util.removeUserImpersonate();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function makeEnviornment(user) {
    if (Util.isValidData(user)) {
      Util.saveUser(user);
      if (user.is_staff) {
        if (Util.isArrayNotEmpty(user.user_Schools)) {
          if (user.user_Schools[0].RoleId) {
            const userSchools = user.user_Schools[0];
            const activeAcademicYears =
              userSchools?.school?.academic_year?.filter((year) => year.active);
            Util.saveRoleInSelectedSchool(userSchools.RoleId);
            Util.saveSelectedSchool(userSchools.school);
            Util.saveSelectedAcademicYear(activeAcademicYears[0]);
            Util.saveParentMode(false);
            history.push("/Dashboard");
          }
        }
      }
    }
  }
  return (
    <div>
      {Util.isValidData(userDetail) && (
        <>
          <div className="dropdown">
            <span
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ cursor: "pointer" }}
            >
              <div className="user-account mt-1">
                <img
                  src={
                    userDetail?.avatar !== null
                      ? imageMyProfile + userDetail?.avatar
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                  className="rounded-circle user-photo"
                />
                <div>
                  <p className="mb-0">
                    {userDetail?.firstName + " " + userDetail?.lastName}
                  </p>

                  <p className="mt-0 mb-0 text-bold-secondary">
                    {parentMode
                      ? "Parent"
                      : isUberAdmin
                      ? uberAdminRole.role?.role
                      : currentUserSchool?.role?.role}
                  </p>
                  <img
                    src={ChevronDown}
                    alt=""
                    style={{
                      position: "absolute",
                      right: getLanguage !== "ar" && "-17px",
                      top: "10px",
                      cursor: "pointer",
                      height: "18px",
                      width: "18px",
                      left: getLanguage == "ar" && "-17px",
                    }}
                  />
                </div>
              </div>
            </span>
            <ul
              className="dropdown-menu user-dropdown"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <div
                  className="user-dropdown-option"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/my-profile");
                  }}
                >
                  <i className="icon-user mr-2"></i>
                  {t("My Profile")}
                </div>
              </li>
              {parentMode ? (
                <>
                  {userDetail?.is_parent == true &&
                  userDetail?.is_staff == true &&
                  userDetail?.Parents?.length &&
                  userDetail?.user_Schools.length ? (
                    <>
                      <li>
                        <div
                          className="user-dropdown-option"
                          onClick={props.switchToStaff}
                        >
                          <i className="icon-login"> </i>
                          {t("Switch as Staff")}
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {userDetail?.is_parent && (
                    <>
                      <li>
                        <div
                          className="user-dropdown-option"
                          onClick={props.LoginAsParent}
                        >
                          <i className="icon-power mr-2"></i>
                          {t("Login as Parent")}
                        </div>
                      </li>
                    </>
                  )}
                </>
              )}
              {(isUberAdmin || isSuperAdmin) && (
                <li>
                  <div
                    className="user-dropdown-option"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/admin");
                    }}
                  >
                    <i>
                      <AdminPanelSettingsOutlined
                        fontSize="small"
                        className="svg-icon mr-1"
                      />
                    </i>{" "}
                    {t("Admin")}
                  </div>
                </li>
              )}
              {Util.getUserImpersonate() && (
                <li>
                  <div
                    className="user-dropdown-option"
                    onClick={(e) => {
                      e.preventDefault();
                      loginBack();
                    }}
                  >
                    <i>
                      <AdminPanelSettingsOutlined
                        fontSize="small"
                        className="svg-icon mr-1"
                      />
                    </i>{" "}
                    {t("Login to your Account")}
                  </div>
                </li>
              )}
              {!Util.getUserImpersonate() && (
                <li>
                  <div
                    className="user-dropdown-option"
                    onClick={props.onLogoutClick}
                  >
                    <i>
                      <LogoutOutlined
                        fontSize="small"
                        className="svg-icon mr-1"
                      />
                    </i>{" "}
                    {t("Log Out")}
                  </div>
                </li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
