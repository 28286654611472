import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import Loader from "../../components/common/Loader";
import Search from "../../assets/Icon/Search.svg";
import close from "../../assets/Icon/close.svg";
import UsersTable from "./UsersTable";
import { ToastComponent } from "../HOC/ToastHOC";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalComponent from "../Dashbord/ModalComponent";
import { useDispatch } from "react-redux";
import { userImpersonate } from "../../actions";

const UsersSection = ({
  selectedUserIds,
  setSelectedUserIds,
  changeUsersStatus,
  fetchUsersTrigger,
}) => {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchClick, setSearchClick] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastSubtitle, setToastSubtitle] = useState("");
  const [toastType, setToastType] = useState("success");
  const [swtichImpersonatingUser, setSwtichImpersonatingUser] = useState(false);
  const [user, setUser] = useState("");

  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const handleAllSelectedUsers = (e) => {
    if (e.target.checked) {
      setSelectedUserIds(users.map((item) => item.id));
    } else {
      setSelectedUserIds([]);
    }
  };

  const usersChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedUserIds([...selectedUserIds, id]);
    } else {
      const data = selectedUserIds.filter((s) => s !== id);
      setSelectedUserIds(data);
    }
  };

  useEffect(() => {
    console.log("getAllUsers useEffect");
    let mounted = true;

    const getAllUsers = () => {
      const data = {
        page_size: recordPerPage,
        page_number: currentPage,
        role_id: roleId,
        str_search: searchClick,
        order_by: sortBy,
        ascdesc: sortOrder ? "desc" : "asc",
      };
      setIsLoading(true);
      ApiCalling.apiCallBodyDataPost("user/all", data)
        .then((res) => {
          if (mounted) {
            setUsers(res.data?.data);
            setTotalPages(res.data?.totalPages);
            setTotal(res.data?.total);
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getAllUsers();

    return () => {
      mounted = false;
    };
  }, [
    currentPage,
    recordPerPage,
    roleId,
    searchClick,
    sortBy,
    sortOrder,
    fetchUsersTrigger,
  ]);

  const impersonate = (item) => {
    const data = {
      user_id: item.id,
    };
    ApiCalling.apiCallBodyDataPost("user_impersonate/impersonate", data)
      .then((res) => {
        if (res === "ERR_NETWORK") {
          setShowNotification(true);
          setToastType("error");
          setToastSubtitle("");
          setToastTitle("Server API not working");
        } else if (res.data.status === true) {
          setUser(res);
          setSwtichImpersonatingUser(true);
        } else {
          setShowNotification(true);
          setToastType("error");
          setToastSubtitle("");
          setToastTitle(res.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Error", error);
      });
  };

  function makeEnviornment(user) {
    if (Util.isValidData(user)) {
      Util.saveUser(user);
      if (user.is_staff) {
        if (Util.isArrayNotEmpty(user.user_Schools)) {
          if (user.user_Schools[0].RoleId) {
            const userSchools = user.user_Schools[0];
            const activeAcademicYears =
              userSchools?.school?.academic_year?.filter((year) => year.active);
            Util.saveRoleInSelectedSchool(userSchools.RoleId);
            Util.saveSelectedSchool(userSchools.school);
            Util.saveSelectedAcademicYear(activeAcademicYears[0]);
            Util.saveParentMode(false);
            history.push("/Dashboard");
          } else {
            Util.saveParentMode(false);
            history.push("/waitingSchool");
          }
        } else if (user.is_parent) {
          if (user.Parents.length > 0) {
            Util.saveSelectedStudent(user.Parents[0].Student);
            if (user.default_language !== null) {
              const data = {
                id: user.Parents[0].Student.School.id,
                name: user.Parents[0].Student.School.name,
                lng: user.default_language,
              };
              Util.saveSelectedSchool(data);
            } else {
              Util.saveSelectedSchool(user.Parents[0].Student.School);
            }
          }
          Util.saveParentMode(true);
          history.push("/ParentsDashboard");
        } else {
          Util.saveParentMode(false);
          history.push("/JoinSchool");
        }
      } else {
        if (user.is_parent) {
          if (user.Parents.length > 0) {
            Util.saveSelectedStudent(user.Parents[0].Student);
          }
          Util.saveParentMode(true);
          Util.removeSelectedSchool();
          Util.removeSelectedAcademicYear();
          history.push("/ParentsDashboard");
        }
      }
    } else {
      setShowNotification(true);
      setToastType("error");
      setToastSubtitle("");
      setToastTitle("Not getting data of user");
    }
  }

 const loginAsImpersonatingUser = () => {
    localStorage.clear();
    const token = user.data.token.access_token;
    localStorage.setItem("token", token);
    dispatch(userImpersonate(true));
    Util.saveUserImpersonate(true);
    makeEnviornment(user.data.user);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 text-start">
          <h3>Manage users globally</h3>
        </div>
        <div className={`col-md-6  ${getLanguage !== "ar" && "text-end"}`}>
          {selectedUserIds.length > 0 ? (
            <div className="d-flex justify-content-end gap-2">
              <button onClick={() => changeUsersStatus({ active: true })}>
                Change to active
              </button>
              <button onClick={() => changeUsersStatus({ active: false })}>
                Change to inactive
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="table-div">
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search "
                placeholder={t("Search user by email or name")}
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon">
                  <img
                    src={search.length > 0 ? close : Search}
                    className="svg-icon"
                    alt=""
                    onClick={searchClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="table-wrapper">
                <UsersTable
                  users={users}
                  selectedUserIds={selectedUserIds}
                  handleAllSelectedUsers={handleAllSelectedUsers}
                  usersChecked={usersChecked}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  impersonate={impersonate}
                />
              </div>

              {totalPages > 0 && (
                <div
                  className="row m-0 table-footer  border-top"
                  style={{ height: "72px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <div>{t("Students Per Page")}</div>
                      <select
                        style={{ width: "72px", cursor: "pointer" }}
                        className="select-dropdown custom-input"
                        value={recordPerPage}
                        onChange={(e) => {
                          setRecordPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <div>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <div
                          className={`link-button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePreviousClick();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <KeyboardArrowLeftIcon />
                          <span className="basic-bold-text">
                            {t("Previous")}
                          </span>
                        </div>
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={(event, page) => {
                            setCurrentPage(page);
                          }}
                          size="small"
                          hideNextButton
                          hidePrevButton
                        />
                        <div
                          className={`link-button ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNextClick();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="basic-bold-text">{t("Next")}</span>
                          <KeyboardArrowRightIcon />
                        </div>
                      </Stack>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showNotification && (
        <ToastComponent
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          title={toastTitle}
          subtitle={toastSubtitle}
          type={toastType}
        />
      )}
      {swtichImpersonatingUser && (
        <ModalComponent
          show={swtichImpersonatingUser}
          onHide={() => {
            setSwtichImpersonatingUser(false);
          }}
          title={"Switch As User"}
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setSwtichImpersonatingUser(false);
          }}
          onConfirmClick={loginAsImpersonatingUser}
        >
          <span>
            {t("Are you sure to Impersonate this user?")}?
            {/* <br /> {t("You will be redirected to the ")}{" "}
            <b>{t("Parent Dashboard")}</b> {t("after logout from this school")}. */}
          </span>
        </ModalComponent>
      )}
    </div>
  );
};

export default UsersSection;
