import React, { useEffect, useState } from "react";
import unknownImg from "../../../assets/images/Frame 1000004000.png";
import camera from "../../../assets/Icon/camera-icon.svg";
import Location from "../../../assets/Icon/location.svg";
import { imageMyProfile, imageAddSchool } from "../../CommonImage";
import Util from "../../../Util";
import EditPhotoModal from "../EditPhotoModal";
import ApiCalling from "../../../network/ApiCalling";
import StaffAvtar from "../../../assets/Icon/Parent-avtar.svg";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";

const ProfileComponent = ({ data, userId, notification, defaultAvtar }) => {
  const { t } = useTranslation();
  const [profileObj, setProfileObj] = useState();
  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [photoURL, setProfilePhoto] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (userId > 0) {
      setIsLoading(true);
      if (data !== null) {
        setIsLoading(false);
        setProfileObj(data);
        setPhotoURL(
          Util.isValidData(data?.avatar) ? imageMyProfile + data?.avatar : ""
        );
        setCountry(data?.Country);
      }
    }
  }, [data]);

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
  };

  const handleSavePhoto = () => {
    const formData = new FormData();
    formData.append("id", userId);
    if (photo) {
      formData.append("avatar", photo);
    }

    ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setShowEditModal(false);
          notification(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {!isLoading ? (
        <div
          className="d-flex align-items-center gap-4"
          style={{ marginBottom: "24px" }}
        >
          {photoURL.length > 0 ? (
            <div
              className="profile-area"
              style={{
                position: "relative",
                height: "100px",
                width: "100px",
                borderRadius: "50%",
              }}
            >
              <img
                alt="User"
                className="rounded-circle user-photo img-responsive  mt-1 "
                src={photoURL}
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                }}
              />{" "}
              <span
                className={`${
                  Util.hasPermission("STAFF_EDIT")
                    ? "choose-profile"
                    : "no-permission"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setShowEditModal(true);
                }}
              >
                <img src={camera} alt="" />
              </span>
            </div>
          ) : (
            <div
              className="profile-area"
              style={{
                position: "relative",
                height: "100px",
                width: "100px",
                borderRadius: "50%",
              }}
            >
              <img
                alt="User"
                className="img-responsive  mt-1"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                  position: "relative",
                }}
                src={StaffAvtar}
              />
              <span
                className={`${
                  Util.hasPermission("STAFF_EDIT")
                    ? "choose-profile"
                    : "no-permission"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("photo").click();
                }}
              >
                <img src={camera} alt="" />
              </span>

              <input
                className="sr-only"
                id="photo"
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
              />
            </div>
          )}

          {userId > 0 && (
            <div>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#07112b",
                }}
              >
                {profileObj?.firstName + " " + profileObj?.lastName}{" "}
              </div>
              <div className="drawer-sub-header">
                {profileObj?.role && profileObj?.role && (
                  <>{profileObj?.role}</>
                )}
              </div>

              {country && (
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#4B5563",
                  }}
                >
                  <img src={Location} className="mr-2" alt="" />
                  {country ? country : ""}
                </div>
              )}
            </div>
          )}
          <EditPhotoModal
            show={showEditModal}
            onClose={(msg) => {
              setShowEditModal(false);
              setPhotoURL(
                Util.isValidData(profileObj?.avatar)
                  ? imageMyProfile + profileObj?.avatar
                  : ""
              );
            }}
            title={t("Edit Photo")}
            subTitle={t("Show profile picture the best version of yourself")}
            onChange={t("Change Image")}
            onSave={t("Save Photo")}
            Id={userId}
            onConfirmClick={() => setShowEditModal(false)}
            photoURL={photoURL}
            // ProfilePhoto={photoURL}
            handlePhotoChange={handleLogoChange}
            defaultAvtar={StaffAvtar}
            handleSavePhoto={handleSavePhoto}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProfileComponent;
