import React, { useState, useEffect } from "react";
import Logo from "../../components/common/Logo";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ThanksPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const submit = () =>{
    history.push("/")
  }
   useEffect(() =>{
    console.log("im here")
  },[])
  return (
    <>
      <div className="theme-cyan">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="auth-box auth-wrapper">
                <Logo />
                <div className="card">
                  <div className="header">
                    <h1 className="text-center">
                      {t("Thank You for Signing Up!")}
                    </h1>
                  </div>
                  <div className="body mx-4">
                    <div>
                      <p>
                        Your account has been successfully created. However,
                        before you can access the platform, we need to verify
                        your details.
                      </p>
                      <p className="mt-2">
                        Please contact us at <a href="mailto:support@classadia.com" onClick={() => window.location = 'mailto:support@classadia.com'}>support@classadia.com</a> to
                        complete the verification process and activate your
                        account.
                      </p>
                      <p className="mt-2">We look forward to assisting you!</p>
                    </div>
                    <div className="row ">
                      <div className="col">
                        <button className="btn custom-primary btn-lg btn-block get-started-btn mt-2" onClick={submit}>
                          {t("Okay!")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThanksPage;
