import React from "react";
import { Modal } from "react-bootstrap";

const PDFModal = ({ isOpen, onClose, url }) => {
  return (
    isOpen && (
      <Modal
        size="xl"
        show={isOpen}
        onHide={onClose}
        animation={true}
        centered={true}
        dialogClassName="pdf-modal"
      >
        <Modal.Body>
          <iframe
            title="Modal Content"
            src={url}
            width="100%"
            height="100%"
          ></iframe>
        </Modal.Body>
      </Modal>
    )
  );
};

export default PDFModal;
