import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import { Avatar } from "@mui/material";
import { NoteAltOutlined } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ToastHOC from "../../HOC/ToastHOC";
import { greenTagStyle, orangeTagStyle } from "../../Dashbord/TagButtonStyle";
import TagButton from "../../Dashbord/TagButton";
import { useSelector } from "react-redux";
import AddLeaveRequest from "./AddLeaveRequest";
import Sort from "../../../assets/Icon/Short.svg";
import Loader from "../../../components/common/Loader";

function LeaveRequestList(props) {
  const selected = useSelector((state) => state.SelectedStudentReducer);

  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const history = useHistory();
  const user = Util.getUser();

  const [pastRequestList, setPastRequestList] = useState([]);
  const [upcomingRequestList, setUpcomingRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleAddLeaveRequest, setIsVisibleAddLeaveRequest] =
    useState(false);

  const leaveRequestList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `leave-request/get-user-request/${selected.selectedStudent?.id}`
    )
      .then((res) => {
        setIsLoading(false);
        setPastRequestList(res.data?.past);
        setUpcomingRequestList(res.data?.upcoming);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (pendingToast && pendingToast?.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);
  useEffect(() => {
    if (selected !== null) {
      leaveRequestList();
    }
  }, [selected]);

  return (
    <div>
      <div className="container-fluid mb-3">
        <div className="row py-3 ">
          <div className="d-flex justify-content-between align-items-centers">
            <h2 className="page-head">{t("My Leave Request")}</h2>
            <div className="heading  ">
              <button
                className="btn custom-primary"
                onClick={() => {
                  setIsVisibleAddLeaveRequest(true);
                }}
              >
                {t("Request Leave")}
              </button>
            </div>
          </div>
        </div>
        <AddLeaveRequest
          isVisible={isVisibleAddLeaveRequest}
          isClose={() => {
            setIsVisibleAddLeaveRequest(false);
          }}
          isSave={() => {
            setIsVisibleAddLeaveRequest(false);
            leaveRequestList();
          }}
          notification={(msg) => {
            setIsToast(msg);
          }}
        />

        {!isLoading ? (
          <>
            <div className="table-div mb-3">
              <h5 className="p-3">{t("Upcoming Leaves")}</h5>
              <table className="table  ">
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Total Days")}</th>
                    <th>{t("Reason")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {upcomingRequestList?.length > 0 ? (
                  <tbody>
                    {upcomingRequestList?.map((value, index) => {
                      const calculateDuration = () => {
                        const startDate = moment(value.start_date);
                        const endDate = moment(value.end_date);
                        const duration = endDate?.diff(startDate, "days") + 1;
                        return duration;
                      };
                      const duration = calculateDuration();
                      return (
                        <tr key={index}>
                          <td>
                            {moment(value.start_date).format("DD/MM/YYYY")} -{" "}
                            {moment(value.end_date).format("DD/MM/YYYY")}{" "}
                          </td>
                          <td>{duration}</td>
                          <td>
                            {" "}
                            {value.Request_type !== null
                              ? value.Request_type?.type
                              : "-"}{" "}
                          </td>
                          <td>
                            <TagButton
                              style={"btn btn-secondary"}
                              size={"100px"}
                              title={`${
                                value.approved == true ? "Approved" : "Pending"
                              }`}
                              buttonStyle={
                                value.approved == true
                                  ? greenTagStyle
                                  : orangeTagStyle
                              }
                              icon="fa fa-circle"
                              iconSize="6px"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tfoot>
                    <tr>
                      <td colSpan={4}>
                        {t("There are no Upcoming leave request found")}
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>

            <div className="table-div mt-2">
              <h5 className="p-3">{t("Past Leaves")}</h5>
              <table className="table ">
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Total Days")}</th>
                    <th>{t("Reason")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {pastRequestList?.length > 0 ? (
                  <tbody>
                    {pastRequestList?.map((value, index) => {
                      const calculateDuration = () => {
                        const startDate = moment(value.start_date);
                        const endDate = moment(value.end_date);
                        const duration = endDate?.diff(startDate, "days") + 1;
                        return duration;
                      };
                      const duration = calculateDuration();
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            {moment(value.start_date)?.format(
                              "DD/MM/YYYY"
                            )} - {moment(value.end_date)?.format("DD/MM/YYYY")}{" "}
                          </td>
                          <td>{duration}</td>
                          <td>
                            {" "}
                            {value.Request_type !== null
                              ? value.Request_type?.type
                              : "-"}{" "}
                          </td>
                          <td>
                            <TagButton
                              style={"btn btn-secondary"}
                              size={"100px"}
                              title={`${
                                value.approved == true ? "Approved" : "Pending"
                              }`}
                              buttonStyle={
                                value.approved == true
                                  ? greenTagStyle
                                  : orangeTagStyle
                              }
                              icon="fa fa-circle"
                              iconSize="6px"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tfoot>
                    <tr>
                      <td colSpan={4}>
                        {t("There are no Past leave request found")}
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
export default ToastHOC(LeaveRequestList);
