import React, { useEffect, useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { ReactPhotoCollageComponent } from "./ReactPhotoCollageComponent";

const createPhotoIds = (photos) => {
  return photos.map((data, i) => {
    return { ...data, id: i };
  });
};
const createLayoutPhotoMaps = (layout, photos) => {
  const newPhotos = createPhotoIds(photos);
  const newMaps = {};
  layout.reduce((accumulator, currentValue, currentIndex) => {
    newMaps[currentIndex] = newPhotos.slice(
      accumulator,
      accumulator + currentValue
    );
    return accumulator + currentValue;
  }, 0);

  return newMaps;
};

const checkProps = (props) => {
  const defaultProps = {
    width: "800px",
    height: new Array(props.layout.length),
    layout: [],
    photos: [],
    showNumOfRemainingPhotos: false,
  };
  const newProps = { ...defaultProps, ...props };
  if (newProps.height.length < newProps.layout.length) {
    for (let i = 0; i < newProps.layout.length; i++) {
      newProps.height[i] = "200px";
    }
  }
  return newProps;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  padding: "20px 0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const ReactPhotoCollageContainer = (props) => {
  const currProps = useMemo(() => checkProps(props), [props]);
  const { width, height, layout, photos, showNumOfRemainingPhotos } = currProps;
  const layoutNum = layout.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const remainingNum = photos.length - layoutNum;
  const [allowRender, setAllowRender] = useState(false);
  const [layoutPhotoMaps, setLayoutPhotoMaps] = useState({});
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const onDownload = () => {
    const imageSrc = photos[currentImage]?.source;
    fetch(imageSrc, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFullscreen = useCallback(() => {
    document.documentElement.requestFullscreen();
  }, []);

  useEffect(() => {
    setLayoutPhotoMaps(createLayoutPhotoMaps(layout, photos));
  }, []);

  useEffect(() => {
    Object.keys(layoutPhotoMaps).length
      ? setAllowRender(true)
      : setAllowRender(false);
  }, [layoutPhotoMaps]);

  const openLightbox = useCallback((id) => {
    setCurrentImage(parseInt(id));
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = useCallback(() => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.key) {
        case "f":
          onFullscreen();
          break;

        default:
          // Handle other key presses if needed
          break;
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onFullscreen]);

  const renderDownloadButton = () => {
    return (
      <div
        className="p-1 cursor-pointer"
        style={{ color: "inherit" }}
        onClick={onDownload}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-download"
          viewBox="0 0 16 16"
        >
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
        </svg>
      </div>
    );
  };

  const renderFullScreenButton = () => {
    return (
      <div className="p-1 cursor-pointer" onClick={onFullscreen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          className="bi bi-fullscreen"
          viewBox="0 0 16 16"
        >
          <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
        </svg>
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <div className="p-1 cursor-pointer" onClick={closeLightbox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
      </div>
    );
  };

  if (allowRender) {
    return (
      <React.Fragment>
        <ReactPhotoCollageComponent
          width={width}
          height={height}
          layout={layout}
          layoutPhotoMaps={layoutPhotoMaps}
          layoutNum={layoutNum}
          remainingNum={remainingNum}
          showNumOfRemainingPhotos={showNumOfRemainingPhotos}
          openLightbox={openLightbox}
        />

        {viewerIsOpen ? (
          <Modal
            className="photoCollageModal"
            open={viewerIsOpen}
            onClose={closeLightbox}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{
              backdrop: {
                style: {
                  backgroundColor: "rgb(0,0,0,0.8) !important",
                  opacity: "1 !important",
                },
              },
            }}
            sx={{
              opacity: 1,
              "& div div.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop":
                {
                  opacity: "1 !important",
                },
            }}
          >
            <Box sx={style}>
              <div
                className="d-flex w-100 justify-content-end align-items-center gap-3 position-absolute top-0 p-2"
                style={{ color: "white" }}
              >
                {renderDownloadButton()}
                {renderFullScreenButton()}
                {renderCloseButton()}
              </div>
              <Carousel
                showArrows
                showStatus={false}
                showIndicators
                infiniteLoop
                showThumbs={false}
                useKeyboardArrows
                swipeable
                emulateTouch
                selectedItem={currentImage}
                onChange={(index, item) => {
                  setCurrentImage(index);
                }}
              >
                {photos.map((photo, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div>
                      <img
                        alt=""
                        src={photo.source}
                        style={{ objectFit: "cover", maxHeight: "80vh" }}
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </Box>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }

  return null;
};

export default ReactPhotoCollageContainer;
