import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";

import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import { useSelector } from "react-redux";
import Invalid from "../../../assets/Icon/invalid.svg";

const StaffAddress = ({
  data,
  userId,
  notification,
  AddressEdit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const selected = useSelector((state) => state.SelectedStudentReducer);
  const ParentMode = Util.getParentMode();

  const [addressLine1, setAddress1] = useState("");
  const [addressLine2, setAddress2] = useState("");
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [country, setCountry] = useState([""]);
  const [countryId, setCountryId] = useState(0);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [stateId, setStateId] = useState(0);
  const [state, setState] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCityText, setIsCityText] = useState("");
  const [isStateText, setIsStateText] = useState("");

  useEffect(() => {
    if (userId > 0) {
      if (data !== null) {
        setAddress1(data?.address1);
        setAddress2(data?.address2);
        setCityId(Util.isValidData(data?.city_id) ? data?.city_id : 0);
        setCountryId(Util.isValidData(data?.country_id) ? data?.country_id : 0);
        setStateId(Util.isValidData(data?.state_id) ? data?.state_id : 0);
        setZipCode(data?.zip);
        setSelectedCity(data?.City);
        setSelectedCountry(data?.Country);
        setSelectedState(data?.State);
        setIsStateText(data?.state);
        setIsCityText(data?.city);
      }
    }
  }, [data]);
  const handleSavePersonalDetails = (e) => {
    e.preventDefault();
    setZipCodeError("");
    let error = false;
    if (zipCode && Util.Zip_regex.test(zipCode) === false) {
      setZipCodeError(
        t("Please enter valid Pin-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", userId);
      if (addressLine1) {
        formData.append("address1", addressLine1);
      }
      if (addressLine2) {
        formData.append("address2", addressLine2);
      }

      if (cityId) {
        formData.append("city_id", cityId);
      }
      if (stateId) {
        formData.append("state_id", stateId);
      }
      if (isCityText) {
        formData.append("city", isCityText);
      }
      if (isStateText) {
        formData.append("state", isStateText);
      }
      if (countryId) {
        formData.append("country_id", countryId);
      }
      if (zipCode) {
        formData.append("zip", zipCode);
      }

      ApiCalling.apiCallBodyDataPost(
        "user/dynamic-update",
        formData,
        ParentMode && selected.selectedStudent.student.School.id
      )
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            notification(res.data.message);
            setZipCodeError("");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setIsCountryLoading(false);
      setCountry(res?.data);
    });
  };

  const stateList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setState(res?.data);
      }
    );
  };
  const cityList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };
  useEffect(() => {
    countryList();
  }, []);

  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  return (
    <>
      {!isLoading ? (
        <div className="row text-start mt-2">
          <div className="col-lg-6">
            <div className="input-filed">
              <span className="basic-text ">{t("Address 1")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(addressLine1) ? addressLine1 : "-"}
                </div>
              ) : (
                <>
                  <input
                    className="form-control custom-input"
                    value={addressLine1}
                    onChange={(e) => {
                      setAddress1(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text">{t("Address 2")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(addressLine2) ? addressLine2 : "-"}
                </div>
              ) : (
                <>
                  <input
                    className="form-control custom-input"
                    value={addressLine2}
                    onChange={(e) => {
                      setAddress2(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text">{t("Country")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(selectedCountry) ? selectedCountry : "-"}
                </div>
              ) : (
                <>
                  <select
                    name=""
                    id=""
                    className="select-dropdown  form-select custom-input"
                    value={countryId}
                    onChange={(e) => {
                      setCountryId(e.target.value);
                    }}
                  >
                    <option disabled value={0}>
                      {t("Select Country")}
                    </option>
                    {country?.map((item) => (
                      <option placeholder="grade" key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("State")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(isStateText) ? isStateText : "-"}
                </div>
              ) : (
                <>
                  {/* <select
                    className="select-dropdown form-select custom-input"
                    value={stateId}
                    onChange={(e) => {
                      setStateId(e.target.value);
                    }}
                    disabled={!countryId}
                  >
                    <option disabled value={0}>
                      {isCountryLoading ? t("Loading...") : t("Select State")}
                    </option>
                    {state?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>*/}
                  <input
                    type="text"
                    className="form-control custom-input"
                    value={isStateText}
                    onChange={(e) => {
                      setIsStateText(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("City")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(isCityText) ? isCityText : "-"}
                </div>
              ) : (
                <>
                  {/* <select
                    className="select-dropdown form-select custom-input"
                    value={cityId}
                    onChange={(e) => {
                      setCityId(e.target.value);
                    }}
                    disabled={!stateId}
                  >
                    <option disabled value={0}>
                      {isCountryLoading ? t("Loading...") : t("Select City")}
                    </option>
                    {city?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select> */}
                  <input
                    type="text"
                    className="form-control custom-input"
                    value={isCityText}
                    onChange={(e) => {
                      setIsCityText(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text">{t("ZIP Code")}</span>
              {!AddressEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(zipCode) ? zipCode : "-"}
                </div>
              ) : (
                <>
                  <input
                    className={`form-control custom-input ${
                      zipCodeError.length > 0 ? "input-error" : ""
                    }`}
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                      setZipCodeError("");
                    }}
                  />
                  {zipCodeError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {zipCodeError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          {AddressEdit ? (
            <div className="text-end mt-2">
              {userId > 0 && (
                <button
                  className="btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                  onClick={() => {
                    onCancel();
                    setZipCodeError("");
                  }}
                >
                  {t("Cancel")}
                </button>
              )}
              <button
                className=" btn primary-lg-btn rounded-btn"
                onClick={handleSavePersonalDetails}
              >
                {t("Save")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StaffAddress;
