import React from "react";
import fileUpload from "../../assets/images/fileUpload.png";

export default function CustomFileUpload({
  onChange,
  fileName,
  showFile,
  onDragOver,
  onDrop,
  alertMessage,
  acceptedDocument,
  accept,
  className,
}) {
  return (
    <div>
      <div tabIndex={0} className={className}>
        <div
          className="text-center"
          onDragOver={onDragOver}
          onDrop={onDrop}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("filePhoto").click();
          }}
        >
          <img src={fileUpload} className="img-responsive mt-4" />

          <div className="basic-text mt-2" style={{ fontWeight: "400" }}>
            Drag and drop your file here or{" "}
            <strong
              style={{ fontWeight: "600", color: "#6418C3", cursor: "pointer" }}
            >
              Browser
            </strong>
          </div>
          <div>{acceptedDocument}</div>
          <a
            target="_blank"
            style={{ fontWeight: "600", color: "#6418C3", cursor: "pointer" }}
            href={showFile}
          >
            {fileName}
          </a>
          <p className="small-text text-secondary mb-0 pb-3">{alertMessage}</p>
        </div>
        <input
          accept={accept}
          className="form-select sr-only"
          id="filePhoto"
          type="file"
          onChange={onChange}
        />
      </div>
    </div>
  );
}
