import React, { useEffect, useState } from "react";
import "../../../screens/Dashbord/DashboardCustom.css";
import Select from "react-dropdown-select";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import ToastHOC from "../../HOC/ToastHOC";
import { imageStudentList } from "../../CommonImage";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Sort from "../../../assets/Icon/Short.svg";
import TagButton from "../TagButton";
import ModalComponent from "../ModalComponent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Avatar } from "@mui/material";
import FatherIcon from "../../../assets/Icon/Father.svg";
import MotherIcon from "../../../assets/Icon/Mother.svg";
import Call from "../../../assets/Icon/call.svg";
import Search from "../../../assets/Icon/Search.svg";
import close from "../../../assets/Icon/close.svg";
import { greenTagStyle, orangeTagStyle } from "../TagButtonStyle";
import AddContactComponent from "./AddContactComponent";

const Student = (props) => {
  const { t } = useTranslation();
  const { setIsSubToast } = props;
  const { setIsToast } = props;
  const getLanguage = Util.getSelectedLanguage();
  const pendingToast = props.location.pendingToast;
  const [studentList, setStudentList] = useState([]);
  // const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [active, setActive] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [hasClassRoom, setHasClassRoom] = useState();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [selectedMultipleStatus, setSelectedMultipleStatus] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState();
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [openRoomPopup, setOpenRoomPopup] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [daysPerWeek, setDaysPerWeek] = useState([]);

  const location = useLocation();

  // let isStudentListMounted = true;
  // let isClassListMounted = true;
  // let isGradeListMounted = true;
  // let isCountryListMounted = true;
  // let isStateListMounted = true;
  // let isCityListMounted = true;

  const statusList = [
    { value: "all", title: "All" },
    { value: "true", title: "Active" },
    { value: "false", title: "Inactive" },
  ];

  const multipleStatusList = [
    { value: "true", title: "Change to Active" },
    { value: "false", title: "Change to InActive" },
  ];

  const DaysList = [
    // { value: "all", name: "All" },
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Thursday", name: "Thursday" },
    { value: "Friday", name: "Friday" },
  ];

  const getClassList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res?.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((grade) => ({
          id: grade.id,
          name: `${grade.Grade.title}-${grade.name}`,
        }));
      setClassList(modifiedClassList);
      setHasClassRoom(res.data.length);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  const getGradeList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  const getStudent = () => {
    if (search) {
      const queryParams = new URLSearchParams();
      queryParams.set("search", search);
      const currentUrl = window.location.pathname; // Get the current URL without query parameters
      const newUrl = `${currentUrl}?${queryParams.toString()}`;

      history.push(newUrl); // Update the URL without page reload
    }

    let data = {
      page_size: recordPerPage,
      page_number: currentPage,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      active: active,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
      weekdays: daysPerWeek.map((d) => d.value).join(","),
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
      setStudentList(res?.data?.data);
      // setFilteredStudentList(res?.data?.data);
      setTotalPages(res.data.totalPages);
      setTotalStudents(res.data.total);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    getStudent();
  }, [
    sortBy,
    sortOrder,
    recordPerPage,
    classId,
    gradeId,
    searchClick,
    currentPage,
    active,
    daysPerWeek,
  ]);
  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);

  useEffect(() => {
    getGradeList();
    getClassList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get("search");
    if (searchParam) {
      setSearch(searchParam);
      setSearchClick(searchParam);
    }
  }, [location.search]);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const changeDaysPerWeek = (data) => {
    setCurrentPage(1);
    setDaysPerWeek(data);
    // const selectAllDays = data.some((s)=>s.value == "all")
    // if (selectAllDays){
    //   setDaysPerWeek([])
    // }
  };

  const customRenderer = ({ props, state, methods }) => {
    const { values } = state;
    const selectedDays = values
      .map((value) => value.name.substring(0, 2))
      .join(", ");
    const hasSelectedValues = selectedDays.length > 0;
    return (
      <>
        <div
          className={` ${
            hasSelectedValues
              ? "react-dropdown-multi-selected"
              : "selected-weekDays"
          }`}
        >
          {selectedDays || props.placeholder}
          {hasSelectedValues && (
            <span
              className={`ml-2`}
              onClick={() => {
                methods.clearAll();
              }}
            >
              ×
            </span>
          )}
        </div>
      </>
    );
  };

  const changeClassRoom = (data) => {
    setCurrentPage(1);
    setClassId(data[0].id || 0);
  };
  const changeGrade = (data) => {
    setCurrentPage(1);
    setGradeId(data[0].id);
    setClassId(0);
  };

  const changeStatus = (data) => {
    setCurrentPage(1);
    setActive(data[0].value);
  };

  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.id));
    } else {
      setSelectedStudentId([]);
    }
  };

  const StudentChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
  };
  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const changeSelectedMultipleStatus = (data) => {
    setSelectedMultipleStatus(data[0].value);
    setOpenStatusPopup(true);
  };

  const changeSelectedRoom = (data) => {
    setSelectedRoom(data[0].id);
    setOpenRoomPopup(true);
  };

  const CircleLetter = ({ letter, isTrue }) => {
    const circleClassName = `circle ${isTrue ? "circle-checked" : ""}`;
    const letterClassName = `letter ${isTrue ? "letter-checked" : ""}`;
    return (
      <div className={circleClassName}>
        <object className={letterClassName}>{letter[0]}</object>
      </div>
    );
  };

  const weekdays = ["M", "TU", "W", "TH", "F"];

  const handleConfirmStatus = () => {
    const students = selectedStudentId.join(",");

    const data = {
      student_ids: students,
      status: selectedMultipleStatus,
    };
    ApiCalling.apiCallBodyDataPost("student/update-students-status", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
          setOpenStatusPopup(false);
          setSelectedStudentId([]);
          setSelectedMultipleStatus();
          getStudent();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleConfirmRoom = () => {
    const students = selectedStudentId.join(",");
    const data = {
      student_ids: students,
      classroom_id: selectedRoom,
    };
    ApiCalling.apiCallBodyDataPost("student/update-students-classroom", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
          setOpenRoomPopup(false);
          setSelectedStudentId([]);
          setSelectedRoom();
          getStudent();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isAllSelected = () => {
    return studentList.length === selectedStudentId.length;
  };
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row py-3">
              <div className="col-md-4 text-start">
                <h2 className="page-head">{t("My Students")}</h2>
              </div>
              <div
                className={`col-md-8  ${getLanguage !== "ar" && "text-end"}`}
              >
                {selectedStudentId.length > 0 ? (
                  <div className={"rows-selected dropdown-height"}>
                    <Select
                      className="select-custom"
                      options={[...multipleStatusList]}
                      placeholder="Change Student Status"
                      onChange={(values) =>
                        changeSelectedMultipleStatus(values)
                      }
                      searchable={false}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[
                        {
                          value: "",
                          title: "Change Student Status",
                        },
                      ]}
                      valueField="value"
                    />
                    <Select
                      className="select-custom"
                      options={[...classList]}
                      placeholder="Change Student Room"
                      searchable={false}
                      onChange={(values) => changeSelectedRoom(values)}
                      style={{ width: "100%" }}
                      labelField="name"
                      values={[{ id: 0, name: "Change Student Room" }]}
                      valueField="id"
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <button
                      className={`${
                        Util.hasPermission("STUDENT_ADD_BULK")
                          ? "btn secondary-sm-btn"
                          : "no-permission"
                      }`}
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: "/BulkStudent",
                          hasClassRoom: hasClassRoom,
                        });
                      }}
                    >
                      {t("Add Student in Bulk")}
                    </button>
                    <button
                      className={`${
                        Util.hasPermission("STUDENT_ADD")
                          ? "btn primary-sm-btn"
                          : "no-permission"
                      }`}
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: "/new-student",
                          studentId: -1,
                        });
                      }}
                    >
                      {t("Add New Student")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className={"table-div"}>
              <div className="d-flex gap-20 align-items-center table-sort-bar">
                <div className="custom-search-wrap">
                  <div className="input-group">
                    <input
                      className="form-control search "
                      placeholder={t("Search Student")}
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyPress={(e) => EnterHandler(e)}
                    />
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img
                          src={search.length > 0 ? close : Search}
                          className="svg-icon"
                          alt=""
                          onClick={searchClicked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mr-2 ">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    options={[...statusList]}
                    onChange={(values) => changeStatus(values)}
                    style={{ width: "100%" }}
                    labelField="title"
                    values={[{ value: "all", title: "Status" }]}
                    valueField="value"
                  />
                </div>

                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    placeholder="Grade"
                    options={[{ id: 0, title: "All" }, ...gradeList]}
                    onChange={(values) => changeGrade(values)}
                    style={{ width: "100%" }}
                    labelField="title"
                    values={[{ id: 0, title: "Grade" }]}
                    valueField="id"
                  />
                </div>

                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    placeholder="Rooms"
                    options={[{ id: 0, name: "All" }, ...classList]}
                    onChange={(values) => changeClassRoom(values)}
                    style={{ width: "100%" }}
                    labelField="name"
                    values={[{ id: 0, name: "Rooms" }]}
                    valueField="id"
                  />
                </div>
                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom selected-weekDays text-nowrap"
                    placeholder="Day Per Week"
                    options={[...DaysList]}
                    onChange={(values) => changeDaysPerWeek(values)}
                    style={{ width: "100%" }}
                    labelField="name"
                    valueField="value"
                    multi
                    contentRenderer={customRenderer}
                  />
                </div>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-wrapper">
                      <table className="table">
                        <thead>
                          <tr className="text-nowrap">
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                paddingRight: "0px",
                                width: 0,
                              }}
                            >
                              <label className="fancy-checkbox element-left">
                                <input
                                  type="checkbox"
                                  name="allselect"
                                  onChange={handleAllSelectedStudent}
                                  checked={
                                    studentList.length > 0 &&
                                    studentList.length ===
                                      selectedStudentId.length
                                  }
                                />
                                <span></span>
                              </label>
                            </th>
                            <th
                              scope="col"
                              style={{
                                paddingLeft: "0px",
                                width: 0,
                              }}
                            >
                              {t("Students Name")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.first_name");
                                }}
                              />
                            </th>
                            {/* <th scope="col"> {t("Primary Contact")} </th> */}
                            <th scope="col">
                              {t("Student ID")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.id");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Phone Number")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.contact");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Grade")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("grade.title");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Room")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("classroom.name");
                                }}
                              />
                            </th>
                            <th scope="col">{t("Day Per Week")}</th>
                            <th scope="col">
                              {t("Status")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student.active");
                                }}
                              />
                            </th>
                            {/* <th scope="col"> {t("Recent activity")}</th> */}
                          </tr>
                        </thead>

                        {studentList.length > 0 ? (
                          <tbody>
                            {studentList.map((item) => {
                              const dayBooleanValues = {
                                M: item.monday,
                                TU: item.tuesday,
                                W: item.wednesday,
                                TH: item.thursday,
                                F: item.friday,
                              };
                              return (
                                <tr
                                  key={item.id}
                                  style={{
                                    backgroundColor: `${
                                      selectedStudentId.includes(item.id)
                                        ? "rgba(100, 24, 195, 0.04)"
                                        : ""
                                    }`,
                                  }}
                                >
                                  <td
                                    style={{
                                      paddingRight: "0px",
                                      width: 0,
                                    }}
                                  >
                                    <label className="fancy-checkbox element-left">
                                      <input
                                        type="checkbox"
                                        name={item.first_name}
                                        onChange={StudentChecked}
                                        value={item.id}
                                        checked={selectedStudentId.includes(
                                          item.id
                                        )}
                                      />
                                      <span></span>
                                    </label>
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "0px",
                                      width: 0,
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {item.photo ? (
                                        <img
                                          className="rounded-circle "
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          src={imageStudentList + item.photo}
                                          alt=""
                                        />
                                      ) : (
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-font"
                                        >
                                          {item.first_name.toUpperCase()[0] +
                                            item.last_name.toUpperCase()[0]}
                                        </Avatar>
                                      )}
                                      <a
                                        className="ml-3"
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          const queryParams =
                                            new URLSearchParams();
                                          queryParams.set("studentId", item.id);

                                          // Construct the URL with the query parameter
                                          const newUrl = `/profileTabs?${queryParams.toString()}`;

                                          history.push({
                                            pathname: "/profileTabs",
                                            search: `?${queryParams.toString()}`,
                                            studentId: item.id,
                                            hasClassRoom: hasClassRoom,
                                          });
                                        }}
                                        style={{ fontWeight: "600" }}
                                      >
                                        {item.first_name} {item.last_name}
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <span>{Util.studentIdFormat(item.id)}</span>
                                  </td>
                                  <td>
                                    {item?.primaryParent ? (
                                      <>
                                        <div>
                                          <img
                                            src={
                                              item?.primaryParent
                                                ?.student_parent_relation_type_id ==
                                              1
                                                ? FatherIcon
                                                : MotherIcon
                                            }
                                            alt=""
                                            width={"20px"}
                                            className="mr-1"
                                          />
                                          {item?.primaryParent?.User
                                            ?.firstName +
                                            " " +
                                            item?.primaryParent?.User?.lastName}
                                        </div>
                                        <div>
                                          {item?.primaryParent?.User
                                            ?.mobile && (
                                            <img
                                              src={Call}
                                              alt=""
                                              width={"16px"}
                                              className="mr-1"
                                            />
                                          )}
                                          {item?.primaryParent?.User?.mobile}
                                        </div>
                                      </>
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setAddContact(true);
                                          setStudentId(item.id);
                                          // history.push({
                                          //   pathname: "/profileTabs",
                                          //   studentId: item.id,
                                          //   contact: "primary",
                                          //   hasClassRoom: hasClassRoom,
                                          // });
                                        }}
                                        className="link-button"
                                        style={{ fontWeight: "600" }}
                                      >
                                        <i
                                          className="fas fa-plus mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Add Contact")}
                                      </a>
                                    )}
                                  </td>
                                  <td>{item?.grade?.title}</td>
                                  <td>{item?.classroom?.name}</td>
                                  <td>
                                    <div className="circle-container">
                                      {weekdays.map((letter, index) => (
                                        <CircleLetter
                                          key={index}
                                          letter={letter}
                                          isTrue={dayBooleanValues[letter]}
                                        />
                                      ))}
                                    </div>
                                  </td>
                                  <td>
                                    <TagButton
                                      size={"100px"}
                                      title={
                                        item.active ? "Active" : "InActive"
                                      }
                                      buttonStyle={
                                        item.active
                                          ? greenTagStyle
                                          : orangeTagStyle
                                      }
                                      icon="fa fa-circle"
                                      iconSize="6px"
                                    />
                                  </td>
                                  {/* <td>
                                      {item?.lastActivityTime
                                        ? moment(
                                            item?.lastActivityTime
                                          ).fromNow()
                                        : ""}
                                    </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={8}>
                                {t(
                                  "No Students Found! Please recheck the filter or Create One"
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                    {totalPages > 0 && (
                      <div className="row m-0 table-footer border-top">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ height: "72px" }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="basic-text">
                              {t("Students Per Page")}
                            </div>
                            <select
                              style={{ width: "72px", cursor: "pointer" }}
                              className="select-dropdown custom-input"
                              value={recordPerPage}
                              onChange={(e) => {
                                setRecordPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>

                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <div
                              className={`link-button ${
                                currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePreviousClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <KeyboardArrowLeftIcon />
                              <span className="basic-bold-text">
                                {t("Previous")}
                              </span>
                            </div>
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={(event, page) => {
                                setCurrentPage(page);
                              }}
                              size="small"
                              hideNextButton
                              hidePrevButton
                            />
                            <div
                              className={`link-button ${
                                currentPage == totalPages ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNextClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="basic-bold-text">
                                {t("Next")}
                              </span>
                              <KeyboardArrowRightIcon />
                            </div>
                          </Stack>
                        </div>
                      </div>
                    )}
                  </div>
                  <ModalComponent
                    show={openStatusPopup}
                    onHide={() => {
                      setOpenStatusPopup(false);
                      setSelectedStudentId([]);
                      setSelectedMultipleStatus();
                    }}
                    title={t("Change Student status")}
                    subTitle={t(
                      "Are you sure want to change student status to active"
                    )}
                    // size="sm"
                    onCancel={t("Cancel")}
                    onConfirm={t("Confirm")}
                    onCancelClick={() => {
                      setOpenStatusPopup(false);
                      setSelectedStudentId([]);
                      setSelectedMultipleStatus();
                    }}
                    onConfirmClick={handleConfirmStatus}
                  />
                  <ModalComponent
                    show={openRoomPopup}
                    onHide={() => {
                      setOpenRoomPopup(false);
                      setSelectedStudentId([]);
                      setSelectedRoom();
                    }}
                    title={t("Change Student Room")}
                    subTitle={t("Are you sure want to change student Room")}
                    // size="sm"
                    onCancel={t("Cancel")}
                    onConfirm={t("Confirm")}
                    onCancelClick={() => {
                      setOpenRoomPopup(false);
                      setSelectedStudentId([]);
                      setSelectedRoom();
                    }}
                    onConfirmClick={handleConfirmRoom}
                  />
                  <AddContactComponent
                    show={addContact}
                    onSave={() => {
                      setAddContact(false);
                      getStudent();
                    }}
                    studentId={studentId}
                    notification={(msg) => {
                      if (msg) {
                        setAddContact(false);
                        setIsToast(msg);
                      }
                    }}
                    onClose={() => {
                      setAddContact(false);
                    }}
                    AddPrimary={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(Student);
