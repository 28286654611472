import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RecaptchaWrapper = ({ path, children }) => {
  const recaptchaPaths = [
    "/",
    "/login",
    "/verifyEmailAddress",
    "/resetPassword",
    "/registration",
    "/forgotpassword",
  ];

  if (recaptchaPaths.includes(path)) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        useEnterprise={true}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  } else {
    return <>{children}</>;
  }
};

export default RecaptchaWrapper;
