import React, { useEffect, useState } from "react";
import groupIcon from "../../assets/Icon/GroupIcon.svg";
import selectedGroupIcon from "../../assets/Icon/selectedGroup (2).svg";
import Util from "../../Util";
import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader";

export default function ParentsGroupList(props) {
  const { t } = useTranslation();
  const groupUsers = props?.GroupList;
  console.log("groupUsers", groupUsers);
  const searchClick = props?.searchGroupClick;
  const [selectedGroupId, setSelectedGroupId] = useState(
    props?.selectedGroupId
  );
  // const [groupUsers, setGroupUsers] = useState([]);

  function createRoom(user1) {
    setSelectedGroupId(user1.id);
    props.startChat(user1);
  }
  return (
    <div>
      <div>
        {groupUsers != undefined && groupUsers.length > 0 ? (
          <ul className="list-unstyled chat-list mt-2 mb-0">
            {groupUsers
              .sort((a, b) => {
                if (a.unread > 0 || b.unread > 0)
                  return b.unread > a.unread ? 1 : -1;
                else if (a.last_message_on !== "" && b.last_message_on !== "") {
                  return new Date(b.last_message_on) >
                    new Date(a.last_message_on)
                    ? 1
                    : -1;
                } else if (
                  a.last_message_on === "" &&
                  b.last_message_on === ""
                ) {
                  return a.name < b.name ? -1 : 1;
                } else {
                  return b.last_message_on.length - a.last_message_on.length;
                }
              })

              .map((item) => {
                return (
                  <>
                    <li
                      key={item.id}
                      className={selectedGroupId == item?.id ? "active" : ""}
                    >
                      <a
                        onClick={() => {
                          createRoom(item);
                        }}
                        className="d-flex"
                      >
                        <img
                          src={
                            selectedGroupId == item?.id
                              ? selectedGroupIcon
                              : groupIcon
                          }
                          height={40}
                          width={40}
                        />
                        <div
                          className={`about ${item.unread > 0 ? "unread" : ""}`}
                        >
                          <div className="last-message-time">
                            {Util.getDateDisplay_ChatMessage(
                              item.last_message_on
                            )}
                          </div>
                          <div className="name">{item.name}</div>
                          <div className="d-flex justify-content-between">
                            <div
                              className="last-message"
                              style={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                                maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                              }}
                            >
                              {Util.isValidData(item.last_message) ? (
                                <>
                                  {item.sent_by.firstName} : {item.last_message}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </>
                );
              })}
          </ul>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
