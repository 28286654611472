import React, { useCallback, useEffect, useRef, useState } from "react";
import ToastHOC from "../../../HOC/ToastHOC";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../../Util";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/common/Loader";
import BreadCrumbComponent from "../../../../components/BreadCrumbComponent";
import StaffStepper from "./StaffStepper";
import DatePicker from "react-datepicker";
import Calander from "../../../../assets/Icon/calendar.svg";
import ApiCalling from "../../../../network/ApiCalling";
import Invalid from "../../../../assets/Icon/invalid.svg";
import CustomFileUpload from "../../CustomFileUpload";
import PhoneInput from "react-phone-number-input";
import "react-datepicker/dist/react-datepicker.css";
import StaffAvtar from "../../../../assets/Icon/Parent-avtar.svg";
import camera from "../../../../assets/Icon/camera-icon.svg";
import EditPhotoModal from "../../EditPhotoModal";
import ExitAlert from "../../../../components/ExitAlert";

function AddNewStaff(props) {
  const { setIsToast } = props;
  const { setIsSubToast } = props;
  const pendingToast = props.location.pendingToast;
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [indexPage, setIndexPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //   step 1
  const datepickerRef = useRef(null);
  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [dob, setDob] = useState(null);
  const [age, setAge] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNumber, setContactNumber] = useState(null);
  const [contactNumberError, setContactNumberError] = useState("");
  const [raceId, setRaceId] = useState(0);
  const [ethnicityId, setEthnicityId] = useState(0);
  const [nationalityId, setNationalityId] = useState(0);
  const [raceList, setRaceList] = useState([]);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [country, setCountry] = useState([]);
  const [identityUpload, setIdentityUpload] = useState("");
  const [identityURL, setIdentityURL] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");

  //   step 2
  const [addressLine1, setAddress1] = useState("");
  const [addressLine2, setAddress2] = useState("");
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [stateId, setStateId] = useState(0);
  const [state, setState] = useState([]);
  const [isCity, setIsCity] = useState("");
  const [isState, setIsState] = useState("");

  //   step 3
  const [roleId, setRoleId] = useState(0);
  const [roleIdError, setRoleIdError] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [notes, setNotes] = useState("");
  const [preferredLanguageId, setPreferredLanguageId] = useState(0);
  const [preferredLanguageList, setPreferredLanguageList] = useState([]);

  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const steps = [
    {
      label: `${t("Personal Information")}`,
    },
    {
      label: `${t("Address Information")}`,
    },
    {
      label: `${t("School Information")}`,
    },
  ];
  const handleNext = () => {
    if (indexPage === 0) {
      step1();
    }
    if (indexPage === 1) {
      step2();
    }
    if (indexPage === 2) {
      step3();
    }
  };

  const step1 = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setEmailError("");
    setContactNumberError("");
    let error = false;
    if (firstName == "") {
      setFirstNameErr(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameErr(t("Last Name is required"));
      error = true;
    }
    if (email == "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (contactNumber == undefined || contactNumber == null) {
      setContactNumberError(t("Phone is required"));
      error = true;
    }
    if (!error) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIndexPage((indexPage) => indexPage + 1);
    }
  };

  const step2 = () => {
    setZipCodeError("");
    let error = false;
    if (zipCode && Util.Zip_regex.test(zipCode) === false) {
      setZipCodeError(
        t("Please enter valid Pin-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIndexPage((indexPage) => indexPage + 1);
    }
  };
  const step3 = () => {
    setContactNumberError("");
    setRoleIdError("");
    let error = false;

    if (roleId === 0) {
      setRoleIdError(t("Staff Type is required"));
      error = true;
    }

    if (contactNumber == undefined || contactNumber == null) {
      setContactNumberError(t("Phone is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();
      if (photo) {
        formData.append("avatar", photo);
      }
      if (firstName) {
        formData.append("firstName", firstName);
      }
      if (lastName) {
        formData.append("lastName", lastName);
      }
      if (dob) {
        formData.append("dob", dob.toISOString());
      }
      if (email) {
        formData.append("email", email);
      }
      if (contactNumber) {
        formData.append("mobile", contactNumber);
      }
      if (raceId) {
        formData.append("race_id", raceId);
      }
      if (nationalityId) {
        formData.append("nationality_id", nationalityId);
      }
      if (ethnicityId) {
        formData.append("ethnicity_id", ethnicityId);
      }
      if (identityUpload) {
        formData.append("identity", identityUpload);
      }
      if (addressLine1) {
        formData.append("address1", addressLine1);
      }
      if (addressLine2) {
        formData.append("address2", addressLine2);
      }
      if (cityId) {
        formData.append("city_id", cityId);
      }
      if (stateId) {
        formData.append("state_id", stateId);
      }
      if (isCity) {
        formData.append("city", isCity);
      }
      if (isState) {
        formData.append("state", isState);
      }
      if (countryId) {
        formData.append("country_id", countryId);
      }
      if (zipCode) {
        formData.append("zip", zipCode);
      }
      if (roleId) {
        formData.append("role_id", roleId);
      }
      if (preferredLanguageId) {
        formData.append("preferred_language_id", preferredLanguageId);
      }
      if (notes) {
        formData.append("notes", notes);
      }
      ApiCalling.apiCallBodyDataPost("user/create-staff-user", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setIsStateUpdated(false);
            setTriggerExit({
              path: "/staff",
              onOk: true,
            });
            history.push({
              pathname: "/staff",
              pendingToast: `${firstName + " " + lastName} ${t(
                "registered successfully"
              )}`,
            });
            reset();
          } else {
            setIsToast(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const reset = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setEmailError("");
    setContactNumberError("");
    setZipCodeError("");
    setRoleIdError("");
  };

  const handleBack = () => {
    if (indexPage === 0) {
      history.push("/staff");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIndexPage((indexPage) => indexPage - 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setIndexPage(0);
  };

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }

    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
    setIsStateUpdated(true);
  };
  const handleSavePhoto = (e) => {
    setShowEditModal(false);
  };

  const handleIdentityDragOver = (e) => {
    e.preventDefault();
  };

  const handleIdentityDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setIdentityUpload(file);
        setIdentityURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 25MB"));
      }
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input ref={ref} className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const handleIdentityUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
    setIsStateUpdated(true);
  };

  const changeDate = (date) => {
    setDob(date);
    const calculatedAge = Util.calculateAge(date);
    setAge(calculatedAge);
    setIsStateUpdated(true);
  };
  const getRaceList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("race")
      .then((res) => {
        setIsLoading(false);
        setRaceList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ethnicitylist = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("ethnicity")
      .then((res) => {
        setIsLoading(false);
        setEthnicityList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCountry = async () => {
    ApiCalling.apiCallBodyDataGet("country/list")
      .then((res) => {
        setCountry(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const LanguageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("preferred-language/list")
      .then((res) => {
        setIsLoading(false);
        setPreferredLanguageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRoleList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("role/list")
      .then((res) => {
        setIsLoading(false);
        setRoleList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const stateList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setState(res?.data);
      }
    );
  };
  const cityList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };
  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  useEffect(() => {
    getCountry();
    getRaceList();
    ethnicitylist();
    LanguageList();
    getRoleList();
  }, []);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/new-staff" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <div>
      {!isLoading ? (
        <>
          <div className="container">
            <div className="row mb-2 py-4">
              <BreadCrumbComponent
                Breadcrumb={[
                  {
                    name: "My School",
                    navigate: () => {
                      history.push("/Dashboard");
                    },
                  },
                  {
                    name: "Staff List",
                    navigate: () => {
                      history.push("/staff");
                    },
                  },
                  { name: `${t("Add New Staff")}` },
                ]}
              />
              <div className="col-lg">
                <h2 className="page-head text-center">{t("Add New Staff")}</h2>
              </div>
            </div>
            <div className="row mt-0 mb-3">
              <div className="col-lg-3">
                <StaffStepper
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleReset={handleReset}
                />
              </div>
              <div className="col-lg-6">
                {indexPage === 0 && (
                  <>
                    <div className="form-wrapper">
                      <h5 className="drawer-sub-header">
                        {t("Personal Information")}
                      </h5>
                      <div
                        className="photo-picker"
                        style={{ position: "relative", marginBottom: "25px" }}
                      >
                        <p className="text-start">{t("Staff's Photo")}</p>
                        {photoURL.length > 0 ? (
                          <div
                            className="profile-area"
                            style={{
                              position: "relative",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="User"
                              className="rounded-circle user-photo img-responsive  mt-1 "
                              src={photoURL}
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />{" "}
                            <span
                              className="choose-profile"
                              onClick={(e) => {
                                e.preventDefault();
                                // document.getElementById("photo").click();
                                setShowEditModal(true);
                              }}
                            >
                              <img src={camera} alt="" />
                            </span>
                          </div>
                        ) : (
                          <div
                            className="profile-area"
                            style={{
                              position: "relative",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="User"
                              className="img-responsive  mt-1"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                position: "relative",
                              }}
                              src={StaffAvtar}
                            />
                            <span
                              className="choose-profile"
                              onClick={(e) => {
                                e.preventDefault();
                                document.getElementById("photo").click();
                              }}
                            >
                              <img src={camera} alt="" />
                            </span>

                            <input
                              className="sr-only"
                              id="photo"
                              type="file"
                              accept="image/*"
                              onChange={handlePhotoChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row  text-start">
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("First Name")}
                          </span>

                          <input
                            className={`form-control custom-input ${
                              firstNameErr.length > 0 ? "input-error" : ""
                            }`}
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setFirstNameErr("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {firstNameErr.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {firstNameErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Last Name")}
                          </span>

                          <input
                            className={`form-control custom-input ${
                              lastNameErr.length > 0 ? "input-error" : ""
                            }`}
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              setLastNameErr("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {lastNameErr.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {lastNameErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">
                            {t("Date of Birth")}
                          </span>

                          <DatePicker
                            ref={datepickerRef}
                            placeholderText="Select Date"
                            selected={dob}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={changeDate}
                            dateFormat={school.date_format}
                            className="datePicker"
                            popperPlacement="bottom"
                            maxDate={new Date()}
                            customInput={<CustomInput />}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Age")}</span>

                          <div className="large-bold-text mt-1 ml-2">
                            {Util.isValidData(dob) ? (
                              <>
                                {age?.years} {t("years")} {age?.months}{" "}
                                {t("months")}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Email")}
                          </span>

                          <input
                            className={`form-control custom-input ${
                              emailError.length > 0 ? "input-error" : ""
                            }`}
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {emailError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {emailError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Phone Number")}
                          </span>

                          <div
                            className={`form-control custom-input ${
                              contactNumberError.length > 0 ? "input-error" : ""
                            }`}
                            tabIndex={0}
                          >
                            <PhoneInput
                              tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={contactNumber}
                              onChange={(value) => {
                                setContactNumber(value);
                                setContactNumberError("");
                                setIsStateUpdated(true);
                              }}
                            />
                          </div>
                          {contactNumberError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {contactNumberError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Race")}</span>

                          <select
                            name=""
                            id=""
                            className="select-dropdown form-select custom-input"
                            value={raceId}
                            onChange={(e) => {
                              setRaceId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Race")}
                            </option>
                            {raceList.map((item) => (
                              <option
                                placeholder="race"
                                key={item.id}
                                value={item.id}
                              >
                                {item.race}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Nationality")}</span>

                          <select
                            className="select-dropdown form-select custom-input"
                            value={nationalityId}
                            onChange={(e) => {
                              setNationalityId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Nationality")}
                            </option>
                            {country?.map(
                              (item) =>
                                item.nationality && (
                                  <option
                                    placeholder="grade"
                                    key={item.id}
                                    value={item.id}
                                  >
                                    {item.nationality}
                                  </option>
                                )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Ethnicity")}</span>

                          <select
                            name=""
                            id=""
                            className="select-dropdown form-select custom-input"
                            value={ethnicityId}
                            onChange={(e) => {
                              setEthnicityId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Ethnicity")}
                            </option>
                            {ethnicityList?.map((item) => (
                              <option
                                placeholder="grade"
                                key={item.id}
                                value={item.id}
                              >
                                {item.ethnicity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className={"col-lg-12"}>
                        <div className="input-filed ">
                          <span className="basic-text ">{t("ID Upload")}</span>
                          <CustomFileUpload
                            onChange={handleIdentityUpload}
                            fileName={identityUpload.name}
                            showFile={identityURL.length > 0 ? identityURL : ""}
                            onDragOver={handleIdentityDragOver}
                            onDrop={handleIdentityDrop}
                            acceptedDocument={t("PDF,Doc (max file 25mb)")}
                            accept={"application/pdf,.docs,.docx"}
                            className={"custom-file"}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {indexPage === 1 && (
                  <>
                    <div className="row  text-start mt-3">
                      <h5 className="drawer-sub-header mb-3">
                        {t("Address Information")}
                      </h5>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Address 1")}</span>

                          <input
                            className="form-control custom-input"
                            value={addressLine1}
                            onChange={(e) => {
                              setAddress1(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Address 2")}</span>

                          <input
                            className="form-control custom-input"
                            value={addressLine2}
                            onChange={(e) => {
                              setAddress2(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Country")}</span>

                          <select
                            name=""
                            id=""
                            className="select-dropdown  form-select custom-input"
                            value={countryId}
                            onChange={(e) => {
                              setCountryId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Country")}
                            </option>
                            {country?.map((item) => (
                              <option
                                placeholder="grade"
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("State")}</span>

                          {/* <select
                            className="select-dropdown form-select custom-input"
                            value={stateId}
                            onChange={(e) => {
                              setStateId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                            disabled={!countryId}
                          >
                            <option value={0}>
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select State")}
                            </option>
                            {state?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </select> */}
                           <input
                            className="form-control custom-input"
                            value={isState}
                            onChange={(e) => {
                              setIsState(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("City")}</span>
                          <input
                            className="form-control custom-input"
                            value={isCity}
                            onChange={(e) => {
                              setIsCity(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          />
                          {/* <select
                            className="select-dropdown form-select custom-input"
                            value={cityId}
                            onChange={(e) => {
                              setCityId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                            disabled={!stateId}
                          >
                            <option disabled value={0}>
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select City")}
                            </option>
                            {city?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select> */}

                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("ZIP Code")}</span>

                          <input
                            className={`form-control custom-input ${
                              zipCodeError.length > 0 ? "input-error" : ""
                            }`}
                            value={zipCode}
                            onChange={(e) => {
                              setZipCode(e.target.value);
                              setZipCodeError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {zipCodeError.length > 0 && (
                            <span className="invalid-error text-danger">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {zipCodeError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {indexPage === 2 && (
                  <>
                    <div className="row text-start mt-3">
                      <h5 className="drawer-sub-header mb-3">
                        {t("School Information")}
                      </h5>

                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Staff Type")}
                          </span>

                          <select
                            name=""
                            id=""
                            className={`select-dropdown form-select ${
                              roleIdError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            value={roleId}
                            onChange={(e) => {
                              setRoleId(e.target.value);
                              setRoleIdError("");
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Staff Type")}
                            </option>
                            {roleList?.map((item) => (
                              <option
                                placeholder="role"
                                key={item.id}
                                value={item.id}
                              >
                                {item.role}
                              </option>
                            ))}
                          </select>
                          {roleIdError.length > 0 && (
                            <span className="invalid-error text-danger">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {roleIdError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">
                            {t("Preferred Language")}
                          </span>

                          <select
                            name=""
                            id=""
                            className="select-dropdown form-select custom-input"
                            value={preferredLanguageId}
                            onChange={(e) => {
                              setPreferredLanguageId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option value={0}>
                              {t("Select Preferred Language")}
                            </option>
                            {preferredLanguageList?.map((item) => (
                              <option
                                placeholder="Language"
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Phone Number")}
                          </span>

                          <div
                            className={`form-control ${
                              contactNumberError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            tabIndex={0}
                          >
                            <PhoneInput
                              tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={contactNumber}
                              onChange={(value) => {
                                setContactNumber(value);
                                setContactNumberError("");
                                setIsStateUpdated(true);
                              }}
                            />
                          </div>
                          {contactNumberError.length > 0 && (
                            <span className="text-danger mt-2">
                              <i
                                className="fa fa-exclamation-circle mr-2"
                                aria-hidden="true"
                              ></i>
                              {contactNumberError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={"col-lg-12"}>
                        <div className="input-filed ">
                          <span className="basic-text">
                            {t("Special Notes")}
                          </span>

                          <textarea
                            style={{ width: "100%", minHeight: "120px" }}
                            className="form-control custom-input"
                            value={notes}
                            onChange={(e) => {
                              setNotes(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {indexPage !== steps.length && (
                  <>
                    <div className="row">
                      <div className="d-flex  align-items-center mt-3 justify-content-between">
                        <div>
                          <button
                            className="btn secondary-lg-btn"
                            onClick={handleBack}
                          >
                            {indexPage === 0 ? t("Cancel") : t("Previous")}
                          </button>
                        </div>

                        <div>
                          <button
                            className="btn primary-btn "
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("Finish")
                              : t("Continue")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <EditPhotoModal
              show={showEditModal}
              onClose={() => {
                setShowEditModal(false);
                setPhoto("");
                setPhotoURL("");
              }}
              title={t("Edit Photo")}
              subTitle={t("Show profile picture the best version of yourself")}
              onChange={t("Change Photo")}
              onSave={t("Save Photo")}
              ProfilePhoto={StaffAvtar}
              photoURL={photoURL}
              handlePhotoChange={handlePhotoChange}
              handleSavePhoto={handleSavePhoto}
            />
            <ExitAlert
              visible={alertModal}
              onClose={() => setIsAlertModal(false)}
              onOk={() => {
                setTriggerExit((obj) => ({
                  ...obj,
                  onOk: true,
                }));
                setIsAlertModal(false);
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
export default ToastHOC(AddNewStaff);
