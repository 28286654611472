import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import VerticalTabs from "./VerticalTabs";
import PropTypes from "prop-types";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MealPlanList from "../MealPlan/MealPlanList";
import PickupInfoList from "../PickupInfo/PickupInfoList";
import Role from "../Permission/Role";
import TimingList from "../Timing/TimingList";
import ActivityCategoryList from "../ActivityCategoryList/ActivityCategoryList";
import Subject from "../Subject/Subject";
import Room from "../ClassRoom/room";
import FeeTypes from "../Fee Types/FeeTypes";
import Terms from "../Terms/Terms";
import Group from "../Group/Group";
import ToastHOC from "../../HOC/ToastHOC";
import LeaveRequestType from "../LeaveRequestType/LeaveRequestType";
import { useDispatch, useSelector } from "react-redux";
import { switchTab } from "../../../actions/SwitchTabAction";
import AnnouncementType from "../Announcement Type/AnnouncementType";
import PaymentInfo from "../PaymentInfo/PaymentInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, m: 1 }} style={{ fontFamily: "Public Sans" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Settings = (props) => {
  const isUpdate = useSelector((state) => state.SwitchTabReducer);
  const dispatch = useDispatch();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (pendingToast) {
      setIsToast(pendingToast);
    }
  }, []);
  useEffect(() => {
    if (isUpdate.isSwitch === true) {
      setValue(2);
    }
    return dispatch(switchTab(false));
  }, [isUpdate.isSwitch]);
  return (
    <div className="container-fluid">
      <div className="body mx-4">
        <div className="row ">
          <div className="col-lg">
            <h2 className="page-head mt-3 position-fixed">{t("Settings")}</h2>
          </div>
        </div>
        <div className="row ">
          <div className="col-2 p-0 m-0 tablet-specific-col">
            <VerticalTabs
              handleChange={handleChange}
              value={value}
              setValue={setValue}
              a11yProps={a11yProps}
              TabPanel={TabPanel}
            />
          </div>
          <div className="col-9 offset-1 p-0 m-0 mt-3 tablet-specific">
            <TabPanel value={value} index={0}>
              <MealPlanList />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PickupInfoList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Role />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TimingList />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ActivityCategoryList />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Subject />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Room />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <FeeTypes />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Terms />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <Group />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <LeaveRequestType />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <AnnouncementType />
            </TabPanel>
            <TabPanel value={value} index={12}>
              <PaymentInfo />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(Settings);
