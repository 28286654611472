import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-number-input/style.css";
import moment from "moment/moment";
import Loader from "../../../components/common/Loader";

const SchoolDetailsCard = ({
  data,
  studentId,
  onSave,
  schoolDetailsEdit,
  onCancel,
  isStudentUpdate,
}) => {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const user = Util.getUser();
  const [isLoading, setIsLoading] = useState(false);

  const [schoolName, setSetSchoolName] = useState("");
  const [registrationDate, setSetRegistrationDate] = useState("");
  const [stageList, setStageList] = useState([]);
  const [stageId, setStageId] = useState(0);
  const [stageIdError, setStageIdError] = useState("");
  const [gradeId, setGradeId] = useState(0);
  const [gradeIdError, setGradeIdError] = useState("");

  const [status, setStatus] = useState(false);
  const [inactive, setInactive] = useState("");
  const [roomId, setRoomId] = useState(0);
  const [roomIdError, setRoomIdError] = useState("");

  const [TimingList, setTimingList] = useState([]);
  const [timingId, setTimingId] = useState(0);
  const [Fees, setFees] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [termsList, setTermsList] = useState([]);
  const [termsId, setTermsId] = useState(0);

  const [stageName, setStageName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [timingName, setTimingName] = useState("");
  const [termsName, setTermsName] = useState("");
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);

  useEffect(() => {
    if (data !== null) {
      setSetSchoolName(data?.School?.name);
      setSetRegistrationDate(
        moment(data?.created_on).format(
          Util.getMomentDateTimeFormat(school.date_format)
        )
      );
      setStatus(data?.active);
      setStageId(
        Util.isValidData(data?.grade?.stage_id) ? data?.grade?.stage_id : 0
      );
      setStageName(data?.grade?.Stage?.stage);
      setGradeId(Util.isValidData(data?.grade_id) ? data.grade_id : 0);
      setGradeName(data?.grade?.title);
      setRoomId(Util.isValidData(data?.classroom_id) ? data.classroom_id : 0);
      setRoomName(data?.classroom?.name);
      setInactive(
        moment(data?.inactivated_on).format(
          Util.getMomentDateTimeFormat(school.date_format)
        )
      );
      setTimingId(Util.isValidData(data?.timing_id) ? data?.timing_id : 0);
      setTimingName(data?.timing?.title);
      setTermsId(Util.isValidData(data?.terms_id) ? data?.terms_id : 0);
      setTermsName(data?.Term?.title);
      setMonday(Util.isValidData(data?.monday) ? data?.monday : false);
      setTuesday(Util.isValidData(data?.tuesday) ? data?.tuesday : false);
      setWednesday(Util.isValidData(data?.wednesday) ? data?.wednesday : false);
      setThursday(Util.isValidData(data?.thursday) ? data?.thursday : false);
      setFriday(Util.isValidData(data?.friday) ? data?.friday : false);
    }
  }, [data]);

  const handleSaveSchoolDetails = (e) => {
    e.preventDefault();
    let error = false;
    setGradeIdError("");
    setRoomIdError("");
    setStageIdError("");
    if (stageId == 0) {
      setStageIdError(t("Please Select stage"));
      error = true;
    }
    if (gradeId == 0) {
      setGradeIdError(t("Please Select grade"));
      error = true;
    }
    if (roomId == 0) {
      setRoomIdError(t("Please Select room"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        id: studentId,
        stage_id: stageId,
        grade_id: gradeId,
        classroom_id: roomId,
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        ...(termsId > 0 ? { terms_id: termsId } : {}),
        ...(timingId > 0 ? { timing_id: timingId } : {}),
      };
      ApiCalling.apiCallBodyDataPost("student/dynamic-update", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            onSave(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const getStageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTermsList = async () => {
    ApiCalling.apiCallParamsGet("terms/school").then((res) => {
      setTermsList(res.data);
    });
  };

  const getTimingList = () => {
    ApiCalling.apiCallBodyDataGet("timing/list")
      .then((res) => {
        setTimingList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getTermsList();
    getStageList();
    getTimingList();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="row text-start mt-2">
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("School Name")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(schoolName) ? schoolName : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={schoolName}
                  disabled
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Student ID")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(studentId)
                    ? Util.studentIdFormat(studentId)
                    : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={studentId}
                  disabled
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Registration Date")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(registrationDate)
                    ? moment(registrationDate).format(
                        Util.getMomentDateTimeFormat(school.date_format)
                      )
                    : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={registrationDate}
                  disabled
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Days Per Week")}</span>

              <div className="circle-container">
                <div>
                  <label
                    className={`circulercheckbox ${monday ? "checked" : ""}`}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={monday}
                      onChange={() => {
                        setMonday(!monday);
                        isStudentUpdate();
                      }}
                      disabled={!schoolDetailsEdit}
                    />
                    <span
                      className={`circulercheckbox-letter ${
                        monday ? "checked" : ""
                      }`}
                    >
                      {t("M")}
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    className={`circulercheckbox ${tuesday ? "checked" : ""}`}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={tuesday}
                      onChange={() => {
                        setTuesday(!tuesday);
                        isStudentUpdate();
                      }}
                      disabled={!schoolDetailsEdit}
                    />
                    <span
                      className={`circulercheckbox-letter ${
                        tuesday ? "checked" : ""
                      }`}
                    >
                      {t("T")}
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    className={`circulercheckbox ${wednesday ? "checked" : ""}`}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={wednesday}
                      onChange={() => {
                        setWednesday(!wednesday);
                        isStudentUpdate();
                      }}
                      disabled={!schoolDetailsEdit}
                    />
                    <span
                      className={`circulercheckbox-letter ${
                        wednesday ? "checked" : ""
                      }`}
                    >
                      {t("W")}
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    className={`circulercheckbox ${thursday ? "checked" : ""}`}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={thursday}
                      onChange={() => {
                        setThursday(!thursday);
                        isStudentUpdate();
                      }}
                      disabled={!schoolDetailsEdit}
                    />
                    <span
                      className={`circulercheckbox-letter ${
                        thursday ? "checked" : ""
                      }`}
                    >
                      {t("T")}
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    className={`circulercheckbox ${friday ? "checked" : ""}`}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={friday}
                      onChange={() => {
                        setFriday(!friday);
                        isStudentUpdate();
                      }}
                      disabled={!schoolDetailsEdit}
                    />
                    <span
                      className={`circulercheckbox-letter ${
                        friday ? "checked" : ""
                      }`}
                    >
                      {t("F")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Level")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(stageName) ? stageName : "-"}
                </div>
              ) : (
                <>
                  <select
                    className={`select-dropdown form-select custom-input ${
                      stageIdError.length > 0 ? "input-error" : ""
                    }`}
                    value={stageId}
                    onChange={(e) => {
                      setStageId(e.target.value);
                      setStageIdError("");
                      setGradeId("");
                      setRoomId("");
                      isStudentUpdate();
                    }}
                  >
                    <option value={0}>{t("Select Level")}</option>
                    {stageList?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.stage}
                        </option>
                      );
                    })}
                  </select>
                  {stageIdError.length > 0 && (
                    <span className="text-danger">
                      {" "}
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>
                      {stageIdError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Fees")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(Fees) ? Fees : "-"}
                </div>
              ) : (
                <input className="form-control custom-input" value={Fees} onChange={()=>{}}/>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Grade")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(gradeName) ? gradeName : "-"}
                </div>
              ) : (
                <>
                  <select
                    className={`select-dropdown form-select custom-input ${
                      gradeIdError.length > 0 ? "input-error" : ""
                    }`}
                    value={gradeId}
                    onChange={(e) => {
                      setGradeId(e.target.value);
                      setGradeIdError("");
                      isStudentUpdate();
                    }}
                    disabled={stageId == 0}
                  >
                    <option value={0}>{t("Select Grade")}</option>
                    {stageList
                      ?.find((stage) => stage.id === parseInt(stageId))
                      ?.Grades.map((grade) => (
                        <option key={grade.id} value={grade.id}>
                          {grade.title}
                        </option>
                      ))}
                  </select>
                  {gradeIdError.length > 0 && (
                    <span className="text-danger">
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>
                      {gradeIdError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Terms")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(termsName) ? termsName : "-"}
                </div>
              ) : (
                <select
                  className="select-dropdown  form-select custom-input"
                  value={termsId}
                  onChange={(e) => {
                    setTermsId(e.target.value);
                    isStudentUpdate();
                  }}
                >
                  <option value={0}>{t("Select Terms")}</option>
                  {termsList.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.title}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Class Room")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(roomName) ? roomName : "-"}
                </div>
              ) : (
                <>
                  <select
                    className={`select-dropdown form-select custom-input ${
                      roomIdError.length > 0 ? "input-error" : ""
                    }`}
                    value={roomId}
                    onChange={(e) => {
                      setRoomId(e.target.value);
                      setRoomIdError("");
                      isStudentUpdate();
                    }}
                    disabled={gradeId === 0}
                  >
                    <option value={0}>{t("Select Room")}</option>
                    {stageList
                      .find((stage) => stage.id === parseInt(stageId))
                      ?.Grades.find((grade) => grade.id === parseInt(gradeId))
                      ?.ClassRooms.map((classroom) => (
                        <option key={classroom.id} value={classroom.id}>
                          {classroom.name}
                        </option>
                      ))}
                  </select>
                  {roomIdError.length > 0 && (
                    <span className="text-danger">
                      {" "}
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>
                      {roomIdError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Timing")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(timingName) ? timingName : "-"}
                </div>
              ) : (
                <select
                  className="select-dropdown form-select custom-input"
                  value={timingId}
                  onChange={(e) => {
                    setTimingId(e.target.value);
                    isStudentUpdate();
                  }}
                >
                  <option value={0}>{t("Select Timing")}</option>
                  {TimingList.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.title}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Status")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {status ? t("active") : t("Inactive")}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={status ? t("active") : t("Inactive")}
                  disabled
                />
              )}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="input-filed">
              <span className="basic-text ">{t("Payment Status")}</span>
              {!schoolDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(PaymentStatus) ? PaymentStatus : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={PaymentStatus}
                />
              )}
            </div>
          </div>
          {status !== true && (
            <div className="col-lg-6">
              {Util.isValidData(inactive) && (
                <div className="input-filed ">
                  <span className="basic-text ">{t("Inactive Since")}</span>
                  {!schoolDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(inactive)
                        ? moment(inactive).format(
                            Util.getMomentDateTimeFormat(school.date_format)
                          )
                        : "-"}
                    </div>
                  ) : (
                    <input
                      className="form-control custom-input"
                      value={inactive}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {schoolDetailsEdit && (
            <div className="text-end">
              {studentId > 0 && (
                <button
                  className="secondary-lg-btn rounded-btn btn mr-2 m-0  px-2"
                  onClick={() => {
                    onCancel();
                    setRoomIdError("");
                    setGradeIdError("");
                    setStageIdError("");
                  }}
                >
                  {t("Cancel")}
                </button>
              )}
              <button
                className="primary-lg-btn rounded-btn btn"
                onClick={handleSaveSchoolDetails}
              >
                {t("Save")}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SchoolDetailsCard;
