import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";

import { tostMessageLoad } from "../actions";
import Util from "../Util";
import ApiCalling from "../network/ApiCalling";
import ToastHOC from "../screens/HOC/ToastHOC";
import ErrorBoundary from "../ErrorBoundry";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Toggle } from "../actions/ToggleAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function NavbarMenu() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      // console.log("yes, im here")
      localStorage.clear();
      Util.removeUser();
      history.push("/");
    }
  }, [token]);

  const [permissionsList, setPermissionsList] = useState([]);
  const [isTabletSize, setIsTabletSize] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const handleMediaQueryChange = (event) => {
    console.log(event);
    setIsTabletSize(event.matches);
    let allLiSelect = document.querySelectorAll("#main-menu li");
    console.log(allLiSelect)
    // allLiSelect.forEach((li) => {
    //   li.addEventListener("click", (event) => {
    //     if (li.querySelector("ul ")) {
    //       // console.log("not hide");
    //     } else {
    //       console.log("1");
    //       document.getElementById("left-sidebar").style.left = "-260px";
    //       dispatch(Toggle(true));
    //     }
    //   });
    // });

    if (event.matches) {
      document.getElementById("left-sidebar").style.left = "-260px";
      console.log("2");
      dispatch(Toggle(true));

      document.getElementById("main-content").style.width = "100%";

      // document.getElementById("main-content").addEventListener("click", () => {
      //   console.log("3");
      //   document.getElementById("left-sidebar").style.left = "-260px";
      //   dispatch(Toggle(true));
      // });
    } else {
      document.body.style.backgroundColor = "";
      document.getElementById("main-content").style.width = "100%";

      document.getElementById("left-sidebar").style.left = "0";
      dispatch(Toggle(false));

      let origionalSize = "100%";
      let reduceSize = "260px";
      let size = `calc(${origionalSize} - ${reduceSize})`;
      document.getElementById("main-content").style.width = size;
    }
  };
  useEffect(() => {
    dispatch(tostMessageLoad(true));
    const res = window.location.pathname.split("/");
    const route = res.length > 4 ? res[4] : "/";

    const role = Util.getRoleInSelectedSchool();
    if (role > 0) {
      getUsersPermission();
    }
  }, []);
  useEffect(() => {
    const mediaQueryList = window.matchMedia("(max-width: 768px)");

    mediaQueryList.addListener(handleMediaQueryChange);

    return () => {
      mediaQueryList.removeListener(handleMediaQueryChange);
    };
  }, [isTabletSize]);
  const getUsersPermission = async () => {
    const role = await Util.getRoleInSelectedSchool();
    const school = await Util.getSelectedSchool();
    // Use setPermissionsList instead of setState for hooks
    setPermissionsList((prevState) => ({
      ...prevState,
      selectedSchool: school,
    }));

    ApiCalling.apiCallParamsGet("role/" + role)
      .then((res) => {
        const tmpList = [];
        res.data.permissions.forEach((obj) => {
          tmpList.push(obj.reference);
        });
        Util.savePermissions(tmpList);
        // Use setPermissionsList instead of setState for hooks
        setPermissionsList((prevState) => ({
          ...prevState,
          permissionsList: tmpList,
        }));
      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  };

  return (
    <div>
      <ErrorBoundary>
        <Sidebar />
        {/* <header>
          <nav className=" navbar navbar-fixed-top header-border">
            <div className="container-fluid">
              <Topbar />
              <Sidebar />
            </div>
          </nav>
        </header> */}
      </ErrorBoundary>
    </div>
  );
}
export default ToastHOC(NavbarMenu);
