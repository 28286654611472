// Table.js

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Sort from "../../assets/Icon/Short.svg";

const Table = ({
  data,
  columns,
  customRenderers,
  rowStyle,
  tdStyle,
  handleRowClick = () => {},
  setSortBy,
  setSortOrder,
  rowClass = "",
}) => {
  const { t } = useTranslation();

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column) => {
    console.log("sorting...");
    console.log("column", column);
    setSortBy && setSortBy(column);
    setSortOrder && setSortOrder((prevSortOrder) => !prevSortOrder);
  };

  const sortedData = () => {
    if (sortColumn) {
      return data.slice().sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
      });
    } else {
      return data;
    }
  };

  return (
    <table className="table">
      <thead>
        <tr className="text-nowrap">
          {columns.map((column, columnIndex) => (
            <th
              key={columnIndex}
              scope="col"
              className={column.className}
              style={column.style}
            >
              <div className="d-flex">
                {column.content}
                {column.sortable && (
                  <img
                    src={Sort}
                    className="ml-1 fa-sort"
                    alt=""
                    onClick={() => {
                      handleSort(column.sortBy);
                    }}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {sortedData().map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={rowStyle && rowStyle(row)}
              onClick={() => {
                handleRowClick(row);
              }}
              className={rowClass}
            >
              {columns.map((column, colIndex) => {
                const customRenderer =
                  customRenderers && customRenderers[rowIndex][column.name];

                const cellContent = customRenderer
                  ? customRenderer(row[column.name])
                  : row[column.name];

                return (
                  <td
                    key={colIndex}
                    style={{ ...column.style, ...(tdStyle && tdStyle()) }}
                  >
                    {cellContent}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      ) : (
        <tfoot>
          <tr>
            <td colSpan={8}>{t("No results found!")}</td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default Table;
