import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RemainingInvoices from "./RemainingInvoices";
import StudentPaymentList from "./StudentPaymentList";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";

function StudentSummary(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const updateData = props?.updateData;
  const studentId = props?.Student?.id;
  const student = props?.Student;
  const history = useHistory();

  const [studentBalance, setStudentBalance] = useState(null);
  const [value, setValue] = React.useState("Open Invoices");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStudentBalance = () => {
    ApiCalling.apiCallBodyDataGet(`student/balance/${studentId}`)
      .then((res) => {
        setStudentBalance(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(studentId)) {
      getStudentBalance();
    } else {
      history.push("/billing-plan-list");
    }
  }, [updateData, studentId]);

  

  const remainingInvoicesCount =
    studentBalance?.remaining_invoices?.length || 0;
  const creditInvoicesCount = studentBalance?.remaining_payments?.length || 0;

  const CurrentInvoices = studentBalance?.remaining_invoices.filter(
    (r) => new Date(r.last_payment_date) > new Date()
  );
  const DueInvoice = studentBalance?.remaining_invoices.filter(
    (r) => new Date(r.last_payment_date) < new Date()
  );
  const sumOfCurrentInvoice = Util.isValidData(CurrentInvoices) && CurrentInvoices.reduce((acc, invoice) => {
    return acc + invoice.due;
  }, 0);

  const sumOfDueInvoice = Util.isValidData(DueInvoice) && DueInvoice.reduce((acc, invoice) => {
    return acc + invoice.due;
  }, 0);

  return (
    <div className="container-fluid mt-4">
      <table
        className="col-6 table"
        style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
      >
        <thead style={{ backgroundColor: "#e5e7eb" }}>
          <tr>
            <th className="large-bold-text">{t("Balance Summary")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {Util.isValidData(DueInvoice) && (
              <>
                <td>
                  <i
                    className="fa fa-exclamation-circle text-danger m-2"
                    aria-hidden="true"
                  ></i>
                  {DueInvoice.length} {t("Due Invoices")}
                </td>
                <td>{Util.invoiceFormat(sumOfDueInvoice)}</td>
              </>
            )}
          </tr>
          <tr>
            {Util.isValidData(CurrentInvoices) && (
              <>
                <td>
                  <i
                    className="fa fa-exclamation-circle m-2"
                    aria-hidden="true"
                  ></i>
                  {CurrentInvoices.length} {t("Current Invoices")}
                </td>
                <td>{Util.amountFormat(sumOfCurrentInvoice)}</td>
              </>
            )}
          </tr>
          <tr>
            <td>
              <i className="fa fa-credit-card-alt m-2" aria-hidden="true"></i>
              {t("Available Credits & Payments")}
            </td>
            <td>
              {studentBalance?.credit &&
                Util.balanceFormat(studentBalance?.credit)}
            </td>
          </tr>
          {/* <tr style={{ color: "#07112b", fontWeight: "500", fontSize: "16px" }}>
            <td>
              Final Balance <i className="fa fa-clock-o" aria-hidden="true"></i>
            </td>
            <td>
              {studentBalance?.balance &&
                Util.balanceFormat(studentBalance?.balance)}
            </td>
          </tr> */}
        </tbody>
      </table>
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#6418c3",
              height: 2,
            },
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "#6418c3",
                fontWeight: "600",
              }
            }}
            value="Open Invoices"
            label={`${t("Open Invoices")} (${remainingInvoicesCount})`}
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "#6418c3",
                fontWeight: "600",
              }
            }}
            value="Available Credits & Payments"
            label={`${t("Available Credits & Payments")} (${creditInvoicesCount})`}
          />
        </Tabs>
        {value === "Open Invoices" && (
          <RemainingInvoices
            student={student}
            updateData={Util.isValidData(updateData) && updateData}
            message={(msg) => {
              setIsToast(msg);
            }}
          />
        )}
        {value === "Available Credits & Payments" && (
          <StudentPaymentList student={student} />
        )}
      </Box>
    </div>
  );
}
export default ToastHOC(StudentSummary);
