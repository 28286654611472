import React from "react";
import { format, isValid, parseISO } from "date-fns";
import { Collapse, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../../components/common/Table";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ErrorLogsTable = ({
  errorLogs,
  expandedErrorLogIds,
  handleExpandAllErrorLogs,
  expandErrorLog,
}) => {
  const columnStyle = { verticalAlign: "middle" };

  const formatContent = (content) => {
    // console.log(content);
    return content
      ?.split("\n")
      .map((line, index) => <div key={index}>{line}</div>);
  };

  const data = [
    ...errorLogs?.map((errorLog) => {
      return {
        expanded: expandedErrorLogIds.includes(errorLog.id),
        id: errorLog?.id,
        schoolId: errorLog?.schoolId,
        userId: errorLog?.userId,
        message: errorLog?.message,
        // reportedOn: errorLog?.reported_on,
        reportedOn: errorLog?.timestamp,
        stack: errorLog?.stack,
        type: errorLog?.type,
        url: errorLog?.url,
      };
    }),
  ];

  const columns = [
    {
      name: "expanded",
      content: (
        <ExpandMore
          expand={expandedErrorLogIds.length}
          onClick={handleExpandAllErrorLogs}
          aria-expanded={expandedErrorLogIds.length}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      ),
      style: columnStyle,
    },
    { name: "id", content: "ID", style: columnStyle },
    { name: "schoolId", content: "School ID", style: columnStyle },
    { name: "userId", content: "User ID", style: columnStyle },
    { name: "message", content: "Message", style: columnStyle },
    { name: "reportedOn", content: "Reported on", style: columnStyle },
    // { name: "stack", content: "Stack", style: columnStyle },
    { name: "type", content: "Type", style: columnStyle },
    { name: "url", content: "URL", style: columnStyle },
  ];

  const customRenderers = [
    ...errorLogs?.map((errorLog) => {
      const expanded = expandedErrorLogIds.includes(errorLog.id);
      return {
        expanded: () => {
          return (
            <ExpandMore
              expand={expanded}
              onClick={() => expandErrorLog(errorLog.id)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          );
        },
        message: (data) => {
          return (
            <div>
              <Collapse in={expanded} collapsedSize={40}>
                {formatContent(data)}
                {/* {data} */}
              </Collapse>
            </div>
          );
        },
        reportedOn: (date) => {
          const parsedDate = parseISO(date);
          return isValid(parsedDate)
            ? format(parseISO(date), "yyyy-MM-dd h:mm a")
            : date;
        },
        stack: (data) => {
          return (
            <div>
              <Collapse in={expanded} collapsedSize={40}>
                {/* {formatContent(data)} */}
                {data}
              </Collapse>
            </div>
          );
        },
      };
    }),
  ];

  const tdStyle = () => {
    return {
      verticalAlign: "top",
    };
  };

  return (
    <Table
      data={data}
      columns={columns}
      customRenderers={customRenderers}
      tdStyle={tdStyle}
    />
  );
};

export default ErrorLogsTable;
