import React, { useState } from "react";
import { Rings } from "react-loader-spinner";
import { Document as PDFDocument, pdfjs, Thumbnail } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFPreview = ({ file, width = 100, setPdfUrl, setIsModalOpen }) => {
  const [pageNumber, setPageNumber] = useState(1);

  function onPDFLoadSuccess() {
    setPageNumber(1);
  }

  return (
    <PDFDocument
      file={file}
      onLoadSuccess={onPDFLoadSuccess}
      loading={
        <Rings
          height="50"
          width="50"
          color="#6418C3"
          radius="10"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      }
      className="pdf-preview"
    >
      <Thumbnail
        loading={
          <Rings
            height="50"
            width="50"
            color="#6418C3"
            radius="10"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        }
        pageNumber={pageNumber}
        width={width}
        onItemClick={() => {
          setPdfUrl && setPdfUrl(file);
          setIsModalOpen && setIsModalOpen(true);
        }}
        className={!setIsModalOpen && "cursor-default"}
      />
    </PDFDocument>
  );
};

export default PDFPreview;
