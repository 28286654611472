import React, { useEffect, useState } from "react";
import "../Register/Custom.css";
import Loader from "../../components/common/Loader";
import Logo from "../../components/common/Logo";
import { Link, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";
import Util from "../../Util";
import ApiCalling from "../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import Invalid from "../../assets/Icon/invalid.svg";
import { Modal } from "react-bootstrap";

const CreateSchool = () => {
  const { t } = useTranslation();
  const user = Util.getUser();

  const history = useHistory();
  const [schoolType, setSchoolType] = useState(0);
  const [schoolTypeError, setSchoolTypeError] = useState("");
  const [schoolTypeList, setSchoolTypeList] = useState([]);
  const [school, setSchool] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [capacity, setCapacity] = useState("");
  const [enrollerr, setEnrollerr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const handleClose = () => setShowPopUp(false);
  const handleShow = () => setShowPopUp(true);

  useEffect(() => {
    getSchoolType();
  }, []);

  const getSchoolType = async () => {
    ApiCalling.apiCallParamsGet("school_type")
      .then((res) => {
        setSchoolTypeList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formHandler = (e) => {
    e.preventDefault();
    let error = false;
    setSchoolError("");
    setEnrollerr("");
    setPhoneErr("");
    setSchoolTypeError("");
    if (school === "") {
      setSchoolError(t("School Name is required"));
      error = true;
    }

    if (capacity === "") {
      setEnrollerr(t("Enrollment Capacity required"));
      error = true;
    } else if (capacity === "0") {
      setEnrollerr(t("Enrollment Capacity must be more then 0"));
      error = true;
    }

    if (phone === "") {
      setPhoneErr(t("Phone is required"));
      error = true;
    }
    // else if (phone.length < 10) {
    //   setPhoneErr(t("Invalid phone number"));
    //   error = true;
    // }

    if (schoolType == 0) {
      setSchoolTypeError(t("Type of School Required"));
      error = true;
    }

    if (!error) {
      saveSchool();
    }
  };

  const getUserData = () => {
    ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setIsLoading(false);
          if (res.data.user_Schools[0].RoleId) {
            Util.saveRoleInSelectedSchool(res.data.user_Schools[0].RoleId);
          }
          //Check this data
          Util.saveUser(res.data);
          console.log("im here")
          history.push("/ThanksPage");
          // setShowPopUp(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submit = () =>{
    setShowPopUp(false);
    history.push("/")
    // history.push("/Dashboard")
  }

  const saveSchool = async () => {
    const data = {
      school_type_id: parseInt(schoolType),
      name: school,
      capacity: parseInt(capacity),
      contact_phone: phone,
      // country_id: 1,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("school/add-school", data)
      .then((res) => {
        if (res.data?.status === true) {
          console.log("check response when add school", res);
          getUserData();
          Util.saveSelectedSchool(res.data.school);
          Util.saveParentMode(false);
        } else {
          alert(t(res.data.message));
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const checkSchool = (e) => {
    setSchool(e.target.value);
    setSchoolError("");
  };
  const checkEnroll = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCapacity(value);
    setEnrollerr("");
  };
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="auth-box auth-wrapper">
              <Logo />
              <div className="card">
                <div className="header">
                  <h1 className="text-center">
                    {t("What would you like to do")}?
                  </h1>
                </div>
                <div className="body">
                  <form>
                    <div className="input-filed">
                      <input
                        type={"text"}
                        value={school}
                        className={`form-control custom-input ${
                          schoolError.length > 0 && "input-error"
                        }`}
                        placeholder={t("School name")}
                        onChange={checkSchool}
                      />
                      {schoolError.length > 0 && (
                        <span className={"invalid-error"}>
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {schoolError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed">
                      <select
                        className={`form-select select-dropdown custom-input ${
                          schoolTypeError.length > 0 && "input-error"
                        }`}
                        aria-label="Default select example"
                        onChange={(e) => {
                          setSchoolType(e.target.value);
                          setSchoolTypeError("");
                        }}
                      >
                        <option selected disabled value="0">
                          {t("Select School Type")}
                        </option>
                        {schoolTypeList.map((val) => {
                          return (
                            <option value={val.id} key={val.id}>
                              {" "}
                              {val.type}{" "}
                            </option>
                          );
                        })}
                      </select>

                      {schoolTypeError.length > 0 && (
                        <span className={"invalid-error"}>
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {schoolTypeError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed">
                      <input
                        type="text"
                        inputMode="numeric"
                        value={capacity}
                        onChange={checkEnroll}
                        className={`form-control custom-input ${
                          enrollerr.length > 0 && "input-error"
                        }`}
                        placeholder={t("Enrollment capacity")}
                      />
                      {enrollerr.length > 0 && (
                        <span className={"invalid-error"}>
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {enrollerr}
                        </span>
                      )}
                    </div>

                    <div className="input-filed ">
                      <div
                        tabIndex={0}
                        className={`form-control custom-input ${
                          phoneErr.length > 0 ? "input-error" : ""
                        }`}
                      >
                        <PhoneInput
                          tabIndex="-1"
                          international
                          defaultCountry={defaultCountry}
                          value={phone}
                          onChange={(value) => {
                            setPhone(value);
                            setPhoneErr("");
                          }}
                        />
                      </div>
                      {phoneErr.length > 0 && (
                        <span className={"invalid-error"}>
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {phoneErr}
                        </span>
                      )}
                    </div>

                    <div className="row ">
                      <div className="col">
                        <button
                          onClick={formHandler}
                          className="btn custom-primary btn-lg btn-block get-started-btn mt-2"
                        >
                          {t("Create School")}
                        </button>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <Link to="JoinSchool">
                          <i className="fa-solid fa-chevron-left"></i>{" "}
                          {t("Back")}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal show={showPopUp} onHide={handleClose} keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Thank You for Signing Up!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center" style={{lineHeight:2}}>
            Your account has been successfully created. However, before you can
            access the platform, we need to verify your details. Please contact
            us at <u>support@classadia.com</u> to complete the verification process and
            activate your account. We look forward to assisting you!
          </Modal.Body>
          <Modal.Footer className="text-center">
            <button className="primary-modal-lg-btn btn" onClick={submit}>
              Okay!
            </button>
          </Modal.Footer>
        </Modal>
        {/* <ModalComponent
          show={showPopUp}
          onHide={handleClose}
          title={"Thank You for Signing Up!"}
          onConfirm={"Okay!"}
          size="sm"
          onConfirmClick={handleClose}
        /> */}
      </div>
    </div>
  );
};

export default CreateSchool;
