import ApiCalling from "../network/ApiCalling";

export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";

export const setUnreadMessagesCount = (data) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_MESSAGES,
    payload: data,
  });
};

export const fetchUnreadMessagesCount = ({ userId }) => {
  return async (dispatch) => {
    try {
      console.log("api call");
      ApiCalling.apiCallParamsGet("chatroom/unread/" + userId)
        .then((res) => {
          dispatch(setUnreadMessagesCount(res.data.unread));
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    }
  };
};
