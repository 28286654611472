import React from "react";
import AllInvoices from "./AllInvoices";
import AllCredits from "./AllCredits";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ToastHOC from "../../HOC/ToastHOC";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";

function TransactionHistory(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const updateData = props?.updateData;
  const StudentId = props?.Student?.id;
  const student = props?.Student;
  const [value, setValue] = React.useState("Invoices");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container-fluid mt-4">
      <div className="medium-large-text text-start">
        {t("All Transaction History")}
      </div>

      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#6418c3",
              height: 2,
            },
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "#6418c3",
                fontWeight: "600",
              }
            }}
            value="Invoices"
            label={t("Invoices")}
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "#6418c3",
                fontWeight: "600",
              }
            }}
            value="Credits & Payments"
            label={t("Credits & Payments")}
          />
        </Tabs>
        {value === "Invoices" && (
          <AllInvoices
            student={student}
            updateData={Util.isValidData(updateData) && updateData}
            message={(msg) => {
              setIsToast(msg);
            }}
          />
        )}
        {value === "Credits & Payments" && <AllCredits student={student} />}
      </Box>
    </div>
  );
}
export default ToastHOC(TransactionHistory);
