import React from "react";
import Loader from "./Loader";

const ModalContent = ({ isLoading, children }) => {
  return (
    <div className="position-relative">
      <div className={`${isLoading ? "invisible" : ""}`}>{children}</div>
      {isLoading && (
        <div className="position-absolute top-50 start-50 translate-middle py-auto">
          <Loader variant="small" />
        </div>
      )}
    </div>
  );
};

export default ModalContent;
